import { Box } from '@mui/material';
import logoWithName from './logoWithFullName.png';
import stagingLogoWithName from './RosefieldLogo.png';

const isStaging = process.env.INPUT_ENVIRONMENT === 'staging';

const logoSrc = isStaging ? stagingLogoWithName : logoWithName;

const LogoWithName = (props) => (
  <Box
    version="1.1"
    display="block"
    {...props}
    src={logoSrc}
    alt="LogoWithName"
    component="img"
  />
);
export default LogoWithName;
