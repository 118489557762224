import Icon from './Upload.svg';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { forwardRef } from 'react';

export const UploadIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  function UploadIcon(props, ref) {
    return (
      <SvgIcon ref={ref} {...props}>
        <Icon />
      </SvgIcon>
    );
  }
);
