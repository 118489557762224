import Icon from './MaterialObligations.svg';
import { SvgIcon } from '@mui/material';
import { forwardRef } from 'react';

export const MaterialObligationsIcon = forwardRef(
  function MaterialObligationsIcon(props, ref) {
    return (
      <SvgIcon ref={ref} {...props} viewBox="0 0 40 41" component={Icon} />
    );
  }
);

MaterialObligationsIcon.name = 'MaterialObligationsIcon';
