import React, { ReactNode, forwardRef } from 'react';
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormHelperText,
} from '@mui/material';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

type CheckboxFieldProps<
  T extends FieldValues = any,
  TFieldName extends FieldPath<FieldValues> = FieldPath<FieldValues>
> = FormControlProps & {
  control: Control<T>;
  name: TFieldName;
  label: ReactNode;
  checkboxProps?: CheckboxProps;
  touched?: boolean;
};

const CheckboxField = forwardRef<HTMLButtonElement, CheckboxFieldProps>(
  function CheckboxField(props, ref) {
    const {
      control,
      name,
      label,
      checkboxProps,
      touched: defaultTouched,
      ...rest
    } = props;

    return (
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState, formState }) => {
          const { error, isTouched: fieldTouched } = fieldState;
          const touched = defaultTouched || fieldTouched;

          function _renderHelperText() {
            if (touched && error) {
              return (
                <FormHelperText error sx={{ mx: 0 }}>
                  {error.message}
                </FormHelperText>
              );
            }
          }

          function _onChange(e) {
            field.onChange(e.target.checked);
            field.onBlur();
          }
          return (
            <FormControl {...rest}>
              <FormControlLabel
                value={field.value}
                checked={field.value}
                control={
                  <Checkbox
                    {...field}
                    {...checkboxProps}
                    ref={ref}
                    disabled={props.disabled}
                    onChange={_onChange}
                  />
                }
                label={label}
              />
              {_renderHelperText()}
            </FormControl>
          );
        }}
      />
    );
  }
);

export default CheckboxField;
