import React, { ReactNode } from 'react';
import { FieldHookConfig, useField } from 'formik';
import { InputAdornment, Typography, Box } from '@mui/material';
import { CustomTooltip } from '../CustomTooltip';
import { InfoIcon } from '../loan_application/icons';
import StyledInput from '../inputs/StyledInput';
import type { StyledInputProps } from '../inputs/StyledInput';

type InputFieldProps = Omit<
  StyledInputProps &
    FieldHookConfig<any> & {
      startAdornmentText?: string;
      endAdornmentText?: string;
      readonly?: boolean;
      max?: number;
      counter?: boolean;
      touched?: boolean;
      isDisabled?: boolean;
      infoText?: string;
    },
  'size'
>;

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  function InputField(props, ref) {
    const {
      name,
      isDisabled,
      infoText,
      startAdornmentText,
      endAdornmentText,
      readOnly,
      max,
      counter,
      touched: defaultTouched,
      ...rest
    } = props;
    const [field, meta, helpers] = useField(name);
    const { error, touched: fieldTouched } = meta;
    const touched = defaultTouched || fieldTouched;

    let startAdornment: ReactNode | undefined = undefined;
    if (startAdornmentText) {
      startAdornment = (
        <InputAdornment position="start">{startAdornmentText}</InputAdornment>
      );
    }
    let endAdornment: ReactNode | undefined = undefined;
    if (endAdornmentText) {
      endAdornment = (
        <InputAdornment position="end">{endAdornmentText}</InputAdornment>
      );
    }
    if (infoText) {
      endAdornment = (
        <CustomTooltip title={infoText} arrow>
          <InfoIcon />
        </CustomTooltip>
      );
    }

    function _renderHelperText() {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
            whiteSpace: 'nowrap',
          }}
          component="span"
        >
          <Typography variant="body2" flexGrow={0} pt={0.375} component="span">
            {counter && field.value && `${field.value?.length ?? 0}/${max}`}
          </Typography>
          {touched && error}
        </Box>
      );
    }
    const id = props.id ?? props.name;
    return (
      <StyledInput
        ref={ref}
        id={id}
        type="text"
        error={Boolean(touched && error)}
        helperText={_renderHelperText()}
        disabled={isDisabled}
        InputProps={{
          readOnly: readOnly,
          endAdornment: endAdornment,
          startAdornment: startAdornment,
        }}
        {...field}
        onChange={(e) => {
          if (!meta.touched) helpers.setTouched(true, true);
          if (max && e.target.value.length > max)
            e.target.value = e.target.value.slice(0, max);
          field.onChange(e);
        }}
        {...rest}
      />
    );
  }
);

export default InputField;
