import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  experimentalStyled,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useTheme,
  Drawer,
  useMediaQuery,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';
import AccountPopover from '../account/AccountPopover';
import Logo from '../logos/LogoWithName';

import useAuth from '../../hooks/useAuth';
import { useLayout } from '../../contexts';
import {
  USER_SETTINGS_PATH,
  DEBUG_SETTINGS_PATH,
  Z_INDEXES,
} from '../../constants';
import {
  isFeatureEnabled,
  IS_DEV_OR_STAGING,
} from '../account/userSettings/DebugForm';
import NavRootItem, { NavItem } from '../nav/desktop/NavRootItem';
import { SettingsOutlined, Logout } from '@mui/icons-material';
import { useEffect } from 'react';

import { lighten } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import noticeError from '../../utils/errors/noticeError';
import toast from 'react-hot-toast';
import ContactInformation from './ContactInformation';
import TuneOutlined from '@mui/icons-material/TuneOutlined';

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.background.paper,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: 'black',
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: Z_INDEXES.NAVBAR,
}));

// Recursive function to set the depth of each item
const setDepth = (items: NavItem[], depth = 0) => {
  return items.map((item) => ({
    ...item,
    depth,
    ...(item.items && {
      items: setDepth(item.items, depth + 1),
    }),
  }));
};

const DashboardNavbar = () => {
  const { sidebar } = useLayout();
  const { user, logout } = useAuth();

  const theme = useTheme();

  // Custom MediaQuery (this is used as a custom breakpoint for the navbar)
  const isMobileMQ = useMediaQuery('(max-width:900px)');

  useEffect(() => {
    if (!isMobileMQ && sidebar.isOpen) {
      sidebar.onClose();
    }
  }, [isMobileMQ, sidebar.isOpen]);

  useEffect(() => {
    // Need this for the menu not to be scrollable through the whole heigh of the page
    const rootElement = document.getElementById('root')!;
    if (sidebar.isOpen) {
      rootElement.style.overflow = 'hidden';
    } else {
      rootElement.style.overflow = 'auto';
    }

    return () => {
      rootElement.style.overflow = 'auto';
    };
  }, [sidebar.isOpen]);

  const isInstaller = user?.is_installer;

  const isConsultant = user?.is_consultant;

  const installerOrConsultant = isInstaller || isConsultant;

  const navItems: NavItem[] = [
    {
      path: `/dashboard/${
        installerOrConsultant ? 'borrowers' : 'applications'
      }`,
      label: 'Dashboard',
    },
  ];

  if (!installerOrConsultant) {
    navItems.push({ path: '/dashboard/documents', label: 'Documents' });
  }

  if (installerOrConsultant && isFeatureEnabled('showLoanCalculator')) {
    navItems.push({
      path: '/dashboard/loan-calculator',
      label: 'Loan Calculator',
    });
  }

  if (installerOrConsultant) {
    // Handle switching between one and the other
    if (isFeatureEnabled('showPricingCalculator')) {
      navItems.push({
        path: '/dashboard/price-a-project',
        label: 'Price and Prequalify',
      });
    }
  }

  if (installerOrConsultant && isFeatureEnabled('showInstallerResources')) {
    navItems.push({
      path: '/dashboard/resources/marketing-materials',
      label: 'Resources',
    });
  }

  const handleLogout = async () => {
    try {
      sidebar.onClose();
      await logout();
    } catch (err) {
      noticeError(err);
      toast.error('Unable to logout.');
    }
  };

  const itemsWithDepth = setDepth(navItems);

  const menuItemStyles = { p: 1.5 };
  const listIconStyles = {
    '&.MuiListItemIcon-root': {
      minWidth: 32,
      mr: 0,
      color: 'text.secondary',
    },
  };

  return (
    <>
      <DashboardNavbarRoot>
        <Toolbar>
          <Grid
            container
            minHeight={81}
            maxHeight={120}
            justifyContent={{ xs: 'flex-start', md: 'space-between' }}
            alignItems="center"
            sx={{
              pl: 0.5,
              pr: 0.5,
            }}
          >
            <Box
              sx={{
                display: { xs: 'block', md: 'none', lg: 'none' },
              }}
            >
              <Grid item xs={2} sm={1}>
                <IconButton
                  color="inherit"
                  onClick={sidebar.onToggle}
                  size="large"
                >
                  <MenuIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Box>

            <Grid
              item
              xs={8}
              sm={8}
              md={2}
              lg={2}
              flexGrow={2}
              sx={{
                margin: { xs: 'auto', md: '0' },
                display: { xs: 'flex', md: 'block' },
              }}
              justifyContent="center"
            >
              <a href={`${__landingUrl__}`}>
                <Logo width="100%" minWidth="187px" maxWidth="187px" />
              </a>
            </Grid>
            <Grid
              container
              item
              sm={4}
              md={3}
              alignSelf="stretch"
              sx={{
                display: {
                  xs: 'none',
                  md: 'flex',
                  lg: 'flex',
                  flexBasis: '100%',
                },
              }}
              wrap="nowrap"
              flexShrink={1}
              flexGrow={0}
              lg={isInstaller ? 4 : 2}
            >
              {itemsWithDepth.map(({ path, label, items }, i) => (
                <NavRootItem key={i} path={path} label={label} items={items} />
              ))}
            </Grid>

            <Grid
              item
              xs={2}
              sm={6}
              lg={isInstaller ? 6 : 7}
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              <Grid
                container
                alignItems="center"
                sx={{
                  justifyContent: {
                    xs: 'right',
                  },
                }}
                margin=".5rem 0"
                gap={2}
                wrap="nowrap"
              >
                <Grid
                  item
                  xs={12}
                  sm={8}
                  lg={'auto'}
                  sx={{
                    display: {
                      lg: 'flex',
                      xs: 'none',
                      md: 'flex',
                      flexBasis: '100%',
                      justifyContent: 'center',
                      marginRight: 'clamp(0, 1rem, 1rem)',
                    },
                  }}
                >
                  <ContactInformation isMobile={false} user={user!} />
                </Grid>

                <Grid
                  item
                  md={'auto'}
                  lg={'auto'}
                  flexShrink={1}
                  sx={{
                    display: { lg: 'flex', xs: 'none', md: 'flex' },
                  }}
                >
                  <Box
                    sx={{
                      paddingTop: '0px',
                      display: {
                        xs: 'none',
                        md: 'flex',
                        flexBasis: '100%',
                      },
                      justifyContent: 'flex-end',
                      flexShrink: 1,
                    }}
                  >
                    <AccountPopover />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </DashboardNavbarRoot>

      {/* Mobile Menu */}
      <Drawer
        open={sidebar.isOpen}
        anchor="left"
        onClose={() => sidebar.onClose()}
        sx={{
          '& .MuiDrawer-paper': {
            width: '75vw',
          },
          overflow: 'hidden',
          zIndex: Z_INDEXES.MOBILE_NAVBAR,
        }}
      >
        <Grid
          container
          minHeight={50}
          alignItems="center"
          padding={2}
          borderBottom={`1px solid ${theme.palette.divider}`}
          wrap="nowrap"
        >
          <Grid item>
            <IconButton color="inherit" onClick={sidebar.onToggle} size="large">
              <Close fontSize="medium" />
            </IconButton>
          </Grid>

          <Grid
            item
            xs={8}
            display="flex"
            justifyContent="center"
            margin="0 auto"
          >
            <a href={`${__landingUrl__}`}>
              <Logo width="100%" maxWidth="250px" />
            </a>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            display: { xs: 'flex', md: 'none' },
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
          height={'100vh'}
        >
          <Grid
            container
            item
            sx={{
              display: { xs: 'flex', md: 'none' },
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
            maxWidth="100%"
            maxHeight="fit-content"
          >
            {itemsWithDepth.map(({ path, label, items }, i) => (
              <NavRootItem key={i} path={path} label={label} items={items} />
            ))}
          </Grid>
          <Grid
            container
            sx={{
              display: { xs: 'flex', md: 'none' },
              alignItems: 'flex-start',
              backgroundColor: lighten(theme.palette.divider, 0.8),
            }}
          >
            <Grid xs={12}>
              <ContactInformation isMobile={true} user={user!} />
            </Grid>

            <Grid
              height={'160px'}
              container
              sx={{
                flexDirection: 'column',
                justifyContent: 'space-between',
                margin: '1rem 0',
              }}
            >
              <MenuItem
                component={RouterLink}
                to={`/${USER_SETTINGS_PATH}/`}
                onClick={() => sidebar.onToggle}
                sx={menuItemStyles}
              >
                <ListItemIcon sx={listIconStyles}>
                  <SettingsOutlined fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography variant="body1" color="textSecondary">
                      Settings
                    </Typography>
                  }
                />
              </MenuItem>
              {IS_DEV_OR_STAGING && (
                <MenuItem
                  component={RouterLink}
                  to={`/${DEBUG_SETTINGS_PATH}/`}
                  sx={menuItemStyles}
                >
                  <ListItemIcon sx={listIconStyles}>
                    <TuneOutlined fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="body1" color="textSecondary">
                        Debug
                      </Typography>
                    }
                  />
                </MenuItem>
              )}
              <MenuItem onClick={handleLogout} sx={menuItemStyles}>
                <ListItemIcon sx={listIconStyles}>
                  <Logout fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      color="textSecondary"
                      variant="body1"
                      textAlign={'start'}
                    >
                      Log out
                    </Typography>
                  }
                />
              </MenuItem>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
