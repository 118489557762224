/* eslint-disable indent */
import React, { FC, memo, ReactNode } from 'react';
import { useField } from 'formik';
import { InputAdornment } from '@mui/material';
import StyledInput, { StyledInputProps } from '../inputs/StyledInput';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

type NumberFieldProps = {
  isDisabled?: boolean;
  readonly?: boolean;
  name: string;
  startAdornmentText?: string;
  endAdornmentText?: string;
  touched?: boolean;
  max?: number;
  min?: number;
  warning?: string;
} & NumberFormatProps<Omit<StyledInputProps, 'type' | 'ref' | 'defaultValue'>>;

const NumberField: FC<NumberFieldProps> = (props) => {
  const {
    isDisabled,
    readOnly,
    startAdornmentText,
    endAdornmentText,
    thousandSeparator,
    decimalScale,
    format,
    max,
    min,
    warning,
    touched: defaultTouched,
    ...rest
  } = props;
  const [field, meta, helpers] = useField(props.name);
  const { error, touched: fieldTouched } = meta;
  const touched = defaultTouched || fieldTouched;

  //Limit max value to the max prop if it's set or maintain minimum value if defined
  const onValueChange = (values) => {
    if (
      typeof max === 'number' &&
      !Number.isNaN(max) &&
      typeof min === 'number' &&
      !Number.isNaN(min)
    ) {
      let endValue = values.floatValue > max ? max.toString() : values.value;
      endValue = values.floatValue < min ? min.toString() : endValue;
      helpers.setValue(endValue);
    } else if (typeof max === 'number' && !Number.isNaN(max)) {
      const maxValue = values.floatValue > max ? max.toString() : values.value;
      helpers.setValue(maxValue);
    } else if (typeof min === 'number' && !Number.isNaN(min)) {
      const minValue = values.floatValue > min ? min.toString() : values.value;
      helpers.setValue(minValue);
    } else {
      helpers.setValue(values.value);
    }
  };

  let startAdornment: ReactNode | undefined = undefined;
  if (startAdornmentText) {
    startAdornment = (
      <InputAdornment position="start">{startAdornmentText}</InputAdornment>
    );
  }
  let endAdornment: ReactNode | undefined = undefined;
  if (endAdornmentText) {
    endAdornment = (
      <InputAdornment position="end">{endAdornmentText}</InputAdornment>
    );
  }

  function _renderHelperText() {
    if (touched && error) {
      return error;
    }
  }

  function _renderWarningText() {
    if (touched && warning) {
      return warning;
    }
  }

  const id = props.id ?? props.name;
  return (
    <NumberFormat
      type="text"
      customInput={StyledInput}
      InputProps={{
        readOnly: readOnly,
        endAdornment: endAdornment,
        startAdornment: startAdornment,
      }}
      decimalScale={decimalScale ? decimalScale : 0}
      fixedDecimalScale={true}
      thousandSeparator={thousandSeparator}
      format={format}
      mask="_"
      disabled={isDisabled}
      error={Boolean(touched && error)}
      helperText={_renderHelperText()}
      id={id}
      isNumericString
      value={field.value}
      onBlur={field.onBlur}
      onValueChange={onValueChange}
      warningText={_renderWarningText()}
      {...rest}
    />
  );
};

export default memo(NumberField);
