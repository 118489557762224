import {
  createTheme,
  responsiveFontSizes,
  alpha,
  ThemeOptions,
  PaletteColorOptions,
  Theme,
} from '@mui/material/styles';
import { lightShadows } from './shadows';

// The default theme interface, augmenting this avoids having to set the theme type everywhere.
declare module '@mui/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}
// Declare any additions to the theme's default properties here.
declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
  interface TypeBackground {
    dark: string;
    input: string;
  }

  interface TypeText {
    tersiary: string;
  }

  interface Palette {
    border: {
      input: string;
      table: string;
    };
  }

  interface PaletteOptions {
    border: {
      input: string;
      table: string;
    };
    chipPrimary?: PaletteColorOptions;
    chipSecondary?: PaletteColorOptions;
    chipTertiary?: PaletteColorOptions;
    chipError?: PaletteColorOptions;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    chipPrimary: true;
    chipSecondary: true;
    chipTertiary: true;
    chipError: true;
  }
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#333333',
          '&.Mui-error': {
            color: '#333333',
          },
          fontWeight: 400,
          fontSize: '.9rem',
        },
        asterisk: {
          color: '#EB5757',
          '&.Mui-error': {
            color: '#EB5757',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '12px',
          padding: '1rem 2rem',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '.375rem',
          backgroundColor: '#F9FBFD',
          '&.Mui-disabled': {
            backgroundColor: '#E5EEF6',
          },
        },
        input: {
          padding: '.6875rem .825rem',
          borderRadius: 'inherit',
          '&.Mui-disabled': {
            color: '#333',
            WebkitTextFillColor: '#333',
          },
        },
        notchedOutline: {
          borderColor: '#DCE1ED',
        },
        multiline: {
          padding: 0,
        },
        inputAdornedStart: {
          paddingLeft: 0,
        },
        inputAdornedEnd: {
          paddingRight: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: 'translate(14px, 12px) scale(1)',
        },
        shrink: {
          transform: 'translate(14px, -9px) scale(0.75)',
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%',
        },
        body: {
          height: '100%',
        },
        '#root': {
          height: '100%',
        },
        '#nprogress .bar': {
          zIndex: '2000 !important',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 0.86,
            color: '#42526e',
          },
        },
      },
    },
  },
  typography: {
    button: {
      fontSize: '0.875rem',
      fontWeight: 500,
      borderRadius: '0.75rem',
      textTransform: 'none',
      lineHeight: 1.2,
    },
    fontFamily: '"Lato", sans-serif',
    body1: {
      fontWeight: 400,
      fontSize: '0.9rem',
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.825rem',
    },
    h1: {
      fontWeight: 700,
      fontSize: '1.75rem',
    },
    h2: {
      fontWeight: 500,
      fontSize: '1.5rem',
    },
    h3: {
      fontWeight: 400,
      fontSize: '1.5rem',
    },
    h4: {
      fontWeight: 500,
      fontSize: '1.125rem',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.25rem',
      lineHeight: 1.6,
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem',
    },
    overline: {
      fontWeight: 600,
    },
  },
  palette: {
    action: {
      active: '#6b778c',
    },
    background: {
      default: '#EFF1F4',
      dark: '#172842',
      input: '#F9FBFD',
      paper: '#ffffff',
    },
    divider: '#D0D9E3',
    border: {
      input: '#DCE1ED',
      table: '#E5E5E5',
    },
    error: {
      contrastText: '#ffffff',
      main: '#EB5757',
    },
    mode: 'light' as const,
    primary: {
      contrastText: '#ffffff',
      main: '#2E9C7F',
    },
    secondary: {
      contrastText: '#ffffff',
      main: '#4476AD',
    },
    success: {
      contrastText: '#ffffff',
      main: '#4caf50',
    },
    text: {
      primary: '#333333',
      secondary: '#868795',
      tersiary: '#C8CACF',
    },
    warning: {
      contrastText: '#ffffff',
      main: '#ff9800',
    },
    chipPrimary: {
      contrastText: '#2E9C7F',
      main: alpha('#2E9C7F', 0.1),
    },
    chipSecondary: {
      contrastText: '#888888',
      main: alpha('#888888', 0.1),
    },
    chipTertiary: {
      contrastText: '#4476AD',
      main: alpha('#4476AD', 0.1),
    },
    chipError: {
      contrastText: '#EB5757',
      main: alpha('#EB5757', 0.1),
    },
  },
  shadows: lightShadows,
  shape: {
    borderRadius: 16,
  },
};

const theme = responsiveFontSizes(createTheme(baseOptions));

export default theme;
