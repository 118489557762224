import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Alert,
  Box,
  Button,
  Collapse,
  Grid,
  Link,
  Typography,
} from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import useMounted from '../../hooks/useMounted';
import StyledInput from '../inputs/StyledInput';
import { LoadingButton } from '@mui/lab';
import { loginFields } from './fields';
import { Tracker } from '../../utils/tracker';

const LoginJWT = (props) => {
  const mounted = useMounted();
  const { login } = useAuth();
  const tracker = Tracker.getInstance();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .max(80)
          .required('Email is required'),
        password: Yup.string().max(255).required('Password is required'),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await login(values.email, values.password);
          tracker.identify(values.email);
          tracker.track(Tracker.EVENTS.LOGIN);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (mounted.current) {
            setStatus({ success: false });
            setErrors(err.data ? { ...err.data } : { submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        isValid,
      }) => (
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          mb={0}
          {...props}
        >
          <Collapse in={!!errors.submit} sx={{ my: 3 }}>
            <Alert
              severity="error"
              variant="filled"
              icon={false}
              sx={{ px: 1, py: 0.5, borderRadius: 0.5 }}
            >
              <Typography variant="body1">{errors.submit}</Typography>
            </Alert>
          </Collapse>
          <Typography color="textPrimary" mb={2.25} variant="h1">
            Log In
          </Typography>
          <Typography color="textSecondary" mb={0.25} variant="body1">
            Enter your email and password to log in.
          </Typography>
          <StyledInput
            id={loginFields.email.id}
            label={loginFields.email.label}
            name={loginFields.email.name}
            data-testid={loginFields.email.testId}
            type={loginFields.email.type}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            variant="outlined"
            autoFocus
            fullWidth
            margin="normal"
            inputProps={{ tabIndex: 1 }}
          />
          <StyledInput
            id={loginFields.password.id}
            label={loginFields.password.label}
            name={loginFields.password.name}
            data-testid={loginFields.password.testId}
            type={loginFields.password.type}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.password}
            variant="outlined"
            fullWidth
            margin="normal"
            inputProps={{ tabIndex: 1 }}
          />
          <Box textAlign="right" mt={1} mb={2.5}>
            <Link
              color="primary"
              component={RouterLink}
              to="/authentication/password-recovery"
              variant="button"
              underline="none"
              tabIndex={4}
              onClick={() => {
                tracker.track(Tracker.EVENTS.FORGOT_PASSWORD);
              }}
            >
              Forgot your password?
            </Link>
          </Box>
          <Grid container columnSpacing={5}>
            <Grid item xs={6}>
              <RouterLink
                to="/authentication/register"
                style={{ textDecoration: 'none' }}
              >
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  type="button"
                  variant="text"
                  sx={{ px: 1 }}
                  tabIndex={3}
                >
                  Register a new account
                </Button>
              </RouterLink>
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                color="primary"
                loading={isSubmitting}
                disabled={isSubmitting || !isValid}
                fullWidth
                type="submit"
                data-testid="login"
                variant="contained"
                tabIndex={2}
              >
                Log In
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      )}
    </Formik>
  );
};

export default LoginJWT;
