import { UtilityAPIConnection } from '../../../../../types/api';
import { api } from './API';

// Define base URL
const BASE_URL = 'utility_bill';

type CreateFormBody = {
  application_id: string;
};
/**
 * Creates a new "connection" to utility bill API
 * Connection requires creating a form that will be used to submit the authorization
 * @param application_id
 * @returns
 */
export const createForm = async (application_id: string) => {
  const data: CreateFormBody = {
    application_id: application_id,
  };
  const response = await api().post<UtilityAPIConnection>(
    `${BASE_URL}/create-form/`,
    data
  );
  return response.data;
};

export const getConnectionStatus = async (applicationId: string) => {
  const response = await api().get<UtilityAPIConnection>(
    `${BASE_URL}/connection/${applicationId}/`
  );
  return response.data;
};

export const getMeters = async (referral: string, applicationId: string) => {
  const response = await api({
    timeout: 100000,
  }).get(`${BASE_URL}/authorizations/${referral}/`, {
    params: {
      application_id: applicationId,
    },
  });
  return response.data;
};

export const revokeConnection = async (applicationId: string) => {
  await api().post(`${BASE_URL}/connection/${applicationId}/revoke`);
};
