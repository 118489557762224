import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
} from '@mui/material';
import Logo from '../components/logos/Logo';

const InstallerApplicationSubmitted = () => {
  return (
    <>
      <Helmet>
        <title>Application Submitted | Sunstone Credit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="sm" sx={{ py: '20px' }}>
          <Box
            sx={{
              mb: 8,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <RouterLink to="/">
              <Logo height={40} width={40} />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    fontFamily="Lato"
                    sx={{
                      fontWeight: 500,
                      fontSize: '1.5rem',
                      lineHeight: 1.2,
                    }}
                  >
                    Application Submitted
                  </Typography>
                  <Typography
                    color="textSecondary"
                    fontFamily="Lato"
                    fontWeight="400"
                    fontSize="1.25rem"
                    lineHeight="1.2"
                  >
                    Thank you for submitting your application to join the
                    Sunstone Credit partner network! We&apos;ll be in touch
                    shortly with next steps on your application.
                  </Typography>
                </div>
              </Box>
              <Box sx={{ mt: 2 }}>
                <div style={{ position: 'relative' }}>
                  <Button
                    component="a"
                    color="secondary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{
                      fontWeight: 500,
                      borderRadius: 8,
                      fontSize: '0.875rem',
                      lineHeight: 1.2,
                    }}
                    href={__landingUrl__}
                  >
                    Back to Home
                  </Button>
                </div>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default InstallerApplicationSubmitted;
