import React, { FC, ReactNode } from 'react';
import { Dialog, Grid, DialogContent, Button, Typography } from '@mui/material';

type Props = {
  mainIcon?: React.ReactNode;
  heading?: string;
  content?: ReactNode;
  ctaText?: string;
  ctaFunction: () => void;
  open?: boolean;
};
// It can't be opened if it wasn't rendered open.
const InfoDialogueWithCtaButton: FC<Props> = ({
  mainIcon,
  heading,
  content,
  ctaText,
  ctaFunction,
  open = false,
}) => {
  return (
    <>
      <Dialog open={open} aria-labelledby="form-dialog-title" maxWidth="md">
        <DialogContent>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%', margin: 0, pt: 7, pb: 3, pl: 4, pr: 4 }}
          >
            <Grid item sx={{ pt: 1 }}>
              {mainIcon}
            </Grid>
            {heading && (
              <Grid item sx={{ pt: 4 }}>
                <Typography
                  align="center"
                  color="textPrimary"
                  variant="h6"
                  sx={{ fontWeight: 600 }}
                >
                  {heading}
                </Typography>
              </Grid>
            )}
            {content && (
              <Grid item sx={{ pt: 2 }}>
                {content}
              </Grid>
            )}
            {ctaText && (
              <Grid item sx={{ pt: 3 }}>
                <Button
                  variant="contained"
                  sx={{ minWidth: '150px', borderRadius: '10px' }}
                  onClick={() => {
                    ctaFunction();
                  }}
                >
                  {ctaText}
                </Button>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default InfoDialogueWithCtaButton;
