import axios from 'axios';
import axiosRetry from 'axios-retry';
import {
  REFRESH_TOKEN_URL,
  SIGNIN_URL,
  AXIOS_TIMEOUT_MS,
} from '../../constants';
import { CUSTOM_EVENT_TYPES, trigger } from '../../events';
import {
  getLocalRefreshToken,
  getToken,
  setToken,
  deleteToken,
} from '../storages/Auth';
import { getLanguage } from '../storages/language';

/**
 * @type {Promise<Object> | null}
 * This variable is used to store the current refresh token request.
 * We use this to avoid multiple refresh token requests at the same time.
 */
let refreshPromise = null;

export const api = (options = {}) => {
  const token = getToken();
  const language = getLanguage();
  const headers = token ? { Authorization: `Bearer ${token.access}` } : {};
  // Don't set base url during unit tests
  const url =
    process.env.NODE_ENV === 'test' ? undefined : window.location.protocol;
  const axiosClient = axios.create({
    baseURL: url,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': language,
    },
    timeout: AXIOS_TIMEOUT_MS,
    ...options,
  });

  axiosRetry(axiosClient, {
    retries: 0,
    shouldResetTimeout: true,
    retryCondition: () => true, // retry no matter what
  });
  // Intercept timeouts on axios side & format it to the shape error handler expects.
  axiosClient.interceptors.response.use(
    (config) => config,
    (error) => {
      // Set timeout status code if request was aborted by axios
      if (error.code === 'ECONNABORTED') error.response = { status: 408 };
      return Promise.reject(error);
    }
  );
  // Refresh access token on receiving 401 "Token expired" error
  axiosClient.interceptors.response.use(
    (res) => res,
    async (error) => {
      const originalConfig = error.config;
      // Attempt to refresh token if
      if (
        // Not a login request
        originalConfig.url !== SIGNIN_URL &&
        // Not a refresh token request
        originalConfig.url !== REFRESH_TOKEN_URL &&
        // Access token invalid
        error.response.status === 401 &&
        error.response.data.code === 'token_not_valid' &&
        // The request wasn't retried before
        !originalConfig._retry
      ) {
        originalConfig._retry = true;
        try {
          const refresh = getLocalRefreshToken();
          if (!refresh) throw new Error('No refresh token found');
          refreshPromise =
            refreshPromise ||
            axiosClient.post(REFRESH_TOKEN_URL, {
              refresh,
            });
          const res = await refreshPromise;
          // Save new token pair to storage
          const freshTokenPair = res.data;
          setToken(freshTokenPair);
          // update instance config with new access token
          originalConfig.headers.Authorization = `Bearer ${freshTokenPair.access}`;
          return axiosClient(originalConfig);
        } catch (err) {
          // Trigger session expired event and clear the token from storage if refresh token request failed
          // to notify the auth context to logout and render the "you have been logged out because of inactivity" message
          if (
            err.message === 'No refresh token found' ||
            (err.response.status === 401 &&
              error.response.data.code === 'token_not_valid' &&
              originalConfig.url !== REFRESH_TOKEN_URL)
          ) {
            deleteToken();
            trigger(CUSTOM_EVENT_TYPES.SESSION_EXPIRED);
          }
          return Promise.reject(err);
        } finally {
          refreshPromise = null;
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosClient;
};
