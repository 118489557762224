import React, { useState, useEffect } from 'react';
import { useField } from 'formik';

import { DesktopDatePicker as DatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { isValid } from 'date-fns';
import StyledInput from '../inputs/StyledInput';

const minimumAcceptableDate = new Date(1900, 0, 1);
const invalidDate = new Date('invalid');

export default function DatePickerField(props) {
  const {
    label,
    isDisabled,
    minDate,
    maxDate,
    readOnly,
    touched: defaultTouched,
    views,
    inputFormat,
    placeholder,
    ...rest
  } = props;
  const [field, meta, helper] = useField(props);
  const { touched: fieldTouched, error } = meta;
  const touched = defaultTouched || fieldTouched;

  const { setValue } = helper;
  const { value } = field;
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (value) {
      const date = new Date(value);
      // Accomodate current user timezone to avoid date being displayed incorrectly
      // date.getTime returns current time in milliseconds, getTimezoneOffset returns minutes - multiply by 60000 to convert to min -> ms
      date.setTime(date.getTime() + date.getTimezoneOffset() * 60000);
      setSelectedDate(date);
    }
  }, [value]);

  function _renderHelperText() {
    if (touched && error) {
      return error;
    }
  }

  function _onChange(date) {
    const nextDate =
      (isValid(date) && date) > minimumAcceptableDate ? date : invalidDate;
    if (date) {
      setSelectedDate(nextDate);
      try {
        // YYYY-MM-dd
        setValue(nextDate.toISOString().slice(0, 10), true);
      } catch (error) {
        console.error(error);
        setValue(nextDate, true);
      }
    } else {
      setValue(date, true);
    }
  }
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label}
          disabled={isDisabled}
          readOnly={readOnly}
          views={views}
          renderInput={({ inputProps, ...otherProps }) => (
            <StyledInput
              {...otherProps}
              {...rest}
              // {...field.}
              onBlur={field.onBlur}
              inputProps={
                placeholder ? { ...inputProps, placeholder } : inputProps
              }
              error={touched && error}
              id={field.name}
              helperText={_renderHelperText()}
            />
          )}
          value={selectedDate}
          //invalidDateMessage={isError && error}
          onChange={_onChange}
          maxDate={maxDate}
          minDate={minDate}
        />
      </LocalizationProvider>
    </>
  );
}
