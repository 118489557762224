import DashboardNavbar from './DashboardNavbar';
import { Outlet } from 'react-router-dom';
import DashboardFooter from './DashboardFooter';
import { Box } from '@mui/system';
import { useDisclosure } from '../../hooks/useDisclosure';
import { makeStyles } from '@mui/styles';
import { LayoutContext } from '../../contexts';
import ErrorBoundary from '../ErrorBoundary';
import { drawerWidth, footerHeight, headerHeight } from '../../config';

const useLayoutStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentRoot: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 1,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: (props) => props.headerHeight,
    minHeight: (props) => `calc(100%-${props.headerHeight})`,
  },
  footer: {
    flexGrow: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  footerShift: {
    flexGrow: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: (props) => props.drawerWidth,
  },
}));

const DashboardLayout = () => {
  const sidebar = useDisclosure({
    defaultIsOpen: false,
  });
  const classes = useLayoutStyles({
    headerHeight,
    footerHeight,
    drawerWidth,
  });

  return (
    <ErrorBoundary>
      <LayoutContext.Provider value={{ sidebar }}>
        <Box className={classes.root}>
          <DashboardNavbar />
          <Box className={classes.contentRoot} component="main">
            <Outlet />
          </Box>
          <Box sx={{ display: { lg: 'block', xs: 'none' } }}>
            <DashboardFooter
              className={!sidebar.isOpen ? classes.footer : classes.footerShift}
            />
          </Box>
        </Box>
      </LayoutContext.Provider>
    </ErrorBoundary>
  );
};

export default DashboardLayout;
