import { FC } from 'react';
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { InitialValues } from '../schema';
import NewBorrowerFormFieldset from '../NewBorrowerFormFieldset';
import { Box, Grid, Typography } from '@mui/material';
import { CustomTooltip } from '../../../../CustomTooltip';
import SelectField from '../../../../hook_form_fields/SelectField';
import { InfoIcon } from '../../../../loan_application/icons';
import NumberField from '../../../../hook_form_fields/NumberField';
import CheckboxField from '../../../../hook_form_fields/CheckboxField';
import { PLACEHOLDERS } from '../../../../../constants';
import InputField from '../../../../hook_form_fields/InputField';
const nonSolarChoices = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const NonSolarProjectComponents: FC<{
  control: Control<InitialValues>;
  setValue: UseFormSetValue<InitialValues>;
}> = ({ control, setValue }) => {
  const nonSolarValues = useWatch({ control, name: 'nonSolar' });
  return (
    <NewBorrowerFormFieldset>
      <Grid
        container
        maxWidth="md"
        spacing={2}
        title="Non-Solar Project Components"
      >
        <Grid item xs={12} sm={12}>
          <Typography variant="body2" mt={2} mb={1}>
            Does a portion of the requested loan amount fund one or more of the
            following: energy storage, EV charging, roof repairs/replacement, or
            other non-solar property enhancements?
            <CustomTooltip
              title={
                'Underwriting may require additional information about these improvements'
              }
            >
              <InfoIcon
                sx={{
                  fontSize: '.75rem',
                  marginTop: '.3125rem',
                  marginLeft: '.25rem',
                  verticalAlign: 'center',
                }}
              />
            </CustomTooltip>
            <Typography variant="body2" color="error" component="span">
              {' '}
              *
            </Typography>
          </Typography>
          <SelectField
            fullWidth
            control={control}
            name="nonSolar.hasNonSolarComponent"
            required
            choices={nonSolarChoices}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          {nonSolarValues?.hasNonSolarComponent && (
            <>
              <Grid item xs={12} sm={12}>
                <NumberField
                  thousandSeparator
                  startAdornmentText="$"
                  control={control}
                  name="nonSolar.nonSolarAmount"
                  label="Estimated total cost of the non-solar project components"
                  required
                  fullWidth
                />
              </Grid>
              <Grid container mt={1}>
                <Grid item xs={9} sm={9}>
                  <Typography variant="body2">
                    Which of the following improvements will be funded by the
                    loan?
                    <CustomTooltip
                      title={
                        'Underwriting may require additional information about these improvements'
                      }
                    >
                      <InfoIcon
                        sx={{
                          fontSize: '.75rem',
                          marginTop: '.3125rem',
                          marginLeft: '.25rem',
                          verticalAlign: 'center',
                        }}
                      />
                    </CustomTooltip>
                    <Typography variant="body2" color="error" component="span">
                      {' '}
                      *
                    </Typography>
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#868795' }}>
                    Please enter the estimated cost of each improvement if you
                    have it.
                  </Typography>
                  <Controller
                    control={control}
                    name="nonSolarChecked"
                    render={({ fieldState, formState }) => {
                      const { error } = fieldState;
                      const { touchedFields } = formState;
                      const nonSolarTouched = touchedFields['nonSolar'];
                      const anyNonSolarCheckboxTouched =
                        nonSolarTouched?.['energyStorage'] ||
                        nonSolarTouched?.['evCharging'] ||
                        nonSolarTouched?.['roofRepair'] ||
                        nonSolarTouched?.['other'];
                      return error && anyNonSolarCheckboxTouched ? (
                        <Typography
                          variant="body2"
                          color="error"
                          component="span"
                        >
                          {error.message}
                        </Typography>
                      ) : (
                        <></>
                      );
                    }}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <CheckboxField
                      control={control}
                      name="nonSolar.energyStorage"
                      color="primary"
                      label="Energy storage"
                      onClick={() => {
                        setValue('application.energy_storage_amount', '');
                      }}
                    />
                    <NumberField
                      thousandSeparator
                      startAdornmentText="$"
                      placeholder={PLACEHOLDERS.OPTIONAL}
                      control={control}
                      name="application.energy_storage_amount"
                      inputProps={{
                        style: {
                          width: '100px',
                          height: '8px',
                          WebkitTextFillColor: !nonSolarValues?.energyStorage
                            ? '#868795'
                            : '#333333',
                        },
                      }}
                      isDisabled={!nonSolarValues?.energyStorage}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <CheckboxField
                      control={control}
                      name="nonSolar.evCharging"
                      color="primary"
                      label="EV charging"
                      onClick={() => {
                        setValue('application.ev_charging_amount', '');
                      }}
                    />
                    <NumberField
                      thousandSeparator
                      startAdornmentText="$"
                      placeholder={PLACEHOLDERS.OPTIONAL}
                      control={control}
                      name="application.ev_charging_amount"
                      inputProps={{
                        style: {
                          width: '100px',
                          height: '8px',
                          WebkitTextFillColor: !nonSolarValues?.evCharging
                            ? '#868795'
                            : '#333333',
                        },
                      }}
                      isDisabled={!nonSolarValues?.evCharging}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <CheckboxField
                      control={control}
                      name="nonSolar.roofRepair"
                      color="primary"
                      label="Roof repairs/replacement"
                      onClick={() => {
                        setValue('application.roof_repair_amount', '');
                      }}
                    />
                    <NumberField
                      thousandSeparator
                      startAdornmentText="$"
                      placeholder={PLACEHOLDERS.OPTIONAL}
                      control={control}
                      name="application.roof_repair_amount"
                      inputProps={{
                        style: {
                          width: '100px',
                          height: '8px',
                          WebkitTextFillColor: !nonSolarValues?.roofRepair
                            ? '#868795'
                            : '#333333',
                        },
                      }}
                      isDisabled={!nonSolarValues?.roofRepair}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'start',
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyItems: 'start' }}>
                      <CheckboxField
                        control={control}
                        name="nonSolar.other"
                        color="primary"
                        label="Other:"
                        onClick={() => {
                          setValue('nonSolar.otherValue', '', {
                            shouldValidate: true,
                            shouldDirty: true,
                            shouldTouch: true,
                          });
                          setValue('application.other_non_solar_amount', '');
                        }}
                      />
                      <InputField
                        control={control}
                        name="nonSolar.otherValue"
                        inputProps={{
                          style: { height: '8px' },
                        }}
                        touched
                        isDisabled={!nonSolarValues?.other}
                        sx={{
                          paddingTop: '6px',
                          position: 'relative',
                        }}
                      />
                    </Box>
                    <NumberField
                      thousandSeparator
                      startAdornmentText="$"
                      placeholder={PLACEHOLDERS.OPTIONAL}
                      control={control}
                      name="application.other_non_solar_amount"
                      sx={{ pt: 0.75 }}
                      inputProps={{
                        style: {
                          width: '100px',
                          height: '8px',
                          WebkitTextFillColor: !nonSolarValues?.other
                            ? '#868795'
                            : '#333333',
                        },
                      }}
                      isDisabled={!nonSolarValues?.other}
                    />
                  </Box>
                </Grid>
                <Grid item xs={3} sm={3} />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </NewBorrowerFormFieldset>
  );
};

export default NonSolarProjectComponents;
