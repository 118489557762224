import { Box } from '@mui/material';
import logo from './logoIcon.png';
import stagingLogo from './stagingLogoIcon.png';

const isStaging = process.env.INPUT_ENVIRONMENT === 'staging';

const logoSrc = isStaging ? stagingLogo : logo;

const Logo = (props) => (
  <Box version="1.1" {...props}>
    <img src={logoSrc} alt="Logo" {...props} />
  </Box>
);
export default Logo;
