import { SvgIcon, SvgIconProps } from '@mui/material';
import { forwardRef } from 'react';
import Icon from './Calendar.svg';

const CalendarIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  function CalendarIcon(props, ref) {
    return (
      <SvgIcon ref={ref} viewBox="0 0 55 55" {...props}>
        <Icon />
      </SvgIcon>
    );
  }
);

export default CalendarIcon;
