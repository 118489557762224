import * as Yup from 'yup';
import { subYears } from 'date-fns';
import loanFormModel from './loanFormModel';
import {
  getEmailValidationSchema,
  getPhoneNumValidationSchema,
  getStringFieldValidationSchema,
} from '../../../utils/validation';
import {
  genericRequiredFieldError,
  RELATED_PARTY_TYPES,
  LIMITS,
  SYSTEM_POWER_USAGE_VALUES,
} from '../../../constants';
import { LoanCategory } from '../../../../../../types/api';
const {
  formField: {
    // Borrower contact information
    primaryContactName,
    primaryContactEmail,
    primaryContactPhoneNumber,
    // Business Identification
    borrowerTIN,
    // Business Address
    businessAddress,
    businessAddressCity,
    businessAddressState,
    businessAddressZipCode,
    // Mailing Address
    mailingAddressSameAsBusinessPicklist,
    mailingAddress,
    mailingAddressCity,
    mailingAddressState,
    mailingAddressZipCode,

    // Solar System Location
    systemPropertyAddressIsSameAsBusiness,
    systemPropertyLegalNameOfOwner,
    systemPropertyOwnerAffiliatedWithBorrower,
    systemPropertyLeaseExpirationYear,
    systemPropertyAppraisedValue,
    loanLender,
    loanBalance,
    loanPurpose,
    loanMonthlyPayment,
    outstandingLoanType,
    loanYearOfMaturity,
    onSystemProperty,

    //System Address
    systemPropertyAddress,
    systemPropertyAddressCity,
    systemPropertyAddressState,
    systemPropertyAddressZipCode,
    //Property where the system will be installed status
    systemPropertyOwnershipStatus,
    // Mortgage fields
    systemPropertyCurrentlyPayingMortgage,
    systemPropertyLandlordConsent,

    // System Power page
    systemPowerUsage,
    systemPowerCompanyType,
    nonProfitRecievedBoardApproval,
    systemPowerDescribeBorrower,
    systemPowerSingleOrMultipleUsers,
    systemPowerTaxEquityPartnering,
    systemPowerTaxEquityFundingPercentage,
    systemPowerOfftakerNeedsPercentage,
    systemPowerOfftakerUtilityRateDiscount,
    systemPowerCommunityOrPPALease,
    systemPowerNumberOfOfftakers,
    systemPowerLegalNamesOfOfftakers,
    systemPowerSubscribersAmount,
    systemPowerSubscribersDiscount,
    systemPowerUtilityJurisdictionName,
    systemPowerSubscriberManagementCompany,

    // Ownership History
    borrowerBusinessStructure,
    borrowerIsReligiousOrganization,
    borrowerReligiousParentOrgSupport,
    borrowerReligiousLeaderYears,
    borrowerStateOfOrganization,
    borrowerLinesOfBusiness,
    borrowerBusinessEstablishedDate,
    borrowerBusinessEstablishedYear,
    borrowerGrossRevenueLatest,
    borrowerYearsManagementInPlace,
    borrowerManagementExperienceYears,
    borrowerIsHighRisk,
    borrowerIsHighRiskExplanation,

    // Principal
    principalFirstName,
    principalLastName,
    principalMiddleInitial,
    principalOccupation,
    principalOtherOccupation,
    principalAddress,
    principalCity,
    principalState,
    principalZip,
    principalCountry,
    principalType,
    principalIsUSCitizen,
    principalTrustName,
    principalTrustTIN,
    principalTrustYearEstablished,
    principalNonUSPersonIdNumber,
    principalNonUSPersonIssuanceCountry,
    principalSsn,
    principalBirthdate,
    principalPhoneNumber,
    principalEmailAddress,
    principalPercentOwned,
    // Bank, Taxes, Lawsuits
    borrowerHasCreditUnderOtherName,
    finInfoNameCreditWasObtainedUnder,

    // Personal Guarantor
    applicantSameAsPrincipal,
    principalIsPG,
    principalAnnualGrossIncome,
    principalLiquidAssets,
    principalRentOrOwn,
    principalMortgagePayment,
    principalRent,
    principalIdDocument,
    principalPGAgreement,
    __isPGValue,
    __asapCount,
    __companyTypeValue,
    loanCategory,
  },
} = loanFormModel;

const defaultBorrowerLegalName = 'Applicant';

const borrowerInformationValidation = {
  [primaryContactName.name]: getStringFieldValidationSchema(primaryContactName),
  [primaryContactEmail.name]: getEmailValidationSchema(primaryContactEmail),
  [primaryContactPhoneNumber.name]: getPhoneNumValidationSchema(
    primaryContactPhoneNumber
  ),
  [mailingAddressSameAsBusinessPicklist.name]: Yup.string()
    .label(mailingAddressSameAsBusinessPicklist.label)
    .nullable()
    .required('An answer is required, please select an option'),
  [mailingAddress.name]: Yup.string().when(
    [mailingAddressSameAsBusinessPicklist.name],
    {
      is: 'Yes',
      then: Yup.string().nullable(),
      otherwise: Yup.string().label(mailingAddress.label).required().nullable(),
    }
  ),
  [mailingAddressCity.name]: Yup.string().when(
    [mailingAddressSameAsBusinessPicklist.name],
    {
      is: 'Yes',
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .label(mailingAddressCity.label)
        .required()
        .nullable(),
    }
  ),
  [mailingAddressState.name]: Yup.string().when(
    [mailingAddressSameAsBusinessPicklist.name],
    {
      is: 'Yes',
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .label(mailingAddressState.label)
        .required()
        .nullable(),
    }
  ),
  [mailingAddressZipCode.name]: Yup.string().when(
    [mailingAddressSameAsBusinessPicklist.name],
    {
      is: 'Yes',
      then: Yup.string().nullable(),
      otherwise: Yup.string()
        .label(mailingAddressZipCode.label)
        .min(5, 'Must be exactly 5 digits')
        .max(5, 'Must be exactly 5 digits')
        .matches(/^[0-9]+$/, 'Must be only digits')
        .required()
        .nullable(),
    }
  ),
  [borrowerIsHighRisk.name]: Yup.string()
    .nullable()
    .when(
      [borrowerBusinessStructure.name],
      (borrowerBusinessStructure, schema) => {
        if (borrowerBusinessStructure !== 'NON-PROFIT') {
          return schema.required(borrowerIsHighRisk.requiredErrorMsg);
        }
        return schema.nullable();
      }
    ),
  [borrowerIsHighRiskExplanation.name]: Yup.string()
    .nullable()
    .when(
      [borrowerIsHighRisk.name, borrowerBusinessStructure.name],
      (borrowerIsHighRisk, borrowerBusinessStructure, schema) => {
        if (borrowerBusinessStructure === 'NON-PROFIT')
          return schema.notRequired();
        if (borrowerIsHighRisk === 'Yes')
          return schema.required(genericRequiredFieldError);
        return schema.notRequired();
      }
    ),
};

const businessAddressValidation = {
  [businessAddress.name]: Yup.string()
    .required()
    .label(businessAddress.label)
    .nullable(),
  [businessAddressCity.name]: Yup.string()
    .label(businessAddressCity.label)
    .required()
    .nullable(),
  [businessAddressState.name]: Yup.string()
    .label(businessAddressState.label)
    .required()
    .nullable(),
  [businessAddressZipCode.name]: Yup.string()
    .label(businessAddressZipCode.label)
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .required()
    .nullable(),
};

export const businessIdentification = {
  ...borrowerInformationValidation,
  ...businessAddressValidation,
};

export const solarSystemProperty = {
  [systemPropertyAddressIsSameAsBusiness.name]: Yup.string()
    .nullable()
    .required('This field is required'),
  // Address fields
  [systemPropertyAddress.name]: Yup.string()
    .label(systemPropertyAddress.label)
    .nullable()
    .when(
      [systemPropertyAddressIsSameAsBusiness.name],
      (isSameAsBusiness, schema) => {
        if (isSameAsBusiness === 'No') return schema.required();
        return schema;
      }
    ),
  [systemPropertyAddressCity.name]: Yup.string()
    .label(systemPropertyAddressCity.label)
    .nullable()
    .when(
      [systemPropertyAddressIsSameAsBusiness.name],
      (isSameAsBusiness, schema) => {
        if (isSameAsBusiness === 'No') return schema.required();
        return schema;
      }
    ),
  [systemPropertyAddressState.name]: Yup.string()
    .label(systemPropertyAddressState.label)
    .nullable()
    .when(
      [systemPropertyAddressIsSameAsBusiness.name],
      (isSameAsBusiness, schema) => {
        if (isSameAsBusiness === 'No') return schema.required();
        return schema;
      }
    ),
  [systemPropertyAddressZipCode.name]: Yup.string()
    .label(systemPropertyAddressZipCode.label)
    .nullable()
    .when(
      [systemPropertyAddressIsSameAsBusiness.name],
      (isSameAsBusiness, schema) => {
        if (isSameAsBusiness === 'No') return schema.required();
        return schema;
      }
    ),
  [systemPropertyOwnershipStatus.name]: Yup.string()
    .nullable()
    .required('This field is required'),
  // owned ownership validation
  [systemPropertyCurrentlyPayingMortgage.name]: Yup.string()
    .nullable()
    .when([systemPropertyOwnershipStatus.name], (ownershipType, schema) => {
      if (ownershipType === 'OWNED')
        return schema.required('This field is required');
      return schema;
    }),
  [systemPropertyAppraisedValue.name]: Yup.number()
    .nullable()
    .when([systemPropertyOwnershipStatus.name], (ownershipType, schema) => {
      if (ownershipType === 'OWNED')
        return (
          schema
            .positive('Please enter a positive number.')
            // Max of 16 digits
            .max(9999999999999999n, 'Please enter a valid number.')
            .required('This field is required')
        );
      return schema;
    }),
  // Outstanding Loan Obligation for Mortgage required if systemPropertyCurrentlyPayingMortgage === 'Yes' and ownershipType === 'OWN'
  outstanding_loans: Yup.array().when(
    [
      systemPropertyOwnershipStatus.name,
      systemPropertyCurrentlyPayingMortgage.name,
    ],
    (ownershipType, payingMortgage, schema) => {
      if (ownershipType === 'OWNED' && payingMortgage === 'Yes') {
        return schema
          .of(
            Yup.object().shape({
              [loanLender.name]: Yup.string().when('on_system_property', {
                is: 'Yes',
                then: (schema) =>
                  schema.required(`${loanLender.requiredErrorMsg}`).nullable(),
                otherwise: (schema) => schema.nullable(),
              }),
              [outstandingLoanType.name]: Yup.string().when(
                'on_system_property',
                {
                  is: 'Yes',
                  then: (schema) =>
                    schema
                      .required(`${outstandingLoanType.requiredErrorMsg}`)
                      .nullable(),
                  otherwise: (schema) => schema.nullable(),
                }
              ),
              [loanBalance.name]: Yup.number()
                .when('on_system_property', {
                  is: 'Yes',
                  then: (schema) =>
                    schema
                      .required(`${loanBalance.requiredErrorMsg}`)
                      .nullable(),
                  otherwise: (schema) => schema.nullable(),
                })
                .typeError('Please enter a number.')
                .min(10000, 'Remaining principal must be greater than $10,000'),
              [loanYearOfMaturity.name]: Yup.number()
                .when('on_system_property', {
                  is: 'Yes',
                  then: (schema) =>
                    schema.required('This field is required').nullable(),
                  otherwise: (schema) => schema.nullable(),
                })
                .typeError(genericRequiredFieldError)
                .min(
                  new Date().getFullYear() + 1,
                  'Loan should have a remaining term of at least 1 year'
                ),
              [loanMonthlyPayment.name]: Yup.number()
                .when('on_system_property', {
                  is: 'Yes',
                  then: (schema) =>
                    schema
                      .required(`${loanMonthlyPayment.requiredErrorMsg}`)
                      .nullable(),
                  otherwise: (schema) => schema.nullable(),
                })
                .typeError('Please enter a number.')
                .min(0.01, 'Please enter a number greater than 0')
                .max(
                  Yup.ref(loanBalance.name),
                  'Please enter an amount less than the total loan balance'
                ),
            })
          )
          .min(1, 'Please add at least one loan.')
          .test(
            'at-least-one-on-system-property',
            'At least one loan must have "on_system_property" set to "Yes"',
            function (loans) {
              return loans.some((loan) => loan.on_system_property === 'Yes');
            }
          );
      }
      return schema;
    }
  ),
  // leased ownership validation
  [systemPropertyLandlordConsent.name]: Yup.string()
    .nullable()
    .when([systemPropertyOwnershipStatus.name], (ownershipType, schema) => {
      if (ownershipType === 'LEASED')
        return schema.required('This field is required');
      return schema;
    }),
  [systemPropertyLegalNameOfOwner.name]: Yup.string()
    .nullable()
    .when([systemPropertyOwnershipStatus.name], (ownershipType, schema) => {
      if (ownershipType === 'LEASED')
        return schema.required('This field is required');
      return schema;
    }),
  [systemPropertyOwnerAffiliatedWithBorrower.name]: Yup.string()
    .nullable()
    .when([systemPropertyOwnershipStatus.name], (ownershipType, schema) => {
      if (ownershipType === 'LEASED')
        return schema.required('This field is required');
      return schema;
    }),
  [systemPropertyLeaseExpirationYear.name]: Yup.number()
    .nullable()
    .when([systemPropertyOwnershipStatus.name], (ownershipType, schema) => {
      if (ownershipType === 'LEASED')
        return schema
          .min(new Date().getFullYear(), 'Lease cannot expire in the past')
          .required('This field is required');
      return schema;
    }),
};

export const systemPower = {
  [systemPowerUsage.name]: Yup.string()
    .label(systemPowerUsage.label)
    .required(genericRequiredFieldError),
  [systemPowerCompanyType.name]: Yup.string()
    .label(systemPowerCompanyType.getLabel('Borrower'))
    .when(systemPowerUsage.name, (usage, schema) => {
      if (usage === SYSTEM_POWER_USAGE_VALUES.OFFSET_BORROWERS_UTILITY_BILL) {
        return schema.required(genericRequiredFieldError).nullable();
      }
      return schema.nullable();
    }),
  [nonProfitRecievedBoardApproval.name]: Yup.string()
    .label(nonProfitRecievedBoardApproval.getLabel(defaultBorrowerLegalName))
    .when(
      [systemPowerUsage.name, systemPowerCompanyType.name],
      (usage, companyType, schema) => {
        if (
          usage === SYSTEM_POWER_USAGE_VALUES.OFFSET_BORROWERS_UTILITY_BILL &&
          companyType === 'Non-Profit'
        ) {
          return schema.required(genericRequiredFieldError).nullable();
        }
        return schema.nullable();
      }
    ),
  [systemPowerDescribeBorrower.name]: Yup.string()
    .label(systemPowerDescribeBorrower.getLabel(defaultBorrowerLegalName))
    .when(systemPowerUsage.name, (usage, schema) => {
      if (usage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY) {
        return schema
          .required(genericRequiredFieldError)
          .test(
            'isNonEmptyIfOther',
            "Please Enter 'Other' description if you selected 'Other'",
            (value) => {
              // Value should not equal empty 'Other: ' string,
              return value !== 'Other: ';
            }
          );
      }
      return schema.nullable();
    }),
  [systemPowerSingleOrMultipleUsers.name]: Yup.string()
    .label(systemPowerSingleOrMultipleUsers.getLabel(defaultBorrowerLegalName))
    .when(systemPowerUsage.name, (usage, schema) => {
      if (usage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY) {
        return schema.required(genericRequiredFieldError).nullable();
      }
      return schema.nullable();
    }),
  [systemPowerTaxEquityPartnering.name]: Yup.string()
    .label(systemPowerTaxEquityPartnering.getLabel(defaultBorrowerLegalName))
    .when(
      [
        systemPowerUsage.name,
        systemPowerSingleOrMultipleUsers.name,
        systemPowerCommunityOrPPALease.name,
      ],
      (usage, singleOrMultipleUsers, communityOrPPALease, schema) => {
        if (
          usage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
          (singleOrMultipleUsers === 'Single User' ||
            (singleOrMultipleUsers === 'Multiple Users' &&
              communityOrPPALease === 'PPA/Lease Agreement'))
        ) {
          return schema.required(genericRequiredFieldError).nullable();
        }
        return schema.nullable();
      }
    ),
  [systemPowerTaxEquityFundingPercentage.name]: Yup.string()
    .label(systemPowerTaxEquityFundingPercentage.label)
    .when(
      [
        systemPowerUsage.name,
        systemPowerSingleOrMultipleUsers.name,
        systemPowerCommunityOrPPALease.name,
        systemPowerTaxEquityPartnering.name,
      ],
      (
        usage,
        singleOrMultipleUsers,
        communityOrPPALease,
        taxEquityPartnering,
        schema
      ) => {
        if (
          usage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
          (singleOrMultipleUsers === 'Single User' ||
            (singleOrMultipleUsers === 'Multiple Users' &&
              communityOrPPALease === 'PPA/Lease Agreement')) &&
          taxEquityPartnering === 'Yes'
        ) {
          return schema.required(genericRequiredFieldError).nullable();
        }
        return schema.nullable();
      }
    ),
  [systemPowerOfftakerNeedsPercentage.name]: Yup.string()
    .label(
      systemPowerOfftakerNeedsPercentage.getLabel(defaultBorrowerLegalName)
    )
    .when(
      [
        systemPowerUsage.name,
        systemPowerSingleOrMultipleUsers.name,
        systemPowerCommunityOrPPALease.name,
      ],
      (usage, singleOrMultipleUsers, communityOrPPALease, schema) => {
        if (
          usage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
          (singleOrMultipleUsers === 'Single User' ||
            (singleOrMultipleUsers === 'Multiple Users' &&
              communityOrPPALease === 'PPA/Lease Agreement'))
        ) {
          return schema.required(genericRequiredFieldError).nullable();
        }
        return schema.nullable();
      }
    ),
  [systemPowerOfftakerUtilityRateDiscount.name]: Yup.string()
    .label(
      systemPowerOfftakerUtilityRateDiscount.getLabel(defaultBorrowerLegalName)
    )
    .when(
      [
        systemPowerUsage.name,
        systemPowerSingleOrMultipleUsers.name,
        systemPowerCommunityOrPPALease.name,
      ],
      (usage, singleOrMultipleUsers, communityOrPPALease, schema) => {
        if (
          usage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
          (singleOrMultipleUsers === 'Single User' ||
            (singleOrMultipleUsers === 'Multiple Users' &&
              communityOrPPALease === 'PPA/Lease Agreement'))
        ) {
          return schema.required(genericRequiredFieldError).nullable();
        }
        return schema.nullable();
      }
    ),
  [systemPowerCommunityOrPPALease.name]: Yup.string()
    .label(systemPowerCommunityOrPPALease.label)
    .when(
      [systemPowerUsage.name, systemPowerSingleOrMultipleUsers.name],
      (usage, singleOrMultipleUsers, schema) => {
        if (
          usage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
          singleOrMultipleUsers === 'Multiple Users'
        ) {
          return schema.required(genericRequiredFieldError).nullable();
        }
        return schema.nullable();
      }
    ),
  [systemPowerNumberOfOfftakers.name]: Yup.number()
    .label(systemPowerNumberOfOfftakers.getLabel(defaultBorrowerLegalName))
    .when(
      [
        systemPowerUsage.name,
        systemPowerSingleOrMultipleUsers.name,
        systemPowerCommunityOrPPALease.name,
      ],
      (usage, singleOrMultipleUsers, communityOrPPALease, schema) => {
        if (
          usage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
          singleOrMultipleUsers === 'Multiple Users' &&
          communityOrPPALease === 'PPA/Lease Agreement'
        ) {
          return schema
            .required(genericRequiredFieldError)
            .nullable()
            .min(1)
            .max(9);
        }
        return schema.nullable();
      }
    ),
  [systemPowerLegalNamesOfOfftakers.name]: Yup.string()
    .label(systemPowerLegalNamesOfOfftakers.label)
    .when(
      [
        systemPowerUsage.name,
        systemPowerSingleOrMultipleUsers.name,
        systemPowerCommunityOrPPALease.name,
      ],
      (usage, singleOrMultipleUsers, communityOrPPALease, schema) => {
        if (
          usage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
          singleOrMultipleUsers === 'Multiple Users' &&
          communityOrPPALease === 'PPA/Lease Agreement'
        ) {
          return schema
            .required(genericRequiredFieldError)
            .nullable()
            .test(
              'are-legal-names-filled',
              'Please enter a legal name for each Offtaker.',
              (value) => {
                // Split by newline
                const offtakerNames = value?.split('\n') || [];
                if (offtakerNames.length === 0) {
                  return false;
                }
                // Each member should be longer than 0
                return offtakerNames.every((name) => name.length > 0);
              }
            );
        } else if (
          usage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
          singleOrMultipleUsers === 'Single User'
        ) {
          // Only expect a single name
          return schema.required(genericRequiredFieldError).nullable();
        }
        return schema.nullable();
      }
    ),
  [systemPowerSubscribersAmount.name]: Yup.string()
    .label(systemPowerSubscribersAmount.getLabel(defaultBorrowerLegalName))
    .when(
      [
        systemPowerUsage.name,
        systemPowerSingleOrMultipleUsers.name,
        systemPowerCommunityOrPPALease.name,
      ],
      (usage, singleOrMultipleUsers, communityOrPPALease, schema) => {
        if (
          usage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
          singleOrMultipleUsers === 'Multiple Users' &&
          communityOrPPALease === 'Community Solar Arrangement'
        ) {
          return schema.required(genericRequiredFieldError).nullable();
        }
        return schema.nullable();
      }
    ),
  [systemPowerSubscribersDiscount.name]: Yup.string()
    .label(systemPowerSubscribersDiscount.getLabel(defaultBorrowerLegalName))
    .when(
      [
        systemPowerUsage.name,
        systemPowerSingleOrMultipleUsers.name,
        systemPowerCommunityOrPPALease.name,
      ],
      (usage, singleOrMultipleUsers, communityOrPPALease, schema) => {
        if (
          usage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
          singleOrMultipleUsers === 'Multiple Users' &&
          communityOrPPALease === 'Community Solar Arrangement'
        ) {
          return schema.required(genericRequiredFieldError).nullable();
        }
        return schema.nullable();
      }
    ),
  [systemPowerUtilityJurisdictionName.name]: Yup.string()
    .label(systemPowerUtilityJurisdictionName.label)
    .when(
      [
        systemPowerUsage.name,
        systemPowerSingleOrMultipleUsers.name,
        systemPowerCommunityOrPPALease.name,
      ],
      (usage, singleOrMultipleUsers, communityOrPPALease, schema) => {
        if (
          usage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
          singleOrMultipleUsers === 'Multiple Users' &&
          communityOrPPALease === 'Community Solar Arrangement'
        ) {
          return schema.required(genericRequiredFieldError).nullable();
        }
        return schema.nullable();
      }
    ),
  [systemPowerSubscriberManagementCompany.name]: Yup.string()
    .label(
      systemPowerSubscriberManagementCompany.getLabel(defaultBorrowerLegalName)
    )
    .when(
      [
        systemPowerUsage.name,
        systemPowerSingleOrMultipleUsers.name,
        systemPowerCommunityOrPPALease.name,
      ],
      (usage, singleOrMultipleUsers, communityOrPPALease, schema) => {
        if (
          usage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
          singleOrMultipleUsers === 'Multiple Users' &&
          communityOrPPALease === 'Community Solar Arrangement'
        ) {
          return schema.required(genericRequiredFieldError).nullable();
        }
        return schema.nullable();
      }
    ),
};

export const ownershipHistory = {
  [borrowerGrossRevenueLatest.name]: Yup.number()
    .nullable()
    .required('This field is required'),
  [borrowerTIN.name]: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(9, 'Must be exactly 9 digits')
    .max(9, 'Must be exactly 9 digits')
    .required(`${borrowerTIN.requiredErrorMsg}`)
    .nullable(),
  [borrowerBusinessStructure.name]: Yup.string()
    .required(`${borrowerBusinessStructure.requiredErrorMsg}`)
    .nullable(),
  [borrowerIsReligiousOrganization.name]: Yup.string()
    .nullable()
    .when(
      [borrowerBusinessStructure.name],
      (borrowerBusinessStructure, schema) => {
        if (borrowerBusinessStructure === 'NON-PROFIT') {
          return schema.required(genericRequiredFieldError);
        }
        return schema.nullable();
      }
    ),
  [borrowerReligiousParentOrgSupport.name]: Yup.string()
    .nullable()
    .when(
      [borrowerIsReligiousOrganization.name],
      (borrowerIsReligiousOrganization, schema) => {
        if (borrowerIsReligiousOrganization === 'Yes') {
          return schema.required(genericRequiredFieldError);
        }
        return schema.nullable();
      }
    ),
  [borrowerReligiousLeaderYears.name]: Yup.string()
    .nullable()
    .when(
      [borrowerIsReligiousOrganization.name],
      (borrowerIsReligiousOrganization, schema) => {
        if (borrowerIsReligiousOrganization === 'Yes') {
          return schema.required(genericRequiredFieldError);
        }
        return schema.nullable();
      }
    ),
  [borrowerStateOfOrganization.name]: Yup.string()
    .nullable()
    .when(
      [borrowerBusinessStructure.name],
      (borrowerBusinessStructure, schema) => {
        if (borrowerBusinessStructure !== 'SOLE PROPRIETORSHIP') {
          return schema.required(genericRequiredFieldError);
        }
        return schema.nullable();
      }
    ),
  [borrowerLinesOfBusiness.name]: Yup.string()
    .required(`${borrowerLinesOfBusiness.requiredErrorMsg}`)
    .nullable(),
  [borrowerBusinessEstablishedYear.name]: Yup.number()
    .nullable()
    .min(LIMITS.MIN_YEAR_ALLOWED, 'Please enter a valid year.')
    .max(new Date().getFullYear(), 'Please enter a valid year.')
    .required('This field is required'),
  [borrowerYearsManagementInPlace.name]: Yup.number()
    .nullable()
    .label(borrowerYearsManagementInPlace.getLabel(defaultBorrowerLegalName))
    .required(genericRequiredFieldError)
    .when(
      [borrowerBusinessEstablishedYear.name],
      (borrowerBusinessEstablishedYear, schema) => {
        // This value can't be greater than the difference between the current year and the year the business was established
        const currentYear = new Date().getFullYear();
        // if borrowerBusinessEstablishedYear is a valid year and is at least >= 1900, then we can calculate the max value
        const establishedYear = Number(borrowerBusinessEstablishedYear);
        if (establishedYear && establishedYear >= LIMITS.MIN_YEAR_ALLOWED) {
          const maxYearsManagementInPlace = currentYear - establishedYear;
          return schema.max(maxYearsManagementInPlace);
        }
        return schema;
      }
    ),
  [borrowerManagementExperienceYears.name]: Yup.number()
    .nullable()
    .when(
      [borrowerYearsManagementInPlace.name],
      (borrowerYearsManagementInPlace, schema) => {
        if (borrowerYearsManagementInPlace < 15)
          return schema.required(genericRequiredFieldError);
        return schema.notRequired();
      }
    )
    .max(LIMITS.MAX_YEARS_ESTABLISHED),
};

// everything, all the time
export const principals = {
  [borrowerHasCreditUnderOtherName.name]: Yup.string()
    .required(`${borrowerHasCreditUnderOtherName.requiredErrorMsg}`)
    .nullable(),
  [finInfoNameCreditWasObtainedUnder.name]: Yup.string()
    .nullable()
    .when(
      [borrowerHasCreditUnderOtherName.name],
      (borrowerHasCreditUnderOtherName, schema) => {
        if (borrowerHasCreditUnderOtherName === 'Yes') {
          return schema.required(genericRequiredFieldError);
        }
        return schema.nullable();
      }
    ),
  principals: Yup.array()
    .of(
      Yup.object().shape({
        [principalType.name]: Yup.string()
          .nullable()
          .required(principalType.requiredErrorMsg),
        [principalTrustName.name]: Yup.string()
          .nullable()
          .when([principalType.name], (principalType, schema) => {
            if (principalType === RELATED_PARTY_TYPES.ENTITY) {
              return schema.required(genericRequiredFieldError);
            }
            return schema.notRequired();
          }),
        [principalTrustTIN.name]: Yup.string()
          .nullable()
          .when([principalType.name], (principalType, schema) => {
            if (principalType === RELATED_PARTY_TYPES.ENTITY) {
              return schema.required(genericRequiredFieldError);
            }
            return schema.notRequired();
          }),
        [principalTrustYearEstablished.name]: Yup.number()
          .nullable()
          .when([principalType.name], (principalType, schema) => {
            if (principalType === RELATED_PARTY_TYPES.ENTITY) {
              return schema
                .required(genericRequiredFieldError)
                .min(1900, 'Please enter a valid year.')
                .max(new Date().getFullYear(), 'Please enter a valid year.');
            }
            return schema.notRequired();
          }),
        [principalOccupation.name]: Yup.string()
          .nullable()
          .when([principalType.name], (principalType, schema) => {
            if (principalType === RELATED_PARTY_TYPES.INDIVIDUAL) {
              return schema.required(principalOccupation.requiredErrorMsg);
            }
            return schema.notRequired();
          }),
        [principalOtherOccupation.name]: Yup.string()
          .nullable()
          .when([principalOccupation.name], (principalOccupation, schema) => {
            if (principalOccupation === 'Other') {
              return schema.required(principalOtherOccupation.requiredErrorMsg);
            }
            return schema.notRequired();
          }),
        [principalAddress.name]: Yup.string()
          .required(`${principalAddress.requiredErrorMsg}`)
          .nullable(),
        [principalBirthdate.name]: Yup.date()
          .typeError('Please enter a valid date.')
          .max(
            subYears(new Date(), 18),
            'Principal must be at least 18 years old'
          )
          .nullable()
          .when([principalType.name], (principalType, schema) => {
            if (principalType === RELATED_PARTY_TYPES.INDIVIDUAL) {
              return schema.required(principalBirthdate.requiredErrorMsg);
            }
            return schema.notRequired();
          }),
        [principalIsUSCitizen.name]: Yup.string()
          .nullable()
          .when(
            [principalType.name, applicantSameAsPrincipal.name],
            (principalType, applicantSameAsPrincipal, schema) => {
              if (
                principalType === RELATED_PARTY_TYPES.INDIVIDUAL &&
                applicantSameAsPrincipal === 'Yes'
              ) {
                return schema.required(principalIsUSCitizen.requiredErrorMsg);
              }
              return schema.notRequired();
            }
          ),
        // US person validation
        [principalNonUSPersonIdNumber.name]: Yup.string()
          .nullable()
          // When principal is non-us, this field is required
          .when([principalType.name, principalIsUSCitizen.name], {
            is: (principalType, principalIsUSCitizen) =>
              principalType === RELATED_PARTY_TYPES.INDIVIDUAL &&
              principalIsUSCitizen === 'No',
            then: Yup.string()
              .required(principalNonUSPersonIdNumber.requiredErrorMsg)
              .min(2)
              .nullable(),
            otherwise: Yup.string()
              .transform(() => null)
              .nullable(),
          }),
        [principalNonUSPersonIssuanceCountry.name]: Yup.string()
          // When principal is non-us, this field is required
          .when([principalType.name, principalIsUSCitizen.name], {
            is: (principalType, principalIsUSCitizen) =>
              principalType === RELATED_PARTY_TYPES.INDIVIDUAL &&
              principalIsUSCitizen === 'No',
            then: Yup.string()
              .required(principalNonUSPersonIssuanceCountry.requiredErrorMsg)
              .min(2)
              .nullable(),
            otherwise: Yup.string()
              .transform(() => null)
              .nullable(),
          }),
        [principalSsn.name]: Yup.string()
          .nullable()
          .when([principalType.name, principalIsUSCitizen.name], {
            is: (principalType, principalIsUSCitizen) =>
              principalType === RELATED_PARTY_TYPES.INDIVIDUAL &&
              principalIsUSCitizen === 'Yes',
            then: (schema) =>
              schema
                .required(principalSsn.requiredErrorMsg)
                .min(9, 'Social Security Number has 9 digits')
                .max(9, 'Social Security Number has 9 digits'),
            otherwise: (schema) => schema.transform(() => null),
          }),
        [principalPhoneNumber.name]: Yup.string()
          .typeError('Must be a number')
          .matches(/^[0-9]+$/, 'Must be only digits')
          .min(10, 'Must be 10 digits')
          .max(10, 'Must be 10 digits')
          .required(`${principalPhoneNumber.requiredErrorMsg}`)
          .nullable(),
        [principalEmailAddress.name]: getEmailValidationSchema(
          principalEmailAddress
        ),
        [principalPercentOwned.name]: Yup.number()
          .typeError('Must be a number')
          .min(0, 'Percentage must be a positive number')
          .max(100, 'Please enter a valid percentage')
          .test(
            'is-valid-percentage',
            "Ownership percentage total for all principals can't exceed 100%",
            (value, testContext) => {
              // Uses undocumented testContext.from to access the parent array.
              const [_parent, application] = testContext.from;
              const total = application?.value?.principals
                .map((p) => Number(p[principalPercentOwned.name]))
                .reduce((prev, curr) => prev + curr, 0);
              return total <= 100;
            }
          )
          .required(`${principalPercentOwned.requiredErrorMsg}`)
          .nullable(),
        [principalFirstName.name]: Yup.string()
          .nullable()
          .when([principalType.name], (principalType, schema) => {
            if (principalType === RELATED_PARTY_TYPES.INDIVIDUAL) {
              return schema.required(principalFirstName.requiredErrorMsg);
            }
            return schema.notRequired();
          }),
        [principalLastName.name]: Yup.string()
          .nullable()
          .when([principalType.name], (principalType, schema) => {
            if (principalType === RELATED_PARTY_TYPES.INDIVIDUAL) {
              return schema.required(principalLastName.requiredErrorMsg);
            }
            return schema.notRequired();
          }),
        [principalMiddleInitial.name]: Yup.string().nullable(),
        // Address fields
        [principalCity.name]: Yup.string()
          .required(principalCity.requiredErrorMsg)
          .nullable(),
        [principalState.name]: Yup.string()
          .required(principalState.requiredErrorMsg)
          .nullable(),
        [principalZip.name]: Yup.string()
          .min(5, 'Must be exactly 5 digits')
          .max(5, 'Must be exactly 5 digits')
          .matches(/^[0-9]+$/, 'Must be only digits')
          .required(`${principalZip.requiredErrorMsg}`)
          .nullable(),
        [principalCountry.name]: Yup.string()
          .required(principalCountry.requiredErrorMsg)
          .nullable(),
        // PG-related fields
        [principalIsPG.name]: Yup.string()
          .nullable()
          .when([__companyTypeValue.name], function (companyType, schema) {
            return schema.test({
              name: 'principalIsPGTest',
              test: function (value) {
                const loanCategory = this.from[1].value['loan_category'];

                // Changing isPGValue to LoanCategory.EXPRESS fixed a bug which made the field not required if
                // the required 51% was already achieved
                if (
                  loanCategory === LoanCategory.EXPRESS ||
                  companyType === 'Agriculture' ||
                  loanCategory === LoanCategory.EXPRESS_PLUS
                ) {
                  return (
                    !!value ||
                    this.createError({ message: 'Choice is required' })
                  );
                }
                return true;
              },
            });
          })
          .typeError('Choice is required'),
        [applicantSameAsPrincipal.name]: Yup.string()
          .nullable()
          .when(
            [principalIsPG.name, __asapCount.name],
            function (principalIsPG, asapCount, schema) {
              return schema.test({
                name: 'principalSameAsApplicantTest',
                test: function (value) {
                  if (principalIsPG === 'Yes' && asapCount === 0) {
                    if (!value) {
                      return this.createError({
                        message: 'Choice is required',
                      });
                    }
                    return true; // The validation passes if value is not empty
                  }
                  return true; // For all other cases, the field is optional
                },
              });
            }
          )
          .typeError('Choice is required'),
        [principalAnnualGrossIncome.name]: Yup.number()
          .nullable()
          .when(
            [principalIsPG.name, applicantSameAsPrincipal.name],
            (principalIsPG, applicantSameAsPrincipal, schema) => {
              if (
                principalIsPG === 'Yes' &&
                applicantSameAsPrincipal === 'Yes'
              ) {
                return schema.required(
                  principalAnnualGrossIncome.requiredErrorMsg
                );
              }
              return schema.notRequired();
            }
          ),
        [principalLiquidAssets.name]: Yup.number()
          .nullable()
          .when(
            [principalIsPG.name, applicantSameAsPrincipal.name],
            (principalIsPG, applicantSameAsPrincipal, schema) => {
              if (
                principalIsPG === 'Yes' &&
                applicantSameAsPrincipal === 'Yes'
              ) {
                return schema.required(principalLiquidAssets.requiredErrorMsg);
              }
              return schema.notRequired();
            }
          ),
        [principalRentOrOwn.name]: Yup.string()
          .nullable()
          .when(
            [principalIsPG.name, applicantSameAsPrincipal.name],
            (principalIsPG, applicantSameAsPrincipal, schema) => {
              if (
                principalIsPG === 'Yes' &&
                applicantSameAsPrincipal === 'Yes'
              ) {
                return schema.required(principalRentOrOwn.requiredErrorMsg);
              }
              return schema.notRequired();
            }
          ),
        [principalRent.name]: Yup.number()
          .nullable()
          .when(
            [
              principalIsPG.name,
              principalRentOrOwn.name,
              applicantSameAsPrincipal.name,
            ],
            (
              principalIsPG,
              principalRentOrOwn,
              applicantSameAsPrincipal,
              schema
            ) => {
              if (
                principalIsPG === 'Yes' &&
                applicantSameAsPrincipal === 'Yes' &&
                principalRentOrOwn === 'Rent'
              ) {
                return schema.required(principalRent.requiredErrorMsg);
              }
              return schema.notRequired();
            }
          ),
        [principalMortgagePayment.name]: Yup.number()
          .nullable()
          .when(
            [
              principalIsPG.name,
              principalRentOrOwn.name,
              applicantSameAsPrincipal.name,
            ],
            (
              principalIsPG,
              principalRentOrOwn,
              applicantSameAsPrincipal,
              schema
            ) => {
              if (
                principalIsPG === 'Yes' &&
                applicantSameAsPrincipal === 'Yes' &&
                principalRentOrOwn === 'Own'
              ) {
                return schema.required(
                  principalMortgagePayment.requiredErrorMsg
                );
              }
              return schema.notRequired();
            }
          ),
        documents: Yup.object().shape({
          [principalIdDocument.name]: Yup.object().shape({
            url: Yup.string().test(
              'check-required-if-undefined',
              'This document is required',
              function (value) {
                // this.from[2] traverses back up the object tree to the principal so that we can read principal_type
                if (
                  this.from[2].value[principalType.name] ===
                  RELATED_PARTY_TYPES.INDIVIDUAL
                ) {
                  return value != undefined && value != null && value !== '';
                }
                return true;
              }
            ),
          }),
        }),
        // the .oneOf requires that the box must be checked (true)
        [principalPGAgreement.name]: Yup.boolean()
          .nullable()
          .when(
            [principalIsPG.name, applicantSameAsPrincipal.name],
            (principalIsPG, applicantSameAsPrincipal, schema) => {
              if (
                principalIsPG === 'Yes' &&
                applicantSameAsPrincipal === 'Yes'
              ) {
                return schema
                  .required(principalPGAgreement.requiredErrorMsg)
                  .oneOf([true], principalPGAgreement.requiredErrorMsg);
              }
              return schema.notRequired();
            }
          ),
      })
    )
    .required(),
};

export const businessIdentificationSchema = Yup.object().shape(
  businessIdentification
);

export const solarSystemPropertySchema =
  Yup.object().shape(solarSystemProperty);

export const systemPowerSchema = Yup.object().shape(systemPower);

export const ownershipHistorySchema = Yup.object().shape(ownershipHistory, [
  borrowerBusinessEstablishedDate.name,
]);

export const principalsSchema = Yup.object().shape(principals);
