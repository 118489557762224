/**
 * Add an event handler to the document.
 * @param {string} eventType
 * @param {Function} callback - The callback function to be called when the event is triggered. Needs to be a function that accepts an event object as its only argument.
 */
export function on(eventType, callback) {
  if (typeof callback !== 'function')
    throw new Error('callback must be a function');

  if (typeof eventType !== 'string' || !eventType)
    throw new Error('eventType must be a string');

  document.addEventListener(eventType, callback);
}
/**
 * Remove an event handler from the document.
 * @param {string} eventType
 * @param {Function} callback
 */
export function off(eventType, callback) {
  if (typeof callback !== 'function')
    throw new Error('callback must be a function');

  if (typeof eventType !== 'string' || !eventType)
    throw new Error('eventType must be a string');

  document.removeEventListener(eventType, callback);
}

/**
 * Trigger an event on the document.
 * @param {string} eventType
 * @param {*} data
 */
export function trigger(eventType, data) {
  if (typeof eventType !== 'string' || !eventType)
    throw new Error('eventType must be a string');

  const event = new CustomEvent(eventType, { detail: data });
  document.dispatchEvent(event);
}

export const CUSTOM_EVENT_TYPES = {
  SESSION_EXPIRED: 'SESSION_EXPIRED',
};
