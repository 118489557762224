// FileInput.tsx
import React, { useState, useCallback, ReactNode } from 'react';
import {
  Button,
  IconButton,
  FormControl,
  FormHelperText,
  InputAdornment,
  FormLabel,
  Box,
} from '@mui/material';
import { Accept, useDropzone, FileRejection } from 'react-dropzone';
import DeleteIcon from '@mui/icons-material/Delete';
import useId from '@mui/material/utils/useId';
import StyledInput from '../../../inputs/StyledInput';
import { UploadIcon } from '../../../loan_application/icons';
import bytesToSize from '../../../../utils/bytesToSize';

interface FileInputProps {
  label: ReactNode;
  'data-testid'?: string;
  buttonContent?: ReactNode;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  error?: boolean;
  required?: boolean;
  errorMessage?: ReactNode;
  multiple?: boolean;
  accept?: Accept;
  maxSize?: number;
  onFileChange?: (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[]
  ) => void;
  onFileRemove?: (file: File) => void;
  additionalInfo?: string;
}

const FileInput: React.FC<FileInputProps> = ({
  label,
  buttonContent,
  startAdornment,
  endAdornment,
  required,
  error = false,
  errorMessage = '',
  multiple = false,
  accept,
  maxSize,
  onFileChange,
  onFileRemove,
  'data-testid': dataTestId,
  additionalInfo,
}) => {
  const id = useId();
  const [files, setFiles] = useState<File[]>([]);

  const handleFileChange = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      setFiles((prevFiles) =>
        multiple ? [...prevFiles, ...acceptedFiles] : acceptedFiles
      );
      if (onFileChange) onFileChange(acceptedFiles, rejectedFiles);
    },
    [multiple, onFileChange]
  );

  const handleFileRemove = useCallback(
    (file: File) => {
      setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
      if (onFileRemove) onFileRemove(file);
    },
    [onFileRemove]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: accept,
    maxSize,
    multiple,
    noClick: true,
    noKeyboard: true,
    onDrop: handleFileChange,
  });

  const hasFiles = files.length > 0;
  const displayError = error && errorMessage;

  return (
    <FormControl fullWidth error={error} variant="outlined">
      <FormLabel htmlFor={id} sx={{ mb: label ? 1.25 : 0 }} required={required}>
        {label}
      </FormLabel>
      {additionalInfo && !hasFiles && (
        <FormHelperText
          sx={{
            margin: 0,
          }}
        >
          <Box
            textAlign={'left'}
            component={'span'}
            display={'block'}
            color={'black'}
            padding={0.5}
            bgcolor={'#F4F4F4'}
            borderRadius={'5px'}
            mb={2}
          >
            {additionalInfo}
          </Box>
        </FormHelperText>
      )}
      <div {...getRootProps()}>
        <input {...getInputProps()} id={id} data-testid={dataTestId} />
        {hasFiles ? (
          <StyledInput
            value={files.map((file) => file.name).join(', ')}
            fullWidth
            InputProps={{
              startAdornment: startAdornment && (
                <InputAdornment position="start">
                  {startAdornment}
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {endAdornment && (
                    <InputAdornment position="end">
                      {endAdornment}
                    </InputAdornment>
                  )}
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={open}>
                      Replace
                    </IconButton>
                    <IconButton
                      edge="end"
                      onClick={() => handleFileRemove(files[files.length - 1])}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
            readOnly
            disabled
          />
        ) : (
          <Button
            variant="outlined"
            onClick={open}
            sx={{
              width: '100%',
              px: 2,
              py: 1.375,
              borderRadius: '.375rem',
            }}
          >
            {buttonContent ?? (
              <>
                <UploadIcon sx={{ fontSize: 20, mr: 1 }} />
                Upload File
              </>
            )}
          </Button>
        )}
      </div>
      {displayError && <FormHelperText>{errorMessage}</FormHelperText>}
      {maxSize && (
        <FormHelperText>
          {maxSize && (
            <Box textAlign={'right'} component={'span'} display={'block'}>
              Max upload file size: {bytesToSize(maxSize)}
            </Box>
          )}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FileInput;
