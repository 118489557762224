/* eslint-disable indent */
import {
  REFRESH_TOKEN_URL,
  SIGNIN_URL,
  SIGNUP_URL,
  VERIFY_URL,
  RESET_PASSWORD_URL,
  CHECK_PASSWORD_RESET_URL,
  LOGOUT_URL,
  UPDATE_PASSWORD_URL,
} from '../../constants';
import {
  BadRequest,
  GeneralError,
  ServerError,
  UserPasswordIncorrect,
  UserNotInvited,
  UserVerificationCodeInvalid,
  TimeoutError,
} from '../../utils/errors';
import { getToken } from '../storages/Auth';
import { api } from './API';

class AuthApi {
  async login(requestData) {
    let response = undefined;
    try {
      response = await api().post(SIGNIN_URL, requestData);
    } catch (err) {
      console.error('[Auth Api]: ', err);
      return this.handleErrorResponse(err.response);
    }
    // Handle Custom error messages + status codes.
    if (response.data?.errorMessage !== undefined) {
      return this.handleErrorResponse(response.data);
    }
    this.handleErrorResponse(response);
    return response.data;
  }

  async logout() {
    const tokens = getToken();
    try {
      const response = await api().post(LOGOUT_URL, tokens);
      // Handle Custom error messages + status codes.
      if (response.data?.errorMessage !== undefined)
        return this.handleErrorResponse(response.data);
      return response.data;
    } catch (err) {
      console.error('[Auth Api]: ', err);
      return this.handleErrorResponse(err.response);
    }
  }

  async signup(requestData) {
    try {
      const response = await api().put(SIGNUP_URL, requestData);
      this.handleErrorResponse(response);
      return response.data;
    } catch (err) {
      return this.handleErrorResponse(err.response);
    }
  }

  async verify(requestData) {
    try {
      const response = await api().put(VERIFY_URL, requestData);
      this.handleErrorResponse(response);
      return response.data;
    } catch (err) {
      return this.handleErrorResponse(err.response);
    }
  }

  async resetPassword(requestData) {
    try {
      const response = await api().post(RESET_PASSWORD_URL, requestData);
      this.handleErrorResponse(response);
      return response.data;
    } catch (err) {
      return this.handleErrorResponse(err.response);
    }
  }

  async checkPasswordResetCode(requestData) {
    try {
      const response = await api().post(CHECK_PASSWORD_RESET_URL, requestData);
      this.handleErrorResponse(response);
      return response.data;
    } catch (err) {
      return this.handleErrorResponse(err.response);
    }
  }

  async resetPasswordCode(requestData) {
    let response = undefined;
    try {
      response = await api().put(RESET_PASSWORD_URL, requestData);
    } catch (err) {
      return this.handleErrorResponse(err.response);
    }
    // Handle Custom error messages + status codes.
    if (response.data?.errorMessage !== undefined) {
      return this.handleErrorResponse(response.data);
    }
    this.handleErrorResponse(response);
    return response.data;
  }

  async resendVerificationEmail() {
    try {
      const response = await api().post(VERIFY_URL);
      this.handleErrorResponse(response);
      return response.data;
    } catch (err) {
      return this.handleErrorResponse(err.response);
    }
  }

  async refreshToken() {
    const { refresh } = getToken();
    const requestData = { refresh_token: refresh };
    try {
      const response = await api().post(REFRESH_TOKEN_URL, requestData);
      this.handleErrorResponse(response);
      return response.data;
    } catch (err) {
      return this.handleErrorResponse(err.response);
    }
  }

  async updatePassword({ current_password, new_password, confirm_password }) {
    const { refresh } = getToken();
    const requestData = {
      refresh_token: refresh,
      current_password,
      new_password,
      confirm_password,
    };
    try {
      const response = await api().put(UPDATE_PASSWORD_URL, requestData);
      return response.data;
    } catch (err) {
      return this.handleErrorResponse(err.response);
    }
  }

  handleErrorResponse(response) {
    const { status, data } = response;
    // Most of default django errors set 'detail' field in response
    const detail = data?.detail;
    // If detail field is set, put it's contents as the message for the error
    const errorData = detail ? { message: detail } : { data };
    switch (status) {
      case 200:
      case 201:
      case 204:
        break;

      case 400:
        throw new BadRequest(errorData);

      case 401:
        throw new UserPasswordIncorrect(errorData);

      case 402:
        throw new UserNotInvited(errorData);

      case 403:
        throw new UserVerificationCodeInvalid(errorData);

      case 408:
        throw new TimeoutError(errorData);

      case 503:
        throw new ServerError(errorData);

      default:
        throw new GeneralError(errorData);
    }
  }
}

export const authApi = new AuthApi();
