import SelectField from './SelectField';
import CheckboxField from './CheckboxField';
import { withInfoBox } from '../loan_application/components/withInfoBox';
import DatePickerField from './DatePickerField';
import FreeformField from './FreeformField';
import InputField from './InputField';
import NumberField from './NumberField';
import RadioGroupFields from './RadioGroupFields';
import FileInputField from './FileInputField';

export const CheckboxFieldWithInfoBox = withInfoBox(CheckboxField);
export const SelectFieldWithInfoBox = withInfoBox(SelectField);
export const DatePickerFieldWithInfoBox = withInfoBox(DatePickerField);
export const FreeformFieldWithInfoBox = withInfoBox(FreeformField);
export const InputFieldWithInfoBox = withInfoBox(InputField);
export const NumberFieldWithInfoBox = withInfoBox(NumberField);
export const RadioGroupFieldWithInfoBox = withInfoBox(RadioGroupFields);
export const FileInputFieldWithInfoBox = withInfoBox(FileInputField);
