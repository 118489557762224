import * as Yup from 'yup';
import { MissingStatusFieldsError } from './errors/AppErrors';

const schema = Yup.object().shape({
  internal_status: Yup.string().required('Required'),
  external_status_action: Yup.string().required('Required'),
  external_status_borrower_description: Yup.string().required('Required'),
  external_status_installer_description: Yup.string().required('Required'),
  external_status_stage: Yup.string().required('Required'),
});

export const checkApplicationStatusFields = (application) => {
  try {
    schema.validateSync(application, { abortEarly: false });
    return true;
  } catch (error) {
    throw new MissingStatusFieldsError(error.message);
  }
};
