import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getMeters } from '../../services/apis/UtilityBillAPI';
import { Box } from '@mui/material';
import LoadingBox from '../LoadingBox';

export const Referral: FC = () => {
  // Get referral code from URL
  const [params] = useSearchParams();
  const referral = params.get('referral');
  const applicationId = params.get('state');

  useEffect(() => {
    if (!referral || !applicationId) return;
    async function submitReferral() {
      if (!referral || !applicationId) return;

      getMeters(referral, applicationId).finally(() => {
        window.close();
      });
    }
    submitReferral();
  }, [applicationId, referral]);

  return (
    <Box>
      <LoadingBox />
    </Box>
  );
};

export default Referral;
