import {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  ReactNode,
} from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { authApi } from '../services/apis/AuthAPIs';
import type { Contact, Account } from '../../../../types/api';
import {
  getUserAPI,
  updateUserAPI,
  updateAccountAPI,
} from '../services/apis/UserAPIs';
import { getToken, deleteToken } from '../services/storages/Auth';
import { CUSTOM_EVENT_TYPES, off, on } from '../events';
import noticeError from '../utils/errors/noticeError';
import { AccountType } from '../../../../types/api';

type AuthContextState = {
  isAuthenticated: boolean;
  isVerified: boolean;
  isInitialized: boolean;
  user: Contact | null;
  account: Account | null;
  showWelcomeMessage: boolean;
  showSessionExpiredModal: boolean;
};

const initialState: AuthContextState = {
  isAuthenticated: false,
  isVerified: false,
  isInitialized: false,
  user: null,
  account: null,
  showWelcomeMessage: false,
  showSessionExpiredModal: false,
};

const HANDLER_TYPES = {
  INITIALIZE: 'INITIALIZE',
  GET_USER_DATA: 'GET_USER_DATA',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  CLOSE_SESSION_EXPIRED_MODAL: 'CLOSE_SESSION_EXPIRED_MODAL',
  REGISTER: 'REGISTER',
  CLOSE_WELCOME_MESSAGE: 'CLOSE_WELCOME_MESSAGE',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
} as const;

const createContactWithType = (
  user: Contact,
  account: Account | null
): Contact => ({
  ...user,
  is_installer: account?.type === AccountType.INSTALLER,
  is_consultant: account?.type === AccountType.CONSULTANT,
  is_borrower: account?.type === AccountType.BORROWER || account?.type === null,
});

const handlers = {
  [HANDLER_TYPES.INITIALIZE]: (state: AuthContextState, action) => {
    const { isAuthenticated, user, account } = action.payload;

    const contactWithType: Contact = createContactWithType(user, account);

    return {
      ...state,
      isAuthenticated,
      isVerified: user?.status == 'Active',
      isInitialized: true,
      user: contactWithType,
      account,
    };
  },
  [HANDLER_TYPES.GET_USER_DATA]: (state, action) => {
    const { user, account } = action.payload;

    const contactWithType: Contact = createContactWithType(user, account);

    return {
      ...state,
      isVerified: user?.status == 'Active',
      user: contactWithType,
      account,
    };
  },
  [HANDLER_TYPES.LOGIN]: (state, action) => {
    const { user, account, first_login } = action.payload;

    const contactWithType: Contact = {
      ...user,
      is_installer: account.type === AccountType.INSTALLER,
      is_consultant: account.type === AccountType.CONSULTANT,
      is_borrower:
        account.type === AccountType.BORROWER || account.type === null,
    };

    return {
      ...state,
      isAuthenticated: true,
      isVerified: user?.status == 'Active',
      user: contactWithType,
      account,
      showWelcomeMessage: first_login,
    };
  },
  [HANDLER_TYPES.LOGOUT]: () => ({
    ...initialState,
    isInitialized: true,
  }),
  [HANDLER_TYPES.SESSION_EXPIRED]: () => ({
    ...initialState,
    isInitialized: true,
    showSessionExpiredModal: true,
  }),
  [HANDLER_TYPES.CLOSE_SESSION_EXPIRED_MODAL]: (state) => ({
    ...state,
    showSessionExpiredModal: false,
  }),
  [HANDLER_TYPES.REGISTER]: (state) => {
    return {
      ...state,
      isAuthenticated: false,
    };
  },
  [HANDLER_TYPES.CLOSE_WELCOME_MESSAGE]: (state) => {
    return { ...state, showWelcomeMessage: false };
  },
  [HANDLER_TYPES.UPDATE_USER]: (state, { payload: { user } }) => {
    return { ...state, user };
  },
  [HANDLER_TYPES.UPDATE_ACCOUNT]: (state, { payload: { account } }) => {
    return { ...state, account };
  },
};

const reducer = (state: AuthContextState, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: (email: string, password: string) => Promise.resolve(),
  getUserData: () => Promise.resolve(),
  closeWelcomeMessage: () => {},
  closeSessionExpiredModal: () => {},
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient();
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = getToken();

        if (accessToken) {
          const { user, account } = await getUserAPI();

          const contactWithType: Contact = createContactWithType(user, account);

          dispatch({
            type: HANDLER_TYPES.INITIALIZE,
            payload: {
              isAuthenticated: true,
              user: contactWithType,
              account,
            },
          });
        } else {
          dispatch({
            type: HANDLER_TYPES.INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        deleteToken();
        dispatch({
          type: HANDLER_TYPES.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  // This funcion needs to maintain the same object reference so that we are able to clear the event listener, thus the useCallback use.
  const finishExpiredSession = useCallback(() => {
    dispatch({
      type: HANDLER_TYPES.SESSION_EXPIRED,
    });
  }, []);

  // Set up listener for session expired event
  // The API uses custom event to pass the information about session expiring without having to depend on react
  useEffect(() => {
    on(CUSTOM_EVENT_TYPES.SESSION_EXPIRED, finishExpiredSession);
    return () => off(CUSTOM_EVENT_TYPES.SESSION_EXPIRED, finishExpiredSession);
  }, [finishExpiredSession]);

  const getUserData = useCallback(async () => {
    const { user, account } = await getUserAPI();

    const contactWithType: Contact = createContactWithType(user, account);

    dispatch({
      type: HANDLER_TYPES.GET_USER_DATA,
      payload: {
        user: contactWithType,
        account,
      },
    });
  }, []);

  const login = useCallback(async (email, password) => {
    const { user, account, access, refresh, first_login } = await authApi.login(
      {
        email,
        password,
      }
    );
    const token = { access: access, refresh: refresh };
    localStorage.setItem('TOKEN_KEY', JSON.stringify(token));

    const contactWithType: Contact = createContactWithType(user, account);

    dispatch({
      type: HANDLER_TYPES.LOGIN,
      payload: {
        user: contactWithType,
        account,
        first_login,
      },
    });
  }, []);

  const closeSessionExpiredModal = useCallback(() => {
    dispatch({
      type: HANDLER_TYPES.CLOSE_SESSION_EXPIRED_MODAL,
    });
  }, []);

  const logout = useCallback(async () => {
    try {
      await authApi.logout();
      dispatch({ type: HANDLER_TYPES.LOGOUT });
      navigate('/authentication/login');
      localStorage.removeItem('TOKEN_KEY');
      queryClient.clear();
    } catch (error) {
      noticeError(error);
    }
  }, [queryClient, navigate]);

  const register = useCallback(async (email, password) => {
    await authApi.signup({ email, password });

    dispatch({
      type: HANDLER_TYPES.REGISTER,
      payload: {},
    });
  }, []);

  const closeWelcomeMessage = useCallback(
    () => dispatch({ type: HANDLER_TYPES.CLOSE_WELCOME_MESSAGE }),
    []
  );

  const updateUserData = useCallback(async (values) => {
    const user = await updateUserAPI(values);

    dispatch({
      type: HANDLER_TYPES.UPDATE_USER,
      payload: { user },
    });
  }, []);

  const updateAccountData = useCallback(async (values) => {
    const account = await updateAccountAPI(values);
    dispatch({
      type: HANDLER_TYPES.UPDATE_ACCOUNT,
      payload: { account },
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register,
        getUserData,
        closeWelcomeMessage,
        updateUserData,
        updateAccountData,
        closeSessionExpiredModal,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
