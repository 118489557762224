/**
 * @file AppErrors.js
 * @description Contains custom errors unrelated to network or authentication.
 */

export const APP_ERROR_CODES = {
  MISSING_STATUS_FIELDS: 'MISSING_STATUS_FIELDS',
};

export class MissingStatusFieldsError {
  constructor(message = 'Missing status fields') {
    this.message = message;
    this.code = APP_ERROR_CODES.MISSING_STATUS_FIELDS;
  }
}
