import { forwardRef } from 'react';
import { SvgIconProps, SvgIcon } from '@mui/material';
import Icon from './InfoIcon.svg';

export const InfoIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  function InfoIcon(props, ref) {
    return (
      <SvgIcon ref={ref} {...props}>
        <Icon />
      </SvgIcon>
    );
  }
);
