import type { FC } from 'react';
import {
  Box,
  CircularProgress,
  BoxProps,
  CircularProgressProps,
} from '@mui/material';

interface Props extends BoxProps {
  progressProps?: CircularProgressProps;
}
const LoadingBox: FC<Props> = ({ progressProps = {}, ...rest }) => {
  return (
    <Box
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
      }}
      data-testid="loading"
      {...rest}
    >
      <CircularProgress {...progressProps} />
    </Box>
  );
};

export default LoadingBox;
