import { BoxProps, GridProps } from '@mui/material';
import React, {
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { APP_INTERNAL_STATUSES } from '../../../constants';
import { useLabelWithTooltip } from '../../../hooks';
import { useApplicationSteps } from '../../../pages/dashboard/loan_detail/ApplicationStepsContext';

import { ApplicationStepStatus } from '../types';

/**
 * HOC wrapping a given component in a container with InfoIcon aside
 * Intended for use with inputs in Application intake forms
 * @param {Function} Component
 * @returns
 * @example
 * import {InputField as InputFieldBase} from 'form_fields';
 * const InputField = withInfoBox(InputFieldBase);
 * //In your output...
 * <InputField
      name='name'
      placeholder="Enter name"
      readOnly={true}
      fullWidth
      infoText="Your name will be used as an identifier for our purposes"
    />
 *
 */

type WithInfoBoxProps = {
  infoText?: ReactNode;
  containerProps?: GridProps;
  boxProps?: BoxProps;
  label?: ReactNode;
};
// eslint-disable-next-line @typescript-eslint/ban-types
export function withInfoBox<P extends PropsWithChildren<{}>>(
  Component: React.ComponentType<P>
) {
  const ComponentWithInfoBox = forwardRef<any, P & WithInfoBoxProps>(
    ({ infoText, containerProps, boxProps, label, ...rest }, ref) => {
      const { data, currentStep, steps } = useApplicationSteps();
      const labelWithTooltip = useLabelWithTooltip(label, infoText);

      // If current application status is "Application Created" - that means that the user didn't interact with the application yet.
      // In this case, we don't want to show any error state for the inputs.
      const applicationTouched =
        data?.application.internal_status !== APP_INTERNAL_STATUSES.APP_CREATED;
      // If the current step is at least partially filled - that means that the user interacted with the step.
      // And we can show the error states for the inputs.
      const currentStepIsTouched =
        steps?.[currentStep]?.status === ApplicationStepStatus.ACTIVE;
      const TypecastComponent = Component as React.ComponentType<
        Omit<
          PropsWithChildren<P & WithInfoBoxProps>,
          'infoText' | 'containerProps' | 'boxProps' | 'label'
        > & { touched: boolean; ref: ForwardedRef<any> }
      >;

      return (
        <TypecastComponent
          {...rest}
          touched={Boolean(applicationTouched && currentStepIsTouched)}
          label={labelWithTooltip}
          ref={ref}
        />
      );
    }
  );
  ComponentWithInfoBox.displayName = `${Component.displayName}WithInfoBox`;
  return ComponentWithInfoBox;
}
