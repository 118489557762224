import { Paper, PaperProps, Typography, TypographyProps } from '@mui/material';
import { Box } from '@mui/system';
import { forwardRef, ReactNode } from 'react';

export type FormFieldsetProps = {
  title?: ReactNode;
  info?: ReactNode;
  titleProps?: TypographyProps;
} & Omit<PaperProps<'fieldset'>, 'component' | 'variant' | 'title'>;

export default forwardRef<HTMLFieldSetElement, FormFieldsetProps>(
  function FormFieldset(
    { children, title, info, sx, titleProps, ...rest },
    ref
  ) {
    const id = rest.id || title?.toString().toLowerCase().replace(/\s/g, '-');
    const labelId = `${id}-label`;
    return (
      <Paper
        elevation={0}
        component="fieldset"
        sx={{ px: 4, py: 5, border: 'none', ...sx }}
        {...rest}
        ref={ref}
        id={id}
        aria-labelledby={labelId}
      >
        {(title || info) && (
          <Box sx={{ mb: 2.5 }}>
            {title && (
              <Typography variant="h2" id={labelId} {...titleProps}>
                {title}
              </Typography>
            )}
            {info && (
              <Typography variant="body1" color="GrayText" mt={1}>
                {info}
              </Typography>
            )}
          </Box>
        )}
        {children}
      </Paper>
    );
  }
);
