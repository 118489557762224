import { Box, Typography, Link } from '@mui/material';
import { Fragment } from 'react';
import { footerHeight } from '../../config';
import {
  CONTACT_SLUG,
  PRIVACY_POLICY_SLUG,
  TERMS_OF_USE_SLUG,
  ACCESSIBILITY_SLUG,
} from '../../constants';

const links = [
  {
    title: 'Privacy Policy',
    href: `${__landingUrl__}/${PRIVACY_POLICY_SLUG}`,
  },
  {
    title: 'Terms of Use',
    href: `${__landingUrl__}/${TERMS_OF_USE_SLUG}`,
  },
  {
    title: 'Accessibility Statement',
    href: `${__landingUrl__}/${ACCESSIBILITY_SLUG}`,
  },
  {
    title: 'Contact',
    href: `${__landingUrl__}/${CONTACT_SLUG}`,
  },
];

const DashboardFooter = (props) => {
  return (
    <Box
      display={'flex'}
      alignItems="center"
      justifyContent={'center'}
      py={4.25}
      height={footerHeight}
      component="footer"
      {...props}
    >
      <Typography variant="body1" color="text.primary">
        {links.map((link, i) => (
          <Fragment key={i}>
            <Link
              href={link.href}
              color="inherit"
              variant="body1"
              underline="none"
              target="_blank"
            >
              {link.title}
            </Link>
            <Typography component="span" px={2}>
              •
            </Typography>
          </Fragment>
        ))}
        ⓒ Sunstone Credit, Inc.
      </Typography>
    </Box>
  );
};

export default DashboardFooter;
