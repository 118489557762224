/**
 * Report an error to New Relic if it is available and log it to console using console.error
 * @param error Error to report
 * @param additionalInfo  Additional information to report, this will be displayed in New Relic as custom attributes
 */
const noticeError = (
  error: string | Error,
  additionalInfo: Record<string, any> = {}
) => {
  if (window.newrelic)
    newrelic.noticeError(error, {
      ...additionalInfo,
      version: __versionString__,
    });
  console.error(error, additionalInfo);
};

export default noticeError;
