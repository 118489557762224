import { NumberFormatValues } from 'react-number-format';
import {
  LIMITS,
  PG_COMPANY_TYPES,
  COMPANY_TYPES,
  SYSTEM_POWER_USAGE_VALUES,
} from '../../../constants';
import {
  Application,
  LoanCategory,
  ProductFamily,
} from '../../../../../../types/api';
import { zonedTimeToUtc } from 'date-fns-tz';
import { api } from '../../../services/apis';

// this determines if a loan requires a personal guarantor
export const updateIsPersonalGuarantor = (control) => {
  const fv = control._formValues;
  const loanSizeCriteria =
    fv.loan_amount && fv.loan_amount <= LIMITS.PG_LOAN_AMOUNT_CEILING;
  const power_company_type = fv.solar_system_power_company_type ?? '';

  // Operating Company, Property Company or Agriculture
  if (loanSizeCriteria && PG_COMPANY_TYPES.includes(power_company_type)) {
    fv.is_personal_guarantor = 'Yes';
  } else {
    fv.is_personal_guarantor = 'No';
  }

  return fv.is_personal_guarantor;
};

export const updateLoanCategory = (control) => {
  const fv = control._formValues;

  const isExpressLoanAmount =
    fv.loan_amount && fv.loan_amount <= LIMITS.PG_LOAN_AMOUNT_CEILING;

  const isExpressPlusLoanAmount =
    fv.loan_amount &&
    fv.loan_amount > LIMITS.EXPRESS_PLUS_LOAN_AMOUNT_FLOOR &&
    fv.loan_amount <= LIMITS.EXPRESS_PLUS_LOAN_AMOUNT_CEILING;

  const power_company_type = fv.solar_system_power_company_type ?? '';

  // Operating Company, Property Company or Agriculture
  if (PG_COMPANY_TYPES.includes(power_company_type)) {
    if (isExpressLoanAmount) {
      fv.loan_category = LoanCategory.EXPRESS;
    } else if (isExpressPlusLoanAmount) {
      fv.loan_category = LoanCategory.EXPRESS_PLUS;
    } else {
      fv.loan_category = LoanCategory.CORE;
    }
  } else {
    fv.loan_category = LoanCategory.CORE;
  }
};

const isPGRequired = (
  loanCategory: LoanCategory,
  companyType: COMPANY_TYPES,
  annualGrossIncome: number,
  yearsAgoEstablished: number
) => {
  /**
   * This function aims to decouple the logic for when a Loan App requires a PG ('Is [PRINCIPAL] willing to be a Personal Guarantor?'),
   * from the 'requiresPGPercentOwnership()' function.
   * allowing it to scale easier and keep adding rules as the business logic changes.
   * This is not related to the principalIsPG field validation, but to the section completion,
   * determining when it can be completed without providing a PG
   */
  const EXPRESS_COMPANIES = [
    COMPANY_TYPES.OPERATING_COMPANY,
    COMPANY_TYPES.AGRICULTURE,
  ];
  switch (loanCategory) {
    case LoanCategory.EXPRESS:
      return !(
        EXPRESS_COMPANIES.includes(companyType) &&
        annualGrossIncome > LIMITS.EXPRESS_REVENUE_CEILING &&
        yearsAgoEstablished > LIMITS.YEARS_AGO_ESTABLISHED_FLOOR
      );

    case LoanCategory.EXPRESS_PLUS:
      if (
        EXPRESS_COMPANIES.includes(companyType) &&
        annualGrossIncome > LIMITS.EXPRESS_PLUS_REVENUE_CEILING &&
        yearsAgoEstablished > LIMITS.YEARS_AGO_ESTABLISHED_FLOOR
      ) {
        return false;
      }
      return true;

    case LoanCategory.CORE:
      if (companyType === COMPANY_TYPES.AGRICULTURE) {
        return true;
      }
      return false;

    default:
      return false;
  }
};

export const calculatePGPercentOwnership = (principals): number => {
  // only relevant for PG loans
  let pgPercentOwnership = 0;
  for (const principal of principals) {
    // only relevant if principal is a PG
    if (
      principal.principal_is_personal_guarantor === 'Yes' &&
      principal.percent_ownership
    ) {
      pgPercentOwnership += parseFloat(principal.percent_ownership);
    }
  }
  return parseFloat(pgPercentOwnership.toFixed(2));
};

export const countPGs = (principals): number => {
  return principals.filter(
    (principal) => principal.principal_is_personal_guarantor === 'Yes'
  ).length;
};

export const isValidSFCurrency = (values: NumberFormatValues): boolean => {
  const { floatValue } = values;
  if (floatValue) return floatValue >= 0 && floatValue <= 10000000000000000;
  return true;
};

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
};

export const shortenFilename = (name: string, maxLength: number) => {
  if (name.length <= maxLength) {
    return name;
  }
  return name.substring(0, maxLength - 3) + '...';
};

export const requiresPGPercentOwnership = (application: Application) => {
  const isTenant =
    application.solar_system_power_usage ===
    SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY;
  if (isTenant) return false;
  const borrowerGrossRevenueLatest = application.borrower_gross_revenue_latest;
  const borrowerBusinessEstablishedYear = application.business_established_year;
  const currentYear = new Date().getFullYear();

  const yearsAgoEstablished =
    borrowerBusinessEstablishedYear &&
    currentYear - borrowerBusinessEstablishedYear;

  const loanCategory = application.loan_category;
  const companyType = application.solar_system_power_company_type;

  if (
    !loanCategory ||
    !companyType ||
    !borrowerGrossRevenueLatest ||
    !yearsAgoEstablished
  ) {
    return true;
  }

  return isPGRequired(
    loanCategory,
    companyType as COMPANY_TYPES,
    borrowerGrossRevenueLatest,
    yearsAgoEstablished
  );
};

export const missingApplicantSameAsPrincipal = (application: Application) => {
  /* This utility function is to be used in ApplicationStepsContext and ApplicationPage, since it overwrites the section
  validation, to make the section incomplete if missing the field Applicant same as Principal 
  -which is already required in Yup validation- */
  if (!application.principals || application.principals.length === 0) {
    return false;
  }

  const hasApplicantSameAsPrincipal = application.principals.some(
    (principal) => principal.applicant_same_as_principal === 'Yes'
  );

  // We need to check:
  /**
   - Pricipal selected Yes to is PG
   - Didn't select any field for ASAP
   - AND there's no other Principal that already selected 'Yes' to ASAP (if so, that question will never show and will always be null)
   */
  const missingAsap = application.principals.some(
    (principal) =>
      principal.principal_is_personal_guarantor === 'Yes' &&
      principal.applicant_same_as_principal === null &&
      !hasApplicantSameAsPrincipal
  );

  return missingAsap;
};

export const isYes = (value: 'Yes' | 'No' | null): boolean => value === 'Yes';
export const isNo = (value: 'Yes' | 'No' | null): boolean => value === 'No';
export const isCurrentDateBetween = (startDate, endDate) => {
  const currentTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentDateUTC = zonedTimeToUtc(new Date(), currentTz);

  const referenceTz = 'America/New_York';
  const startDateUTC = zonedTimeToUtc(startDate, referenceTz);
  const endDateUTC = zonedTimeToUtc(endDate, referenceTz);
  return currentDateUTC >= startDateUTC && currentDateUTC <= endDateUTC;
};

interface SelectedProduct {
  rate: number;
  amortizationYears: number;
  loanTermYears: number;
  productFamily: ProductFamily;
}

interface SendMonthlyPaymentRequestParams {
  selectedProduct: SelectedProduct;
  loanAmount: number;
  specialPaymentPercentage?: number;
}

export const sendMonthlyPaymentRequest = async ({
  selectedProduct,
  loanAmount,
  specialPaymentPercentage,
}: SendMonthlyPaymentRequestParams) => {
  // Send a request to Railway endpoint to calculate the monthly payment

  const { rate, amortizationYears, loanTermYears, productFamily } =
    selectedProduct;

  let prodFamilyShort: string;

  switch (productFamily) {
    case ProductFamily.STANDARD:
      prodFamilyShort = 'standard';
      break;
    case ProductFamily.SPECIAL_PAYMENT:
      prodFamilyShort = 'special';
      break;
    default:
      prodFamilyShort = '';
  }

  const params = {
    loanAmount: loanAmount.toString(),
    annualRate: rate.toString(),
    numberOfYears: loanTermYears.toString(),
    type: prodFamilyShort,
    amortizationYears: amortizationYears.toString(),
    ...(specialPaymentPercentage
      ? { specialPaymentPercentage: specialPaymentPercentage.toString() }
      : {}),
  };

  const queryString = new URLSearchParams(params).toString();
  const response = await api().get(`calculate-monthly-payment?${queryString}`);

  const data = JSON.parse(response.data.join(''));

  return data.monthlyPayment;
};
