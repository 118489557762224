import Icon from './DocumentVerification.svg';
import { SvgIcon } from '@mui/material';
import { forwardRef } from 'react';

export const DocumentVerification = forwardRef(function DocumentVerification(
  props,
  ref
) {
  return <SvgIcon ref={ref} {...props} component={Icon} viewBox="0 0 40 41" />;
});
