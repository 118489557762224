import { FormHelperText, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { InputField } from '../../form_fields';
import * as Yup from 'yup';
import { authApi } from '../../../services/apis/AuthAPIs';
import noticeError from '../../../utils/errors/noticeError';

const ChangePasswordValidationSchema = Yup.object().shape({
  current_password: Yup.string()
    .label('Current Password')
    .required()
    .min(8, 'Must be atleast 8 characters')
    .max(255, 'Must be at most 255 characters'),
  new_password: Yup.string()
    .label('New Password')
    .required('Password is required')
    .min(8, 'Must be atleast 8 characters')
    .max(255, 'Must be at most 255 characters')
    .matches(/.*[A-Z].*/, 'At least one upper case letter')
    .matches(/.*[a-z].*/, 'At least one lower case letter')
    .matches(/.*\d.*/, 'At least one digit')
    .test(
      'Should not contain whitespace characters',
      'Should not contain whitespace characters',
      (value) => !/.*\s.*/.test(value)
    ),
  confirm_password: Yup.string()
    .label('Confirm New Password')
    .required('Password is required')
    .oneOf(
      [Yup.ref('new_password'), null],
      'Your new passwords do not match. Please enter your password confirmation again.'
    ),
});

export const ChangePasswordForm = () => {
  async function _handleSubmit(values, formikBag) {
    try {
      await authApi.updatePassword(values);
      toast.success('Password Updated!');
      formikBag.resetForm();
    } catch (err) {
      noticeError(err);
      if (err.data) formikBag.setErrors(err.data);
      toast.error('Something went wrong!');
    }
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={ChangePasswordValidationSchema}
      initialValues={{
        current_password: '',
        new_password: '',
        confirm_password: '',
      }}
      onSubmit={_handleSubmit}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          <Grid container rowSpacing={2.25} columnSpacing={3}>
            <Grid item xs={12}>
              <InputField
                label="Current Password"
                fullWidth
                name={'current_password'}
                type="password"
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label="New Password"
                fullWidth
                name={'new_password'}
                type="password"
              />
              <FormHelperText></FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <InputField
                label="Confirm New Password"
                fullWidth
                name={'confirm_password'}
                type="password"
              />
            </Grid>

            <Grid item container xs={12}>
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={!dirty || !isValid}
                loading={isSubmitting}
                sx={{ ml: 'auto' }}
              >
                Save Changes
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
