import * as z from 'zod';
import { ProductFamily } from './api';

export const SFObjectSchema = z.object({
  id: z.string(),
  name: z.string(),
  is_deleted: z.boolean(),
  created_date: z.string(),
  last_modified_date: z.string(),
  last_activity_date: z.string().nullable(),
  last_viewed_date: z.string().nullable(),
  last_referenced_date: z.string().nullable(),
});

const FeeProductFamilyEnum = z.nativeEnum(ProductFamily);
const pricingGroupings = {
  1: '1' as const,
  2: '2' as const,
  3: '3' as const,
  4: '4' as const,
  5: '5' as const,
};
const PricingGroupingEnum = z.nativeEnum(pricingGroupings);

// Schema for `Fee` interface
export const FeeSchema = z
  .object({
    product: z.string().nullable(),
    start_date: z.string().nullable(),
    end_date: z.string().nullable(),
    dealer_fee: z.coerce.number().nullable(),
    deactivation_reason: z.string().nullable(),
    installer: z.string().nullable(),
    active: z.boolean(),
    profile: z.union([
      z.literal('Normal'),
      z.literal('CED'),
      z.literal('Custom'),
      z.literal('Legacy'),
      z.null(),
    ]),
    interest_rate: z.coerce.number().nullable(),
    loan_term_years: z.string().nullable(),
    product_family: FeeProductFamilyEnum.nullable(),
    pricing_grouping: PricingGroupingEnum,
  })
  .merge(SFObjectSchema)
  .omit({
    is_deleted: true,
    created_date: true,
    last_modified_date: true,
    last_activity_date: true,
    last_viewed_date: true,
    last_referenced_date: true,
  });

export const RateSheetFeeSchema = FeeSchema.merge(
  z.object({
    pricing_grouping: PricingGroupingEnum,
    loan_term_years: z.coerce.string(),
    dealer_fee: z.coerce.number(),
    product_family: FeeProductFamilyEnum,
    interest_rate: z.coerce.number(),
    amortization_years: z.coerce.string(),
  })
);

const RatesByProductFamily = z.object({
  [ProductFamily.SPECIAL_PAYMENT]: z.array(RateSheetFeeSchema).optional(),
  [ProductFamily.STANDARD]: z.array(RateSheetFeeSchema).optional(),
});

// Schema for `Rates` interface
export const RatesSchema = z.object({
  current: RatesByProductFamily,
  future: z.array(
    z.object({
      date: z.string(),
      rates: RatesByProductFamily,
    })
  ),
});
