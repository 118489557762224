import {
  Application,
  MaterialLoanObligation,
  RelatedParty,
} from '../../../../../../types/api';
import {
  UNITED_STATES,
  NUMBER_FORMATS,
  SF_MAX_CHAR_LIMITS,
  MAX_NUMBER_INPUT_VALUE,
  OCCUPATIONS,
  PLACEHOLDERS,
  RENT_OWN,
  SYSTEM_POWER_USAGE_VALUES,
  YES_NO,
  RELATED_PARTY_TYPES,
  COUNTRIES,
  LIMITS,
  COMPANY_TYPES_OBJECT_ARRAY,
  INTERVAL_DATES,
  OUTSTANDING_LOAN_TYPES,
} from '../../../constants';
import { isNo, isYes } from '../utils/helpers';

const required = true as const;
const currentYear = new Date().getFullYear();
const lastYear = currentYear - 1;
const yearAgo = currentYear - 2;
const twoYearsAgo = currentYear - 3;
// adjusting for tax season in april.  from Jan - April, this will be last year, after April will be the current year
const pg1Year = currentYear - (new Date().getMonth() + 1 < 5 ? 2 : 1);
const currentMonth = new Date().getMonth() + 1;

const loanFormModel = {
  formId: 'businessAndContact',
  formField: {
    // Borrower Contact Information
    primaryContactName: {
      name: 'primary_contact_name' as const,
      label: 'Primary Contact Name',
      required,
      getTooltip: (borrowerLegalName: string) =>
        `Please provide the name of the individual who we should contact if there are questions about this application.  This does not have to be the individual that is filling out this application nor the owner of ${borrowerLegalName}.`,
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    primaryContactPhoneNumber: {
      name: 'primary_contact_phone_number' as const,
      label: 'Primary Contact Phone Number',
      required,
      infoText:
        'Please provide the phone number of the individual who we should contact if there are questions about this application.',
      placeholder: PLACEHOLDERS.PHONE,
      format: NUMBER_FORMATS.PHONE,
    },
    primaryContactEmail: {
      name: 'primary_contact_email_address' as const,
      label: 'Primary Contact Email Address',
      required,
      maxLength: SF_MAX_CHAR_LIMITS.EMAIL,
      infoText:
        'Please provide the e-mail of the person we should contact if there are questions about this application.',
    },
    // Business address
    businessAddress: {
      name: 'business_address' as const,
      label: 'Street Address',
      required,
      placeholder: 'Enter street address',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    businessAddressLine2: {
      name: 'business_address_line_2' as const,
      label: 'Suite, Floor, etc.',
      placeholder: 'Enter Apt, suite, unit, building, floor, etc.',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    businessAddressCity: {
      name: 'business_address_city' as const,
      label: 'City',
      required,
      placeholder: 'Enter city',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    businessAddressState: {
      name: 'business_address_state' as const,
      label: 'State',
      placeholder: 'Choose state',
      choices: UNITED_STATES,
      required,
    },
    businessAddressZipCode: {
      name: 'business_address_zip_code' as const,
      label: 'Zip Code',
      placeholder: 'Enter 5 digit zip code',
      format: NUMBER_FORMATS.ZIP,
      required,
    },
    // Mailing address
    mailingAddressSameAsBusinessPicklist: {
      name: 'mailing_address_same_as_bussiness' as const,
      label: 'Mailing Address is the same as the Physical Business Address',
      choices: YES_NO,
      required,
    },
    mailingAddress: {
      name: 'mailing_address',
      label: 'Street Address',
      required,
      placeholder: 'Enter street address',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    mailingAddressLine2: {
      name: 'mailing_address_line_2',
      label: 'Suite, Floor, etc.',
      placeholder: 'Enter Apt, suite, unit, building, floor, etc.',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    mailingAddressCity: {
      name: 'mailing_address_city',
      label: 'City',
      required,
      placeholder: 'Enter city',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    mailingAddressState: {
      name: 'mailing_address_state',
      label: 'State',
      required,
      placeholder: 'Choose state',
      choices: UNITED_STATES,
    },
    mailingAddressZipCode: {
      name: 'mailing_address_zip_code',
      label: 'Zip Code',
      required,
      placeholder: 'Enter 5 digit zip code',
      format: NUMBER_FORMATS.ZIP,
    },
    // System Location. A13 - A17, A23 - A27
    systemPropertyAddressIsSameAsBusiness: {
      name: 'sys_prop_address_is_same_as_business' as const,
      getLabel: (borrowerLegalName: string) =>
        `Address of the property where the system will be installed is the same as ${borrowerLegalName}'s Physical Business Address`,
      choices: YES_NO,
      required,
    },
    systemPropertyAddress: {
      name: 'sys_prop_address',
      label: 'Address of the property where the system will be installed',
      placeholder: 'Enter street address',
      required,
      infoText:
        'Please enter the physical address of the property that the solar system will be installed on.',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    systemPropertyAddressCity: {
      name: 'sys_prop_address_city',
      label: 'City of the property where the system will be installed',
      placeholder: 'Enter city',
      required,
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    systemPropertyAddressState: {
      name: 'sys_prop_address_state',
      label: 'State of the property where the system will be installed',
      choices: UNITED_STATES,
      placeholder: 'Choose state',
      required,
    },
    systemPropertyAddressZipCode: {
      name: 'sys_prop_address_zip_code',
      label: 'Zip Code of the property where the system will be installed',
      placeholder: 'Enter 5 digit zip code',
      format: NUMBER_FORMATS.ZIP,
      required,
    },
    systemPropertyOwnershipStatus: {
      name: 'sys_prop_ownership_status_picklist' as const,
      getLabel: (borrowerLegalName: string, propertyAddress: string) =>
        `Does ${borrowerLegalName} own or lease the property where the system will be installed${
          propertyAddress ? `, which was listed at ${propertyAddress}?` : '?'
        }`,
      required,
      requiredErrorMsg: 'Ownership Status is required',
      choices: [
        {
          value: 'OWNED',
          label: 'Own',
        },
        {
          value: 'LEASED',
          label: 'Lease',
        },
      ],
    },
    systemPropertyLegalNameOfOwner: {
      name: 'system_property_legal_name_of_owner' as const,
      getLabel: (propertyAddress: string) =>
        `Who owns the property where the system will be installed${
          propertyAddress ? `, which was listed at ${propertyAddress}?` : '?'
        }`,
      tooltip:
        'Please enter the legal name of the entity that owns the property where the system will be installed. We will not reach out to them until we receive your consent.',
      required,
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    systemPropertyOwnerAffiliatedWithBorrower: {
      name: 'sys_prop_owner_affiliated_with_borrower',
      getLabel: (borrowerLegalName: string, propertyAddress: string) =>
        `Is the owner of the property where the system will be installed affiliated with ${borrowerLegalName}${
          propertyAddress ? `, which was listed at ${propertyAddress}?` : '?'
        }`,
      choices: YES_NO,
      getTooltip: (borrowerLegalName: string) =>
        `The owner of the property where the system will be installed is affiliated with ${borrowerLegalName} if ${borrowerLegalName} and the owner of the property where the system will be installed are owned or controlled by the same individuals or entities.`,
      required,
    },
    systemPropertyLeaseExpirationYear: {
      name: 'system_property_lease_expiration_year' as const,
      getLabel: (borrowerLegalName: string) =>
        `In which year does ${borrowerLegalName}'s current lease expire?`,
      format: NUMBER_FORMATS.YEAR,
      tooltip:
        'Lease term should be at least 80% of the loan term, with the exception of major property modifications.',
      lease80percentLoanTermWarning:
        'We ordinarily require the remaining lease length to be 80% of the loan term.  A member of our underwriting team will reach out to you upon submission regarding your particular situation.',
      required,
    },
    systemPropertyCurrentlyPayingMortgage: {
      name: 'borrower_is_currently_paying_mortgage' as const,
      getLabel: (propertyAddress: string) =>
        `Is there a mortgage outstanding on the property where the system will be installed${
          propertyAddress ? `, which was listed at ${propertyAddress}?` : '?'
        }`,
      required,
      choices: YES_NO,
    },
    systemPropertyAppraisedValue: {
      name: 'system_property_appraised_value' as const,
      getLabel: (propertyAddress: string) =>
        `What is the estimated market value of the property${
          propertyAddress ? ` listed at ${propertyAddress},` : ''
        } where the system will be installed?`,
      required,
    },
    systemPropretyMortgageOpenedYear: {
      name: 'system_property_mortgage_opened_year' as const,
      label: 'What year was the mortgage opened?',
      format: NUMBER_FORMATS.YEAR,
      required,
    },
    systemPropertyInitialMortgageAmount: {
      name: 'system_property_initial_mortgage_amount' as const,
      label: 'What was the initial mortgage amount?',
      required,
    },
    systemPropertyMortgageDueAmount: {
      name: 'system_property_mortgage_due_amount' as const,
      label: 'What is the current amount due?',
      required,
    },
    systemPropertyMortgageMaturityYear: {
      name: 'system_property_mortgage_maturity_year' as const,
      label: 'In what year is the maturity date of the mortgage?',
      format: NUMBER_FORMATS.YEAR,
      required,
    },
    systemPropertyMortgagePayment: {
      name: 'sys_prop_monthly_mortgage_payment',
      label: 'What is the monthly mortgage payment?',
      required,
      requiredErrorMsg: 'Mortgage Payment amount is required',
    },
    systemProperyMortgageLenderName: {
      name: 'sys_prop_mortgage_lender_name',
      label: 'What is the name of the lender for your mortgage?',
      required,
      requiredErrorMsg: 'Mortgage lender name is required',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    systemPropertyLandlordConsent: {
      name: 'sys_prop_landlord_consent_confirmation',
      getLabel: (borrowerLegalName: string) =>
        `Has ${borrowerLegalName}'s landlord agreed to the installation of the solar system on the property?`,
      infoText:
        'Once approved for the loan, we will require your landlord to provide their consent in writing as a condition of loan funding.',
      getTooltip: (borrowerLegalName: string) =>
        `If ${borrowerLegalName} is approved for the loan, we will require ${borrowerLegalName}'s landlord to provide their consent in writing as a condition of loan funding.`,
      choices: YES_NO,
      required,
      requiredErrorMsg: 'Landlord consent information is required',
    },
    // Solar System Power
    systemPowerUsage: {
      name: 'solar_system_power_usage' as const,
      label: 'Who pays the utility bill?',
      getChoices: (borrowerLegalName: string) => [
        {
          label: `${borrowerLegalName}`,
          value: SYSTEM_POWER_USAGE_VALUES.OFFSET_BORROWERS_UTILITY_BILL,
        },
        {
          label: `Tenant`,
          value: SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY,
        },
      ],
      getTooltip: (borrowerLegalName: string) =>
        `Select “${borrowerLegalName}” if ${borrowerLegalName} will directly consume the power generated by the solar system or if ${borrowerLegalName} is a property company and an affiliated operating company will consume the power generated by the solar system.`,
      required,
    },
    systemPowerCompanyType: {
      name: 'solar_system_power_company_type' as const,
      getLabel: (borrowerLegalName: string) =>
        `What type of company is ${borrowerLegalName}?`,
      infoText:
        'An operating company sells products or services. A property company owns real estate, typically collects rental income, and typically does not sell products or services. A non-profit is organized for purposes other than generating profit. An HOA is a community of individuals that own real estate in the same building or area.',
      required,
      choices: COMPANY_TYPES_OBJECT_ARRAY,
    },

    loanCategory: {
      name: 'loan_category' as const,
    },
    // Solar System Power - Property Company
    propertyCompanyUploadRentRollOrProvideTenantInfo: {
      name: 'system_power_rent_roll_or_tenant_info' as const,
      getLabel: (borrowerLegalName: string) =>
        `Since ${borrowerLegalName} is a property company, would you like to upload a rent roll for each of ${borrowerLegalName}'s tenants or enter information for a single tenant?`,
      choices: [
        {
          label: 'Upload rent roll',
          value: 'Upload rent roll',
        },
        {
          label: 'Enter information for a single tenant',
          value: 'Enter information for a single tenant',
        },
      ],
      required,
    },
    propertyCompanyTenantName: {
      name: 'sys_power_property_company_tenant_name',
      label: "Tenant's Name",
      required,
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    propertyCompanyTenantMoveInYear: {
      name: 'property_company_tenant_move_in_year',
      label: "Tenant's Move-In Year",
      required,
      format: NUMBER_FORMATS.YEAR,
    },
    propertyCompanyTenantLeaseTerm: {
      name: 'property_company_tenant_lease_term',
      label: "Tenant's Current Lease Term (in months)",
      tooltip:
        "Please provide the number of months of the tenant's current lease.",
      required,
    },
    propertyCompanyTenantRenewalYear: {
      name: 'property_company_tenant_renewal_year',
      label: "Tenant's Renewal Year",
      tooltip:
        "Please provide the year that the tenant's lease will be renewed.",
      required,
      format: NUMBER_FORMATS.YEAR,
    },
    propertyCompanyTenantRenewalLength: {
      name: 'property_company_tenant_renewal_length',
      label: 'Length of Renewal (in months)',
      tooltip:
        "Please provide the number of months that the tenant's lease will renew for.",
      required,
    },
    propertyCompanyTenantMonthlyRentPayment: {
      name: 'prop_com_tenant_monthly_rent_payment',
      label: "Tenant's Monthly Rent Payment",
      required,
    },
    propertyCompanyTenantPaymentsSatisfactory: {
      name: 'prop_com_tenant_payments_satisfactory',
      label: 'Has the tenant made their payments in a satisfactory manner?',
      choices: YES_NO,
      required,
    },
    // Solar System Power - Non-Profit
    nonProfitRecievedBoardApproval: {
      name: 'non_profit_received_board_approval' as const,
      getLabel: (borrowerLegalName: string) =>
        `Has ${borrowerLegalName} received approval from its Board of Directors to proceed with the loan?`,
      choices: YES_NO,
      required,
      getTooltip: (borrowerLegalName: string) =>
        `If ${borrowerLegalName} is approved for the loan, we will require its Board of Directors to provide their approval in writing as a condition of loan funding.`,
    },

    systemPowerDescribeBorrower: {
      name: 'solar_system_power_describe_company' as const,
      getLabel: (borrowerLegalName: string) =>
        `Which of the following best describes ${borrowerLegalName}?`,
      choices: [
        {
          label: 'Special Purpose Vehicle established to own the solar system',
          value: 'Special Purpose Vehicle established to own the solar system',
        },
        {
          label: 'Property Company established to own real estate',
          value: 'Property Company',
        },
      ],
      infoText:
        'A Special Purpose Vehicle is typically an entity established specifically to own one or more solar assets. A property company is typically an entity established specifically to own real estate.',
      required,
    } as const,
    systemPowerSingleOrMultipleUsers: {
      name: 'system_power_single_or_multiple_users' as const,
      getLabel: (borrowerLegalName: string) =>
        `Will ${borrowerLegalName} sell the power to a single user or multiple users?`,
      choices: [
        {
          label: 'Single User',
          value: 'Single User',
        },
        {
          label: 'Multiple Users',
          value: 'Multiple Users',
        },
      ],
      required,
    },
    // Single User info
    systemPowerTaxEquityPartnering: {
      name: 'is_partnering_with_tax_equity_investor' as const,
      getLabel: (borrowerLegalName: string) =>
        `Is ${borrowerLegalName} partnering with a third-party tax equity investor to fund a portion of the solar system?`,
      choices: YES_NO,
      getTooltip: (borrowerLegalName: string) =>
        `Select “Yes” if a tax equity investor unaffiliated with ${borrowerLegalName} will initially own a portion of the solar system.`,
      required,
    },
    systemPowerTaxEquityFundingPercentage: {
      name: 'tax_equity_investor_deal_funding_percent',
      label:
        'What percentage of the solar project will be (or has been) funded by the tax equity investor(s)?',
      max: 99,
      required,
    },
    systemPowerOfftakerNeedsPercentage: {
      name: 'system_power_as_percent_of_offtaker_need' as const,
      getLabel: (borrowerLegalName: string) =>
        `What percentage of the Offtaker’s total power need does ${borrowerLegalName} expect the solar system to produce?`,
      max: 100,
      min: 0,
      required,
    },
    systemPowerOfftakerUtilityRateDiscount: {
      name: 'offtaker_utility_rate_discount' as const,
      getLabel: (borrowerLegalName: string) =>
        `What approximate discount does ${borrowerLegalName} expect to provide the Offtaker compared to their current utility rate in year 1?`,
      max: 99,
      required,
    },
    systemPowerCommunityOrPPALease: {
      name: 'community_solar_or_ppa_lease' as const,
      label:
        'Will the user of the power sign a PPA/Lease agreement or is the solar project a “community solar” arrangement?',
      choices: [
        {
          label: 'PPA/Lease Agreement',
          value: 'PPA/Lease Agreement',
        },
        {
          label: 'Community Solar Arrangement',
          value: 'Community Solar Arrangement',
        },
      ],
      required,
    },
    systemPowerNumberOfOfftakers: {
      name: 'system_power_number_of_offtakers' as const,
      getLabel: (borrowerLegalName: string) =>
        `How many separate buyers (“Offtakers”) will purchase power from ${borrowerLegalName}?`,
      max: 9,
      required,
    },
    systemPowerLegalNamesOfOfftakers: {
      name: 'legal_names_of_offtakers' as const,
      getLabel: (borrowerLegalName: string, multipleOfftakers: boolean) =>
        multipleOfftakers
          ? 'What are the legal names of the Offtakers?'
          : `What is the legal name of the buyer that will purchase power from ${borrowerLegalName} (“Offtaker”)?`,
      required,
    },
    systemPowerSubscribersAmount: {
      name: 'community_solar_subscribers_amount' as const,
      getLabel: (borrowerLegalName: string) =>
        `On average, how many subscribers does ${borrowerLegalName} expect to have on the solar system?`,
      tooltip:
        'Please provide an estimate if an exact number is not available.',
      required,
    },
    systemPowerSubscribersDiscount: {
      name: 'community_solar_subscribers_discount' as const,
      getLabel: (borrowerLegalName: string) =>
        `What discount does ${borrowerLegalName} expect to provide subscribers compared to the current utility rate?`,
      max: 100,
      required,
    },
    systemPowerUtilityJurisdictionName: {
      name: 'local_utility_jurisdiction_name' as const,
      label:
        'What is the name of the local utility jurisdiction where the solar system will be installed?',
      required,
    },
    systemPowerSubscriberManagementCompany: {
      name: 'subscriber_management_company_name' as const,
      getLabel: (borrowerLegalName: string) =>
        `What is the name of the subscriber management company that ${borrowerLegalName} expects to hire?`,
      getTooltip: (borrowerLegalName: string) =>
        `If you do not know which subscriber management company ${borrowerLegalName} expects to hire, or if the Applicant does not expect to hire a subscriber management company, please explain in the provided text field.`,
      required,
    },
    // Property Company info
    // Financial Information. A34-A39, A42, D1-D4
    borrowerTIN: {
      name: 'borrower_tin',
      label: 'Employer Identification Number (EIN)',
      requiredErrorMsg: 'EIN is required',
      required,
      format: NUMBER_FORMATS.EIN,
      getTooltip: (borrowerLegalName: string) =>
        `Please provide the Employer Identification Number or Tax ID ${borrowerLegalName} uses to pay taxes. You will be able to find this on a previously filed tax return or your IRS form SS-4.`,
    },
    borrowerGrossRevenueLatest: {
      name: 'borrower_gross_revenue_latest' as const,
      label: 'Gross Business Revenue',
      getLabel: (borrowerLegalName: string) =>
        `What was ${borrowerLegalName}'s gross revenues for the most recent year?`,
      required,
      requiredErrorMsg: 'Gross Business Revenue is required',
    },
    borrowerAdvance: {
      name: 'borrower_advance' as const,
      choices: YES_NO,
      required,
    },
    borrowerHasCreditUnderOtherName: {
      name: 'borrower_has_credit_under_other_name' as const,
      getLabel: (borrowerLegalName: string) =>
        `Has ${borrowerLegalName} or any Principal of ${borrowerLegalName} ever obtained credit under another name?`,
      choices: YES_NO,
      requiredErrorMsg: 'Answer is required',
      required,
    },
    finInfoNameCreditWasObtainedUnder: {
      name: 'fin_info_name_credit_was_obtained_under',
      label: 'Please provide the other name credit was obtained under.',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
      required,
    },
    // Ownership History and Business Information
    // A6-A8, A30-A33, A40, A41, A43
    borrowerBusinessStructure: {
      name: 'borrower_structure' as const,
      getLabel: (borrowerLegalName: string) =>
        `${borrowerLegalName} Business Structure`,
      choices: [
        {
          value: 'LLC',
          label: 'LLC',
        },
        {
          value: 'LLP',
          label: 'LLP',
        },
        {
          value: 'S-CORP',
          label: 'S-Corp',
        },
        {
          value: 'C-CORP',
          label: 'C-Corp',
        },
        {
          value: 'GENERAL PARTNERSHIP',
          label: 'General Partnership',
        },
        {
          value: 'SOLE PROPRIETORSHIP',
          label: 'Sole Proprietorship',
        },
        {
          value: 'NON-PROFIT',
          label: 'Non-Profit',
        },
        {
          value: 'HOA',
          label: 'HOA',
        },
      ],
      required,
      requiredErrorMsg: 'Business Structure is required',
    },
    borrowerNetIncomeAnnualAfterTax: {
      name: 'borrower_net_income_annual_after_tax' as const,
      label: 'Net After-Tax Annual Business Income',
      required,
      requiredErrorMsg: 'Net After-Tax Annual Business Income is required',
    },
    borrowerStateOfOrganization: {
      name: 'borrower_state_of_organization',
      getLabel: (borrowerLegalName: string) =>
        `${borrowerLegalName}’s State of Organization`,
      required,
      requiredErrorMsg: 'State of Organization is required',
      choices: UNITED_STATES,
    },
    borrowerLinesOfBusiness: {
      name: 'borrower_industry_lines_of_business',
      getLabel: (borrowerLegalName: string) =>
        `Please describe ${borrowerLegalName}'s business`,
      getTooltip: (borrowerLegalName: string) =>
        `Briefly describe what line of business ${borrowerLegalName} is in and how ${borrowerLegalName} generates revenue.`,
      required,
      requiredErrorMsg: 'Primary business activity is required',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    borrowerBusinessEstablishedDate: {
      name: 'borrower_date_business_established_date' as const,
      label: 'Date Business Established',
      required,
      requiredErrorMsg: 'Date Business Established is required',
    },
    borrowerBusinessEstablishedYear: {
      name: 'business_established_year' as const,
      getLabel: (borrowerLegalName: string) =>
        `Year ${borrowerLegalName} was Established`,
      required,
      format: NUMBER_FORMATS.YEAR,
    },
    borrowerYearsManagementInPlace: {
      name: 'years_management_in_place' as const,
      getLabel: (borrowerLegalName: string) =>
        `Number of Years that ${borrowerLegalName}'s Present Management has been in Place`,
      getTooltip: (borrowerLegalName: string) =>
        `Please provide the tenure of the present management of ${borrowerLegalName}.`,
      required,
      max: LIMITS.MAX_YEARS_ESTABLISHED,
    },
    borrowerManagementExperienceYears: {
      name: 'management_years_of_industry_experience',
      getLabel: (borrowerLegalName: string) =>
        `Number of years of industry relevant experience of ${borrowerLegalName}'s longest-tenured management member`,
      getTooltip: (borrowerLegalName) =>
        `Please enter the number of years that ${borrowerLegalName}'s longest-tenured management member has been working in ${borrowerLegalName}'s industry.`,
      max: 99,
      required,
    },
    borrowerHasPotentialOwnershipChange: {
      name: 'borrower_has_potential_ownership_change' as const,
      getLabel: (borrowerLegalName: string) =>
        `Is ${borrowerLegalName} for sale or is there any agreement that would change ${borrowerLegalName}'s ownership in the near-term?`,
      required,
      requiredErrorMsg: 'Answer is required',
    },
    borrowerIsHighRisk: {
      name: 'borrower_is_high_risk' as const,
      getLabel: (borrowerLegalName: string) =>
        `Does ${borrowerLegalName} support the cannabis industry (including CBD and hemp), buy or sell currency for customers or themselves (including crypto currency), engage in firearm sales (including the sale of ammunition), facilitate gambling (internet or otherwise), provide check-cashing services, or offer high-rate loans to consumers (e.g., > 25% APR or payday loans)?`,
      required,
      choices: YES_NO,
      requiredErrorMsg: 'Answer is required',
    },
    borrowerIsHighRiskExplanation: {
      name: 'borrower_is_high_risk_explanation',
      label: 'Please provide an explanation.',
      required,
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    borrowerIsReligiousOrganization: {
      name: 'is_religious_organization' as const,
      getLabel: (borrowerLegalName: string) =>
        `Is the ${borrowerLegalName} a religious organization?`,
      required,
      choices: YES_NO,
      requiredErrorMsg: 'Answer is required',
    },
    borrowerReligiousParentOrgSupport: {
      name: 'parent_religious_organization_support' as const,
      getLabel: (borrowerLegalName: string) =>
        `Does your religious organization have financial support from parent organization in the event of financial need?`,
      required,
      choices: YES_NO,
      requiredErrorMsg: 'Answer is required',
    },
    borrowerReligiousLeaderYears: {
      name: 'years_religious_leader_position' as const,
      getLabel: (borrowerLegalName: string) =>
        `How many years has the leader of ${borrowerLegalName} been in their position?`,
      required,
      requiredErrorMsg: 'Answer is required',
    },
    // Principals. Section B
    // Principals are in an array. For example: principals[i].position_title

    // these fields that start with "__" are not in RelatedParty, they are just used for managing principals on the application
    __isPGValue: {
      name: '__is_pg_value',
      label: 'isPersonalGuarantorValue',
      choices: OCCUPATIONS,
    },
    __asapCount: {
      name: '__asap_count',
      label: 'applicantSameAsPrincipalCount',
      choices: OCCUPATIONS,
    },
    __companyTypeValue: {
      name: '__company_type_value',
      label: 'companyTypeValue',
      choices: COMPANY_TYPES_OBJECT_ARRAY,
    },
    principalOccupation: {
      name: 'position_title',
      label: 'Occupation',
      choices: OCCUPATIONS,
      required,
      requiredErrorMsg: 'Occupation is required',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    principalOtherOccupation: {
      name: 'other_occupation',
      label: 'Other Occupation',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
      getRequired: (occupation) => occupation === 'Other',
      required,
      requiredErrorMsg: 'Other Occupation is required',
    },
    principalAddress: {
      name: 'address',
      label: 'Address (residential or business street address)',
      required,
      requiredErrorMsg: 'Address is required',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    principalAddressLine2: {
      name: 'address_line_2',
      label: 'Suite, Floor, etc.',
      placeholder: 'Enter Apt, suite, unit, building, floor, etc.',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    principalCity: {
      name: 'city',
      label: 'City',
      required,
      requiredErrorMsg: 'City is required',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    principalState: {
      name: 'state_picklist',
      label: 'State',
      required,
      requiredErrorMsg: 'State is required',
      choices: UNITED_STATES,
    },
    principalZip: {
      name: 'zip_code',
      label: 'Zip',
      format: NUMBER_FORMATS.ZIP,
      required,
      requiredErrorMsg: 'Zip Code is required',
    },
    principalCountry: {
      name: 'country',
      label: 'Country',
      required,
      requiredErrorMsg: 'Country is required',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    principalFirstName: {
      name: 'first_name',
      label: 'First Name',
      required,
      requiredErrorMsg: 'First Name is required',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    principalLastName: {
      name: 'last_name',
      label: 'Last Name',
      required,
      requiredErrorMsg: 'Last Name is required',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    principalMiddleInitial: {
      name: 'middle_initial',
      label: 'Middle Initial',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    principalIsUSCitizen: {
      name: 'is_us_citizen' as const,
      label: 'Are you a US citizen or permanent resident?',
      choices: YES_NO,
      requiredErrorMsg: 'Answer is required',
      required,
    },
    principalNonUSPersonIdNumber: {
      name: 'non_us_person_id_number',
      label:
        'Social Security Number, passport number or other similar identification number',
      required,
      requiredErrorMsg: 'ID number is required',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    principalNonUSPersonIssuanceCountry: {
      name: 'non_usperson_id_country_of_issuance',
      label: 'Country of Issuance',
      choices: COUNTRIES.map((country) => ({
        value: country.name,
        label: country.name,
      })),
      required,
      requiredErrorMsg: 'Country of Issuance is required',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    principalSsn: {
      name: 'ssn_text',
      label: 'Social Security Number',
      format: NUMBER_FORMATS.SSN,
      required,
      requiredErrorMsg: 'Social Security Number is required',
    },
    principalBirthdate: {
      name: 'dob',
      label: 'Date of Birth',
      required,
      requiredErrorMsg: 'Date of Birth is required',
    },
    principalPhoneNumber: {
      name: 'phone_number',
      label: 'Phone Number',
      format: NUMBER_FORMATS.PHONE,
      placeholder: PLACEHOLDERS.PHONE,
      required,
      requiredErrorMsg: 'Phone Number is required',
    },
    principalEmailAddress: {
      name: 'email',
      label: 'Email Address',
      required,
      requiredErrorMsg: 'Email Address is required',
      maxLength: SF_MAX_CHAR_LIMITS.EMAIL,
    },
    principalPercentOwned: {
      name: 'percent_ownership',
      label: 'Percentage Owned',
      required,
      requiredErrorMsg: 'Percentage Owned is required',
      tooltip:
        'If the principal does not have ownership, such as a non-profit, enter zero for ownership percentage.',
      max: 100,
    },
    principalType: {
      name: 'principal_type' as const,
      choices: [
        {
          label: 'Individual',
          value: RELATED_PARTY_TYPES.INDIVIDUAL,
        },
        {
          label: 'Trust',
          value: RELATED_PARTY_TYPES.ENTITY,
        },
      ],
      getLabel: (principalIndex: number) =>
        `Is the ${
          principalIndex === 0
            ? 'controlling principal'
            : `principal ${principalIndex + 1}`
        } an individual or a trust?`,
      required,
      requiredErrorMsg: 'Answer is required',
    },
    principalTrustName: {
      name: 'name_of_trust',
      label: 'Name of Trust',
      required,
    },
    principalTrustYearEstablished: {
      name: 'year_trust_was_established',
      label: 'Year Trust was Established',
      tooltip:
        'Please provide the year when the fully executed final trust agreement was filed.',
      format: NUMBER_FORMATS.YEAR,
      required,
    },
    principalTrustTIN: {
      name: 'trust_tin',
      label: 'Taxpayer Identification Number (TIN)',
      format: NUMBER_FORMATS.EIN,
      required,
    },
    principalIdDocument: {
      name: 'PRINCIPAL_ID' as const,
      label:
        'Government issued ID such as a State driver’s license or passport',
      getLabel: (principalName: string) =>
        `Please upload a government-issued ID such as a state driver's license or passport for ${principalName}`,
      required,
      infoText:
        "Provide a copy of a government issued form of identification such as a driver's license or passport for each beneficial owner and controlling party.",
      requiredErrorMsg: 'Copy of Government-Issued ID is required',
    },
    principalCertificateOfTrust: {
      name: 'Certificate of Trust or Executed Trust Agreement' as const,
      getLabel: (trustName: string) =>
        `Certificate of Trust or Executed Trust Agreement for ${trustName}`,
      required,
    },
    // Personal Guarantor
    // this field is in Application
    isPG: {
      name: 'is_personal_guarantor' as const,
      choices: YES_NO,
    },
    // this field is in RelatedParty
    principalIsPG: {
      name: 'principal_is_personal_guarantor' as const,
      getLabel: (firstName: string, lastName: string) =>
        firstName && lastName
          ? `Is ${firstName} ${lastName} willing to be a Guarantor for this loan?`
          : `Is this Principal willing to be a Guarantor for this loan?`,
      choices: YES_NO,
      required,
      requiredErrorMsg: 'Choice is required',
    },
    applicantSameAsPrincipal: {
      name: 'applicant_same_as_principal' as const,
      getLabel: (firstName: string, lastName: string) =>
        firstName && lastName
          ? `Are you ${firstName} ${lastName}?`
          : `Are you this Principal?`,
      choices: YES_NO,
      required,
      requiredErrorMsg: 'Choice is required',
    },
    principalAnnualGrossIncome: {
      name: 'annual_gross_income',
      label: 'Guarantor Annual Gross Income',
      tooltip:
        'Please enter gross income as listed on the guarantor’s annual tax return. If filed jointly, please do not include filing partner/spouse income. Income from alimony, child support, or separate maintenance need not be revealed if you do not want that income considered as a basis of repayment.',
      required,
      requiredErrorMsg: 'Guarantor Annual Gross Income is required',
      thousandSeparator: ',',
      decimalScale: 2,
      max: MAX_NUMBER_INPUT_VALUE,
    },
    principalLiquidAssets: {
      name: 'liquid_assets',
      label: 'Guarantor Liquid Assets',
      tooltip:
        'Liquid Assets include cash in hand, cash at bank, and investments in brokerage accounts.',
      required,
      requiredErrorMsg: 'Guarantor Liquid Assets are required',
      thousandSeparator: ',',
      decimalScale: 2,
      max: MAX_NUMBER_INPUT_VALUE,
    },
    principalAverageCashBalance: {
      name: 'average_cash_balance',
      getLabel: (borrower_business_name: string | undefined) =>
        (borrower_business_name
          ? borrower_business_name
          : 'Personal Guarantor') + "'s Average Cash Balance",
      thousandSeparator: ',',
      decimalScale: 2,
      max: MAX_NUMBER_INPUT_VALUE,
      required: false,
      requiredErrorMsg: 'Average Cash Balance is required',
    },
    principalRentOrOwn: {
      name: 'rent_or_own',
      label: 'Do you rent or own your residence?',
      required,
      requiredErrorMsg: 'Choice is required',
      choices: RENT_OWN,
    },
    principalMortgagePayment: {
      name: 'mortgage_payment',
      label: 'Please enter the monthly mortgage payment.',
      tooltip: 'Combined monthly interest and principal payment.',
      getRequired: (
        isPersonalGuarantor: boolean,
        rentOrOwn: RelatedParty['rent_or_own']
      ) => isPersonalGuarantor && rentOrOwn == 'Own',
      required,
      requiredErrorMsg: 'Mortgage payment is required (Enter 0 if none)',
      thousandSeparator: ',',
      decimalScale: 2,
      max: MAX_NUMBER_INPUT_VALUE,
    },
    principalRent: {
      name: 'monthly_rent',
      label: 'Please enter the monthly rental payment.',
      getRequired: (
        isPersonalGuarantor: boolean,
        rentOrOwn: RelatedParty['rent_or_own']
      ) => isPersonalGuarantor && rentOrOwn == 'Rent',
      required,
      requiredErrorMsg: 'Monthly rent is required (Enter 0 if none)',
      thousandSeparator: ',',
      decimalScale: 2,
      max: MAX_NUMBER_INPUT_VALUE,
    },
    principalPGAgreement: {
      name: 'guarantor_agreement' as const,
      label: 'I Agree',
      required,
      requiredErrorMsg: '"I Agree" checkbox is required',
    },
    pgFiledTaxes1YearAgo: {
      name: 'filed_taxes_1_year_ago' as const,
      choices: YES_NO,
      getLabel: (
        firstName: string | null | undefined,
        lastName: string | null | undefined,
        applicantSameAsPrincipal: boolean,
        index: number
      ) =>
        `Has ${
          firstName && lastName
            ? `${firstName} ${lastName}`
            : `Principal ${index}`
        } filed a ${pg1Year} tax return?` +
        (!applicantSameAsPrincipal ? ' (Optional)' : ''),
      getRequired: (
        applicantSameAsPrincipal: RelatedParty['applicant_same_as_principal']
      ) => applicantSameAsPrincipal === 'Yes',
      required,
    },
    pgFiledTaxes2YearsAgo: {
      name: 'filed_taxes_2_years_ago' as const,
      choices: YES_NO,
      getLabel: (
        firstName: string | null | undefined,
        lastName: string | null | undefined,
        applicantSameAsPrincipal: boolean,
        index: number
      ) =>
        `Has ${
          firstName && lastName
            ? `${firstName} ${lastName}`
            : `Principal ${index}`
        } filed a ${pg1Year - 1} tax return?` +
        (!applicantSameAsPrincipal ? ' (Optional)' : ''),
      getRequired: (
        applicantSameAsPrincipal: RelatedParty['applicant_same_as_principal']
      ) => applicantSameAsPrincipal === 'Yes',
      required,
    },
    pgFiledTaxes3YearsAgo: {
      name: 'filed_taxes_3_years_ago' as const,
      choices: YES_NO,
      getLabel: (
        firstName: string | null | undefined,
        lastName: string | null | undefined,
        applicantSameAsPrincipal: boolean,
        index: number
      ) =>
        `Has ${
          firstName && lastName
            ? `${firstName} ${lastName}`
            : `Principal ${index}`
        } filed a ${pg1Year - 2} tax return?` +
        (!applicantSameAsPrincipal ? ' (Optional)' : ''),
      getRequired: (
        applicantSameAsPrincipal: RelatedParty['applicant_same_as_principal']
      ) => applicantSameAsPrincipal === 'Yes',
      required,
    },
    // Outstanding Loan Obligations. Section C
    borrowerAttestsNoDebt: {
      name: 'borrower_attests_no_debt' as const,
      label:
        'Our business has no outstanding debt obligations greater than $10,000.',
      required,
    },
    // Outstanding loans are in an array. For example: outstanding_loans[i].reference_name
    loanLender: {
      name: 'lender_name',
      label: 'Lender Name',
      requiredErrorMsg: 'Lender Name is required',
      required,
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    loanType: {
      name: 'loan_type',
      label: 'Loan Type',
      required,
      requiredErrorMsg: 'Loan Type is required',
      choices: [
        { value: 'Personal', label: 'Personal' },
        { value: 'Commercial', label: 'Commercial' },
      ],
      infoText: 'Provide the loan type',
    },
    loanBalance: {
      name: 'loan_balance',
      label: 'Current Balance',
      required,
      requiredErrorMsg: 'Balance is required',
      tooltip: 'Please provide the most recent outstanding balance.',
      max: MAX_NUMBER_INPUT_VALUE,
    },
    outstandingLoanType: {
      name: 'outstanding_loan_type',
      label: 'Loan Type',
      required,
      requiredErrorMsg: 'Loan Type is required',
      choices: OUTSTANDING_LOAN_TYPES,
    },
    loanMaturityDate: {
      name: 'loan_maturity_date',
      label: 'Maturity Date',
      required,
      requiredErrorMsg: 'Maturity Date is required',
      infoText: 'Enter the due date for final payment',
    },
    loanMonthlyPayment: {
      name: 'loan_monthly_payment',
      label: 'Monthly Payment',
      required,
      requiredErrorMsg: 'Monthly Payment is required',
      tooltip: 'Please enter the average amount due each month.',
      max: MAX_NUMBER_INPUT_VALUE,
    },
    loanOriginalAmount: {
      name: 'loan_original_amount',
      label: 'Original Amount',
      required,
      requiredErrorMsg: 'Original Amount is required',
      infoText: 'Enter the original loan amount',
      max: MAX_NUMBER_INPUT_VALUE,
    },
    loanCollateralDescription: {
      name: 'loan_collateral_description',
      label: 'Collateral Description',
      required,
      requiredErrorMsg: 'Collateral Description is required',
      infoText: 'Describe any collateral offered as a part of the loan',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    loanHasPast30DaysDue: {
      name: 'any_payments_30_days_past_due',
      label: 'Any Payments > 30 Days Past Due?',
      required,
      requiredErrorMsg: 'Answer is required',
    },
    loanContactName: {
      name: 'reference_name',
      label: 'Contact Name',
      required,
      requiredErrorMsg: 'Contact Name is required',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    loanContactPhone: {
      name: 'reference_phone_number',
      label: 'Contact Phone Number',
      format: NUMBER_FORMATS.PHONE,
      placeholder: PLACEHOLDERS.PHONE,
      required,
      requiredErrorMsg: 'Contact Phone Number is required',
    },
    loanContactEmailAddress: {
      name: 'reference_email',
      label: 'Email Address',
      required,
      requiredErrorMsg: 'Email Address is required',
      maxLength: SF_MAX_CHAR_LIMITS.EMAIL,
    },
    loanPurpose: {
      name: 'loan_purpose',
      label: 'Loan Purpose',
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
      getRequired: (
        outstanding_loan_type: MaterialLoanObligation['outstanding_loan_type']
      ) => outstanding_loan_type === 'Other',
      requiredErrorMsg: 'Please enter the loan purpose',
    },
    loanYearOfMaturity: {
      name: 'year_of_loan_maturity',
      label: 'Loan Maturity Year',
      tooltip: 'Please enter the year that final payment is due.',
      required,
      format: NUMBER_FORMATS.YEAR,
    },
    onSystemProperty: {
      name: 'on_system_property',
      label: 'On System Property',
    },
    // User can also upload a single Debt Schedule document instead of submitting loan obligations
    loanDebtSchedule: {
      name: 'Debt Schedule' as const,
      label: 'Debt Schedule Upload',
      reviewLabel: 'Debt Schedule',
      tooltip:
        'At a minimum, debt schedule should include the current outstanding balance, remaining term, and monthly payment amount for each loan obligation.',
      required: false,
    },
    // E-sign Consent
    borrowerEsignName: {
      name: 'borrower_esign_name',
      label: 'Full Name E-Signature',
      requiredErrorMsg: 'E-Signature is required',
      required,
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    borrowerSignerRole: {
      name: 'borrower_signer_role',
      label: 'Position/Title',
      requiredErrorMsg: 'Position/Title is required',
      required,
      maxLength: SF_MAX_CHAR_LIMITS.CHAR_FIELD,
    },
    // Documents
    documents: {
      plaidAssetReport: {
        name: 'plaid_asset_report',
      },
      taxReturnsLastYear: {
        name: 'TR',
        getLabel: (borrowerLegalName) =>
          currentMonth < 5
            ? `Company’s self-prepared financial statement (P&L and balance sheet) or tax return from ${lastYear}`
            : `Company’s ${lastYear} tax return, or a copy of the extension ${borrowerLegalName} filed with the IRS (form 7004)`,

        infoText: `Please provide a copy of your company's tax return filed with the IRS for the ${lastYear} tax year. If you don't have a copy of the tax return, please provide a copy of the extension you filed with the IRS (form 7004).`,
        required: true,
      },
      selfPreparedFinancials: {
        name: 'self_prepared_financials',
        label: `If the Company filed an extension on ${lastYear} taxes, please provide ${lastYear} self-prepared financial statements (P&L and balance sheet).`,
        reviewLabel: `${lastYear} self-prepared financial statements (P&L and balance sheet)`,
        required: false,
        // Don't show this field January through May
        skip: currentMonth < 5,
      },
      taxReturns1Year: {
        name: 'TR1',
        label: `Company’s ${yearAgo} tax return`,
        infoText: `Please provide a copy of your company's tax return filed with the IRS for the ${yearAgo} tax year.`,
        required: true,
      },
      taxReturns2Year: {
        name: 'TR2',
        label: `Company’s ${twoYearsAgo} tax return`,
        infoText: `Please provide a copy of your company's tax return filed with the IRS for the ${twoYearsAgo} tax year.`,
        required: true,
      },
      interimFinancialStatement: {
        name: 'FIN',
        label: `Company’s ${currentYear} year-to-date self-prepared financial statement (P&L and balance sheet)`,
        infoText:
          'If requested by Sunstone, please provide financial statements for the year not yet completed. Financial statements should include an income statement and balance sheet.',
        required: true,
        skip: currentMonth < 5,
      },
      bankStatementLastMonth: {
        name: 'BS',
        label: 'Bank statement from last month',
        infoText: '',
        required: true,
        getRequired: (
          isPersonalGuarantor: Application['is_personal_guarantor']
        ) => isPersonalGuarantor !== 'Yes',
      },
      bankStatement1Month: {
        name: 'BS1',
        label: 'Bank statement from two months ago',
        infoText: '',
        required: true,
        getRequired: (
          isPersonalGuarantor: Application['is_personal_guarantor']
        ) => isPersonalGuarantor !== 'Yes',
      },
      bankStatement2Month: {
        name: 'BS2',
        label: 'Bank statement from three months ago',
        infoText: '',
        required: true,
        getRequired: (
          isPersonalGuarantor: Application['is_personal_guarantor']
        ) => isPersonalGuarantor !== 'Yes',
      },
      ownershipDocs: {
        name: 'OWN' as const,
        getLabel: (propertyAddress: string) =>
          `Lease or Proof of Ownership of the property where the system will be installed${
            propertyAddress ? `, which was listed at ${propertyAddress}?` : '?'
          }`,
        infoText:
          'Please provide proof that you own or have legal access to the property where the system will be installed. Acceptable documentation includes a signed and current lease agreement, property deed or other evidence of ownership.',
        required: true,
      },
    },
    // Company documents section
    companyDocuments: {
      articlesOfIncorporation: {
        name: 'Articles of Organization/Incorporation' as const,
        getLabel: (borrowerLegalName: string) =>
          `Since ${borrowerLegalName}'s business is less than three years old, please upload a copy of the articles of organization/incorporation.`,
        required,
        getSkip: (yearBusinessEstablished: number) => {
          const currentYear = new Date().getFullYear();
          return currentYear - yearBusinessEstablished >= 3;
        },
      },
      // Non-profit documents
      nonProfitBoardResolution: {
        name: 'Non-Profit Board Resolution' as const,
        label:
          'Please upload the resolution from the Board of Directors authorizing the solar system loan.',
        required,
      },
      nonProfitBylawsCopy: {
        name: 'Non-Profit Bylaws' as const,
        getLabel: (borrowerLegalName: string) =>
          `Please upload a copy of ${borrowerLegalName}'s bylaws.`,
        required,
      },
      // Religious organization documents
      religiousOrganizationAttendance: {
        name: 'Religious Organization Attendance' as const,
        getLabel: (borrowerLegalName: string) =>
          `Please upload a document detailing ${borrowerLegalName}'s attendance.`,
        tooltip: 'Attendance by month over the past 36 months',
        required,
      },
      religiousOrganizationDonations: {
        name: 'Religious Organization Donations' as const,
        getLabel: (borrowerLegalName: string) =>
          `Please upload a document detailing ${borrowerLegalName}'s donations / tithes (“Giving Units”).`,
        tooltip:
          'Donations / tithes (“Giving Units”) by month by contributor over the past 36 months (anonymized)',
        required,
      },
      // Property company documents
      propertyCompanyRentRoll: {
        name: 'Property Company Rent Roll' as const,
        getLabel: (borrowerLegalName: string) =>
          `Please provide a rent roll that includes each of ${borrowerLegalName}'s tenants.`,
        tooltip:
          "Must include each tenant's name, move-in date, current lease term, renewal term, monthly rent payment, and confirmation that the tenant has made their payments in a satisfactory manner.",
        required,
      },
      propertyCompanyMortgageStatement: {
        name: 'Property Company Mortgage Statement' as const,
        getLabel: (borrowerLegalName: string) =>
          `Please provide a copy of the mortgage statement for ${borrowerLegalName}'s property from within the past 90 days.`,
        tooltip:
          'The mortgage statement should be from the institution that issued your mortgage and contain the monthly payment, the payment due date, interest rate, detail how the payment is allocated between principal, interest, taxes, insurance and other obligations, and list the property address.',
        required,
      },
      pgMortgageStatement: {
        name: 'Mortgage Statement' as const,
        getLabel: (address: string, required: boolean) =>
          `Please upload the most recent Mortgage Statement for the property at ${address}. ${
            required ? '' : ' (Optional)'
          } `,
        tooltip:
          'The mortgage statement should be from the institution that issued your mortgage and contain the monthly payment, the payment due date, interest rate, detail how the payment is allocated between principal, interest, taxes, insurance and other obligations, and list the property address.',
        getRequired: (mortgageStatementRequired: boolean) =>
          mortgageStatementRequired === true,
        required,
        requiredErrorMsg: 'Mortgate Statement is required',
      },
    },
    hoaDocuments: {
      memberAssessmentPolicy: {
        name: 'Member Assessment Policy' as const,
        label: 'A copy of the member assessment policy',
        tooltip:
          'Policy detailing how operating and reserve assessments are calculated and how revenue and expenses are recognized across operating and reserve accounts.',
      },
      priorYearBudgets: {
        name: 'Operating and Reserve Budgets' as const,
        label: 'Copies of operating and reserve budgets for the prior year.',
        required,
      },
      currentOccupantLedger: {
        name: 'Current Occupant Ledger' as const,
        label: 'A ledger detailing the current occupants',
        getTooltip: (borrowerLegalName: string) =>
          `For the privacy of your occupants, please provide unit numbers only – no names), their tenure with ${borrowerLegalName}, and the current status of dues payments (current, past due).`,
        required,
      },
      meetingMinutes: {
        name: 'Meeting Minutes' as const,
        label:
          'Meeting minutes or similar documentation showing the solar system purchase has been authorized.',
        tooltip:
          'If not currently available, this can be submitted at a later time',
      },
      hoaBylaws: {
        name: 'HOA Bylaws' as const,
        label: 'Please upload a copy of the HOA bylaws',
        tooltip:
          'If not currently available, this can be submitted at a later time',
      },
    },
    solarSystemDocuments: {
      // Lease documents
      leaseOfTheSystemProperty: {
        name: 'Lease of the property where the system will be installed' as const,
        getLabel: (borrowerLegalName: string, propertyAddress: string) =>
          `Please upload ${borrowerLegalName}'s lease of the property where the system will be installed${
            propertyAddress ? `, listed at ${propertyAddress}` : ''
          }.`,
        tooltip:
          'The lease must be for the exact property where the system will be installed, whether a building, roof, or plot of land. If the lease is embedded within a Power Purchase Agreement, you may upload the Power Purchase Agreement.',
        required,
      },
      // Sold to single entity documents
      offtakerPPAorLeaseAgreement: {
        name: 'Offtaker PPA or Lease Agreement' as const,
        label:
          'Please upload a copy of the PPA or Lease agreement that the Offtaker will sign or has signed.',
        required,
        uploadMoreText: 'Upload additional copy',
      },
      proofOfOfftakerCreditworthiness: {
        name: 'Proof of Offtaker Creditworthiness' as const,
        getLabel: (borrowerLegalName: string) =>
          `Please upload a copy of any financial statements, commercial credit reports, or other data ${borrowerLegalName} used to determine the creditworthiness of the Offtaker.`,
        tooltip:
          'While not required at this time, providing this information will accelerate underwriting.',
        required: false,
        uploadMoreText: 'Upload additional copy',
      },
      offTakerLeaseOrProofOfOwnership: {
        name: 'Offtaker Lease or Proof of Ownership' as const,
        getLabel: (propertyAddress: string) =>
          `If the Offtaker is a tenant in the property${
            propertyAddress ? `-listed at ${propertyAddress}-` : ''
          } where the system will be installed or in another property, please include a copy of the Offtaker’s current lease. If the Offtaker owns the property where the system will be installed, please include proof of property ownership.`,
        tooltip:
          'While not required at this time, providing this information will accelerate underwriting.',
        required: false,
      },
      offTakerUtilityBill: {
        name: 'Offtaker Utility Bill' as const,
        label:
          "Please upload copies of the Offtaker's utility bill(s) covering at least 12 months of power usage.",
        tooltip:
          'If a single utility bill shows 12 months of power usage, there is no need to upload more than one bill.',
        required,
        uploadMoreText: 'Upload additional copy',
      },
      ppaLeaseFinancialProjections: {
        name: 'PPA/Lease Financial Projections' as const,
        label:
          'Please upload financial projections for the project, including Offtaker revenue and operating expenses.',
        tooltip:
          'At a minimum, financial projections should include annual system production, the price of power sold in each year, total system revenue, O&M expense, and insurance expense.',
        required,
      },
      // Sold to multiple entities documents
      // PPA/Lease documents
      // Community solar documents
      approvalForCommunitySolarDelivery: {
        name: 'Approval for Community Solar Delivery' as const,
        getLabel: (borrowerLegalName: string) =>
          `Please upload the official documentation showing that ${borrowerLegalName} has been approved to deliver community solar, including the system design and system address.`,
        tooltip:
          'While not required at this time, providing this information will accelerate underwriting.',
        required: false,
      },
      communitySolarFinancialProjections: {
        name: 'Community Solar Financial Projections' as const,
        label:
          'Please upload financial projections for the solar system, including subscriber revenue and operating expenses.',
        tooltip:
          'At a minimum, financial projections should include annual system production, the price of power sold in each year, total system revenue, subscriber management expense, O&M expense, and insurance expense.',
        required,
        uploadMoreText: 'Upload additional copy',
      },
      subscriberManagementCompanyContract: {
        name: 'Subscriber Management Company Contract' as const,
        getLabel: (borrowerLegalName: string) =>
          `Please upload a copy of the contract ${borrowerLegalName} has signed or expects to sign with the subscriber management company.`,
        tooltip:
          'While not required at this time, providing this information will accelerate underwriting.',
        required: false,
      },
    },
    otherDocuments: {
      additionalDocuments: {
        name: 'Additional Document' as const,
        label: 'Upload any additional documents (optional).',
        tooltip: 'You can add up to 10 additional documents.',
        required: false,
        uploadMoreText: 'Upload additional documents',
      },
    },
  },
};

export const taxDocuments = {
  financialStatementsTwoYearsAgo: {
    name: 'Financial Statements Two Years Ago' as const,
    getLabel: (borrowerLegalName: string) =>
      `Please provide ${borrowerLegalName}'s ${twoYearsAgo} audited or accountant-prepared financial statement. If not available, please upload a ${twoYearsAgo} company-prepared financial statement (P&L and balance sheet).`,
    tooltip:
      'Financial statements should include an income statement and balance sheet.',
  },
  financialStatementsOneYearAgo: {
    name: 'Financial Statements One Year Ago' as const,
    getLabel: (borrowerLegalName: string) =>
      `Please provide ${borrowerLegalName}'s ${yearAgo} audited or accountant-prepared financial statement. If not available, please upload a ${yearAgo} company-prepared financial statement (P&L and balance sheet).`,
    tooltip:
      'Financial statements should include an income statement and balance sheet.',
  },
  financialStatementsLastYear: {
    name: 'Financial Statements Last Year' as const,
    getLabel: (borrowerLegalName: string) =>
      `Please provide ${borrowerLegalName}'s ${lastYear} audited or accountant-prepared financial statement. If not available, please upload a ${lastYear} company-prepared financial statement (P&L and balance sheet).`,
    tooltip:
      'Financial statements should include an income statement and balance sheet.',
    getRequired: (systemPowerUsage: Application['solar_system_power_usage']) =>
      systemPowerUsage !== SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY,
  },
  financialStatementsCurrentYear: {
    name: 'Financial Statements Current Year' as const,
    getLabel: (borrowerLegalName: string) =>
      `Please provide ${borrowerLegalName}'s ${currentYear} Year to Date (“YTD”) financial statements. This should include a Profit & Loss Statement as well as a Balance Sheet.`,
    tooltip:
      'Financial statements should include an income statement and balance sheet.',
    getRequired: (systemPowerUsage: Application['solar_system_power_usage']) =>
      systemPowerUsage !== SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY,
  },
  applicantFiledTaxReturnsInThePast: {
    name: 'applicant_filed_tax_returns_in_the_past' as const,
    choices: YES_NO,
    getLabel: (borrowerLegalName: string) =>
      `Has ${borrowerLegalName} ever filed tax returns?`,
    required,
  },
  applicantFiledLastYearTaxReturns: {
    name: 'applicant_filed_last_year_tax_returns' as const,
    choices: YES_NO,
    getLabel: (borrowerLegalName: string) =>
      `Has ${borrowerLegalName} filed a ${lastYear} tax return?`,
    required,
  },
  // If applicant did not file tax returns in the past
  irsFormSS4: {
    name: 'IRS Form SS-4' as const,
    getLabel: (borrowerLegalName: string) =>
      `Please upload a copy of the IRS form SS-4 to verify the employer identification number of ${borrowerLegalName}.`,
    displayCondition: (
      filedTaxReturns: Application['applicant_filed_tax_returns_in_the_past']
    ) => filedTaxReturns === 'No',
    required,
  },
  taxReturnsExtension: {
    name: 'Tax Returns Extension (Form 7004)' as const,
    getLabel: (borrowerLegalName: string) =>
      `Please upload a copy of the extension ${borrowerLegalName} filed with the IRS (form 7004).`,
    displayCondition: (
      filedTaxReturns: Application['applicant_filed_tax_returns_in_the_past'],
      applicantFiledLastYearTaxReturns: Application['applicant_filed_last_year_tax_returns']
    ) =>
      isYes(filedTaxReturns) &&
      isNo(applicantFiledLastYearTaxReturns) &&
      INTERVAL_DATES().MayOct,
    getRequired: (systemPowerUsage: Application['solar_system_power_usage']) =>
      systemPowerUsage !== SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY,
  },
  // If applicant filed tax returns in the past
  taxReturnsLastYear: {
    name: 'TR' as const,
    getLabel: (borrowerLegalName: string) =>
      `Please upload ${borrowerLegalName}'s ${lastYear} tax return.`,
    getTooltip: (borrowerLegalName: string) =>
      `Please provide a copy of ${borrowerLegalName}'s tax return filed with the IRS for the ${lastYear} tax year.`,
    displayCondition: (
      filedTaxReturns: Application['applicant_filed_tax_returns_in_the_past'],
      applicantFiledLastYearTaxReturns: Application['applicant_filed_last_year_tax_returns']
    ) =>
      isYes(filedTaxReturns) &&
      ((INTERVAL_DATES().MayOct && isYes(applicantFiledLastYearTaxReturns)) ||
        INTERVAL_DATES().NovDec),
    getRequired: (systemPowerUsage: Application['solar_system_power_usage']) =>
      systemPowerUsage !== SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY,
  },
  taxReturns1Year: {
    name: 'TR1' as const,
    getLabel: (borrowerLegalName: string) =>
      `Please upload ${borrowerLegalName}'s ${yearAgo} tax return.`,
    getTooltip: (borrowerLegalName: string) =>
      `Please provide a copy of ${borrowerLegalName}'s tax return filed with the IRS for the ${yearAgo} tax year.`,
    // Render Conditionally based on whether applicant filed tax returns in the past and business established year
    displayCondition: (
      filedTaxReturns: Application['applicant_filed_tax_returns_in_the_past'],
      yearBusinessEstablished: Application['business_established_year']
    ) => filedTaxReturns === 'Yes' && Number(yearBusinessEstablished) < yearAgo,
    getRequired: (systemPowerUsage: Application['solar_system_power_usage']) =>
      systemPowerUsage !== SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY,
  },
  taxReturns2Year: {
    name: 'TR2' as const,
    getLabel: (borrowerLegalName: string) =>
      `Please upload the ${borrowerLegalName}'s ${twoYearsAgo} tax return.`,
    getTooltip: (borrowerLegalName: string) =>
      `Please provide a copy of ${borrowerLegalName}'s tax return filed with the IRS for the ${twoYearsAgo} tax year.`,
    displayCondition: (
      filedTaxReturns: Application['applicant_filed_tax_returns_in_the_past'],
      yearBusinessEstablished: Application['business_established_year']
    ) =>
      filedTaxReturns === 'Yes' &&
      Number(yearBusinessEstablished) < twoYearsAgo,
    getRequired: (systemPowerUsage: Application['solar_system_power_usage']) =>
      systemPowerUsage !== SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY,
  },
  pgTaxes1YearAgo: {
    name: 'PGTR1' as const,
    getLabel: (
      firstName: string | null | undefined,
      lastName: string | null | undefined,
      index: number
    ) =>
      `Please upload the ${pg1Year} tax return for ${
        firstName && lastName
          ? `${firstName} ${lastName}`
          : `Principal ${index}`
      }.`,
    getTooltip: () =>
      `Please provide a copy of your tax return filed with the IRS for the ${pg1Year} tax year.`,
    // Render Conditionally based on whether applicant filed tax returns in the past and business established year
    displayCondition: (
      pgFiledTaxes1YearAgo: RelatedParty['filed_taxes_1_year_ago']
    ) => pgFiledTaxes1YearAgo === 'Yes',
    getRequired: (
      pgFiledTaxes1YearAgo: RelatedParty['filed_taxes_1_year_ago']
    ) => pgFiledTaxes1YearAgo === 'Yes',
  },
  pgExtension1YearAgo: {
    name: 'PGEX1' as const,
    getLabel: (
      firstName: string | null | undefined,
      lastName: string | null | undefined,
      index: number
    ) =>
      `Please upload the ${pg1Year} tax filing extension for ${
        firstName && lastName
          ? `${firstName} ${lastName}`
          : `Principal ${index}`
      }.`,
    getTooltip: () =>
      `Please provide a copy of your tax extension filed with the IRS for the ${pg1Year} tax year.`,
    displayCondition: (
      pgFiledTaxes1YearAgo: RelatedParty['filed_taxes_1_year_ago']
    ) => pgFiledTaxes1YearAgo === 'No',
    getRequired: (
      pgFiledTaxes1YearAgo: RelatedParty['filed_taxes_1_year_ago']
    ) => pgFiledTaxes1YearAgo === 'No',
  },
  pgTaxes2YearsAgo: {
    name: 'PGTR2' as const,
    getLabel: (
      firstName: string | null | undefined,
      lastName: string | null | undefined,
      index: number
    ) =>
      `Please upload the ${pg1Year - 1} tax return for ${
        firstName && lastName
          ? `${firstName} ${lastName}`
          : `Principal ${index}`
      }.`,
    getTooltip: () =>
      `Please provide a copy of your tax return filed with the IRS for the ${
        pg1Year - 1
      } tax year.`,
    displayCondition: (
      pgFiledTaxes2YearsAgo: RelatedParty['filed_taxes_2_years_ago']
    ) => pgFiledTaxes2YearsAgo === 'Yes',
    getRequired: (
      pgFiledTaxes2YearsAgo: RelatedParty['filed_taxes_2_years_ago']
    ) => pgFiledTaxes2YearsAgo === 'Yes',
  },
  pgExtension2YearsAgo: {
    name: 'PGEX2' as const,
    getLabel: (
      firstName: string | null | undefined,
      lastName: string | null | undefined,
      index: number
    ) =>
      `Please upload the ${pg1Year - 1} tax filing extension for ${
        firstName && lastName
          ? `${firstName} ${lastName}`
          : `Principal ${index}`
      }.`,
    getTooltip: () =>
      `Please provide a copy of your tax extension filed with the IRS for the ${
        pg1Year - 1
      } tax year.`,
    displayCondition: (
      pgFiledTaxes2YearsAgo: RelatedParty['filed_taxes_2_years_ago']
    ) => pgFiledTaxes2YearsAgo === 'No',
    getRequired: (
      pgFiledTaxes2YearsAgo: RelatedParty['filed_taxes_2_years_ago']
    ) => pgFiledTaxes2YearsAgo === 'No',
  },
  pgTaxes3YearsAgo: {
    name: 'PGTR3' as const,
    getLabel: (
      firstName: string | null | undefined,
      lastName: string | null | undefined,
      index: number
    ) =>
      `Please upload the ${pg1Year - 2} tax return for ${
        firstName && lastName
          ? `${firstName} ${lastName}`
          : `Principal ${index}`
      }.`,
    getTooltip: () =>
      `Please provide a copy of your tax return filed with the IRS for the ${
        pg1Year - 2
      } tax year.`,
    displayCondition: (
      pgFiledTaxes3YearsAgo: RelatedParty['filed_taxes_3_years_ago']
    ) => pgFiledTaxes3YearsAgo === 'Yes',
    getRequired: (
      pgFiledTaxes3YearsAgo: RelatedParty['filed_taxes_3_years_ago']
    ) => pgFiledTaxes3YearsAgo === 'Yes',
  },
  pgExtension3YearsAgo: {
    name: 'PGEX3' as const,
    getLabel: (
      firstName: string | null | undefined,
      lastName: string | null | undefined,
      index: number
    ) =>
      `Please upload the ${pg1Year - 2} tax filing extension for ${
        firstName && lastName
          ? `${firstName} ${lastName}`
          : `Principal ${index}`
      }.`,
    getTooltip: () =>
      `Please provide a copy of your tax extension filed with the IRS for the ${
        pg1Year - 2
      } tax year.`,
    displayCondition: (
      pgFiledTaxes3YearsAgo: RelatedParty['filed_taxes_3_years_ago']
    ) => pgFiledTaxes3YearsAgo === 'No',
    getRequired: (
      pgFiledTaxes3YearsAgo: RelatedParty['filed_taxes_3_years_ago']
    ) => pgFiledTaxes3YearsAgo === 'No',
  },
  financialStatementLastYearQ3: {
    name: 'FinancialStatementLastYearQ3' as const,
    getLabel: (borrowerLegalName: string) =>
      `Please upload ${borrowerLegalName}'s ${lastYear} financial statements (P&L and balance sheet) covering at a minimum ${lastYear} Q3.`,
    getTooltip: () =>
      'Financial statements should include an income statement and balance sheet.',
    getRequired: (systemPowerUsage: Application['solar_system_power_usage']) =>
      systemPowerUsage !== SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY,
    displayCondition: (
      filedTaxReturns: Application['applicant_filed_tax_returns_in_the_past']
    ) => isYes(filedTaxReturns) && INTERVAL_DATES().JanApr,
  },
  financialStatementQ1: {
    name: 'FinancialStatementQ1' as const,
    getLabel: (borrowerLegalName: string) =>
      `Please upload ${borrowerLegalName}'s ${currentYear} year-to-date financial statements (P&L and balance sheet) up to ${currentYear} Q1.`,
    getTooltip: () =>
      'Financial statements should include an income statement and balance sheet.',
    getRequired: (systemPowerUsage: Application['solar_system_power_usage']) =>
      systemPowerUsage !== SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY,
    displayCondition: (isPersonalGuarantor: boolean) =>
      isPersonalGuarantor === false && INTERVAL_DATES().MidMaytoMidAug,
  },
  financialStatementQ2: {
    name: 'FinancialStatementQ2' as const,
    getLabel: (borrowerLegalName: string) =>
      `Please upload ${borrowerLegalName}'s ${currentYear} year-to-date financial statements (P&L and balance sheet) up to ${currentYear} Q2.`,
    getTooltip: () =>
      'Financial statements should include an income statement and balance sheet.',
    getRequired: (systemPowerUsage: Application['solar_system_power_usage']) =>
      systemPowerUsage !== SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY,
    displayCondition: (isPersonalGuarantor: boolean) =>
      isPersonalGuarantor === false && INTERVAL_DATES().MidAugtoMidNov,
  },
  financialStatementQ3: {
    name: 'FinancialStatementQ3' as const,
    getLabel: (borrowerLegalName: string) =>
      `Please upload ${borrowerLegalName}'s ${currentYear} year-to-date financial statements (P&L and balance sheet) up to ${currentYear} Q3.`,
    getTooltip: () =>
      'Financial statements should include an income statement and balance sheet.',
    getRequired: (systemPowerUsage: Application['solar_system_power_usage']) =>
      systemPowerUsage !== SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY,
    displayCondition: (isPersonalGuarantor: boolean) =>
      isPersonalGuarantor === false && INTERVAL_DATES().MidNovDec,
  },
  financialStatementFullYear: {
    name: 'FinancialStatementFullYear' as const,
    getLabel: (borrowerLegalName: string) =>
      `Please upload ${borrowerLegalName}'s full-year ${lastYear} financial statements (P&L and balance sheet)`,
    getTooltip: () =>
      'Financial statements should include an income statement and balance sheet.',
    getRequired: (systemPowerUsage: Application['solar_system_power_usage']) =>
      systemPowerUsage !== SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY,
    displayCondition: (
      filedTaxReturns: Application['applicant_filed_tax_returns_in_the_past'],
      applicantFiledLastYearTaxReturns: Application['applicant_filed_last_year_tax_returns']
    ) =>
      isYes(filedTaxReturns) &&
      isNo(applicantFiledLastYearTaxReturns) &&
      INTERVAL_DATES().MayOct,
  },
};

Object.keys(loanFormModel.formField).forEach((key) => {
  if (
    !loanFormModel.formField[key]?.required &&
    !loanFormModel.formField[key]?.getRequired &&
    loanFormModel.formField[key]?.label
  ) {
    loanFormModel.formField[key].label =
      loanFormModel.formField[key].label + ' (Optional)';
  }
});

Object.keys(loanFormModel.formField.documents).forEach((key) => {
  if (
    !loanFormModel.formField.documents[key]?.required &&
    loanFormModel.formField.documents[key]?.label
  ) {
    loanFormModel.formField.documents[key].label =
      loanFormModel.formField.documents[key].label + ' (Optional)';
  }
});

loanFormModel.formField;

export default loanFormModel;
