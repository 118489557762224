import { Box } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { CustomTooltip } from '../components/CustomTooltip';
import { InfoIcon } from '../components/loan_application/icons';

const useLabelWithTooltip = (label: ReactNode, tooltip: ReactNode) => {
  return useMemo(() => {
    return label && tooltip ? (
      <Box component="span" display="inline" alignItems="center">
        {label}
        {tooltip && (
          <CustomTooltip title={tooltip}>
            <InfoIcon
              sx={{
                fontSize: '14px',
                // marginTop: '.175rem',
                mx: '.25rem',
                verticalAlign: '-.15rem',
              }}
            />
          </CustomTooltip>
        )}
      </Box>
    ) : (
      label
    );
  }, [label, tooltip]);
};

export default useLabelWithTooltip;
