import {
  useQuery,
  useMutation,
  useQueryClient,
  UseMutationOptions,
  UseQueryOptions,
} from 'react-query';
import {
  Application,
  Document,
  InstallerDashboardSummary,
  InstallerProjectSummary,
  RequiredDocuments,
  PaginatedResult,
} from '../../../../types/api';
import {
  getPaginatedInstallerApplications,
  closeApplication,
  getDocuments,
  uploadApplicationDocument,
  getInstallerDashboardSummary,
  getInstallerProjectSummary,
  InstallerApplicationsParams,
} from '../services/apis/InstallerAPIs';

export const INSTALLER_APPS_QUERY = 'installerApplications';

type InstallerApplicationsKey = [
  typeof INSTALLER_APPS_QUERY,
  InstallerApplicationsParams
];
const getInstallerApplicationsKey = (
  params: InstallerApplicationsParams
): InstallerApplicationsKey => [INSTALLER_APPS_QUERY, params];
export const useInstallerApplications = (
  params: InstallerApplicationsParams,
  options: UseQueryOptions<
    PaginatedResult<Application>,
    unknown,
    PaginatedResult<Application>,
    InstallerApplicationsKey
  > = {}
) =>
  useQuery(
    getInstallerApplicationsKey(params),
    (context) => getPaginatedInstallerApplications(context.queryKey[1]),
    options
  );

type InstallerDashboardSummaryKey = [typeof INSTALLER_APPS_QUERY, 'summary'];
export const useInstallerDashboardSummary = (
  options?: UseQueryOptions<
    InstallerDashboardSummary,
    unknown,
    InstallerDashboardSummary,
    InstallerDashboardSummaryKey
  >
) => {
  return useQuery(
    [INSTALLER_APPS_QUERY, 'summary'] as InstallerDashboardSummaryKey,
    getInstallerDashboardSummary,
    {
      ...options,
    }
  );
};

type InstallerProjectSummaryKey = [
  typeof INSTALLER_APPS_QUERY,
  'summary/projects'
];
export const useInstallerProjectSummary = (
  options?: UseQueryOptions<
    InstallerProjectSummary,
    unknown,
    InstallerProjectSummary,
    InstallerProjectSummaryKey
  >
) => {
  return useQuery(
    [INSTALLER_APPS_QUERY, 'summary/projects'] as InstallerProjectSummaryKey,
    getInstallerProjectSummary,
    {
      ...options,
    }
  );
};

type InstallerApplicationMutations = 'remove';
type InstallerApplicationMutationKey = [
  typeof INSTALLER_APPS_QUERY,
  InstallerApplicationMutations
];
const getInstallerApplicationMutationKey = (
  mutation: InstallerApplicationMutations
): InstallerApplicationMutationKey => [INSTALLER_APPS_QUERY, mutation];

export const useCloseInstallerApplication = () => {
  const queryClient = useQueryClient();
  return useMutation(
    getInstallerApplicationMutationKey('remove'),
    closeApplication,
    {
      onSuccess: async () => {
        await queryClient.cancelQueries(INSTALLER_APPS_QUERY);
        queryClient.invalidateQueries(INSTALLER_APPS_QUERY);
        queryClient.invalidateQueries(['projects']);
      },
    }
  );
};

export const useInstallerApplicationDocuments = (
  applicationId,
  options = {}
) => {
  return useQuery(
    [INSTALLER_APPS_QUERY, applicationId, 'documents'],
    () => {
      return getDocuments(applicationId);
    },
    options
  );
};

type InstallerApplicationDocumentMutations = 'upload';
type InstallerApplicationDocumentMutationKey = [
  typeof INSTALLER_APPS_QUERY,
  'documents',
  InstallerApplicationDocumentMutations
];
const getInstallerApplicationDocumentMutationKey = (
  mutationType: InstallerApplicationDocumentMutations
): InstallerApplicationDocumentMutationKey => [
  INSTALLER_APPS_QUERY,
  'documents',
  mutationType,
];
type UpdateInstallerDocumentsVariables = {
  applicationId: string;
  data: FormData;
  documentType: keyof RequiredDocuments;
  filename: string;
};
export const useUpdateInstallerDocuments = (
  options: UseMutationOptions<
    Document,
    unknown,
    UpdateInstallerDocumentsVariables,
    unknown
  > = {}
) => {
  const queryClient = useQueryClient();

  return useMutation<
    Document,
    unknown,
    UpdateInstallerDocumentsVariables,
    unknown
  >(
    getInstallerApplicationDocumentMutationKey('upload'),
    ({ applicationId, data }) => {
      return uploadApplicationDocument(applicationId, data);
    },
    {
      onSuccess: (
        uploadedDocument,
        { applicationId, documentType, filename }
      ) => {
        queryClient.setQueryData<RequiredDocuments>(
          [INSTALLER_APPS_QUERY, applicationId, 'documents'],
          // On success, update the cached query results with the new document
          (oldData) => {
            const updates: Partial<RequiredDocuments> = {
              [documentType]: {
                ...uploadedDocument,
                name: filename,
              },
            };
            if (!oldData) return oldData as unknown as RequiredDocuments;
            return {
              ...oldData,
              ...updates,
            } as RequiredDocuments;
          }
        );
      },
      ...options,
    }
  );
};
