import { Typography } from '@mui/material';
import { FC } from 'react';

const NetFundingTooltipText: FC<Record<string, never>> = () => {
  return (
    <Typography textAlign={'left'}>
      Net Funding is the amount that will be remitted to you, the installer, by
      Sunstone Credit after dealer fees have been applied and all milestone
      requirements have been achieved. This number assumes 90%/10% payment terms
      (i.e., all payments are made after Substantial Completion) and no
      additional discounts or fees apply.
    </Typography>
  );
};

export default NetFundingTooltipText;
