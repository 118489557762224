import React, { forwardRef, useState } from 'react';
import {
  Tooltip as MuiTooltip,
  TooltipProps,
  ClickAwayListener,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Tracker } from '../utils/tracker';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.input,
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(16),
    borderRadius: '.75rem',
    border: '1px solid rgba(219, 224, 233, 1)',
    padding: '.75rem .875rem',
    textAlign: 'center',
  },
  arrow: {
    color: theme.palette.background.input,
    '&::before': {
      border: '1px solid rgba(219, 224, 233, 1)',
    },
  },
}));

export const CustomTooltip = forwardRef<
  HTMLElement,
  TooltipProps & { event?: string }
>(function CustomTooltip({ event, ...restProps }, ref) {
  // Had to separate trackerData props from the previous '...props' because otherwise it would pass trackerData into a DOM element, which is not recognized by React
  const classes = useStyles();
  const isTouchDevice = useMediaQuery('(hover: none)');

  const tracker = Tracker.getInstance();

  // State to manage the tooltip's open status
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    if (event) {
      // Check if trackerData is provided
      tracker.track(event);
    }
    setOpen(true);
  };

  if (isTouchDevice) {
    // For mobile, show the tooltip on click
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <MuiTooltip
            classes={classes}
            ref={ref}
            open={open}
            onClose={handleTooltipClose}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            {...restProps}
          >
            {/* Clone the child element to attach the onClick handler */}
            {React.cloneElement(restProps.children, {
              onClick: handleTooltipOpen,
            })}
          </MuiTooltip>
        </div>
      </ClickAwayListener>
    );
  } else {
    // Desktop version, we use default behavior
    return (
      <MuiTooltip
        classes={classes}
        ref={ref}
        arrow
        onMouseEnter={handleTooltipOpen} // Add tracking on mouse enter
        onClose={handleTooltipClose}
        {...restProps}
      >
        {restProps.children}
      </MuiTooltip>
    );
  }
});
