import * as Yup from 'yup';
import {
  businessIdentification,
  businessIdentificationSchema,
  ownershipHistory,
  ownershipHistorySchema,
  principals,
  principalsSchema,
  solarSystemProperty,
  solarSystemPropertySchema,
  systemPower,
  systemPowerSchema,
} from '../../../components/loan_application/form_models/stage1ValidationSchema';
import {
  bankStatements,
  bankStatementsValidationSchema,
  consentValidation,
  consentValidationSchema,
  documentsValidation,
  documentsValidationSchema,
  outstandingLoans,
  outstandingLoansSchema,
} from '../../../components/loan_application/form_models/stage2ValidationSchema';
import { ApplicationGroups, ApplicationStepID } from './LoanDetailTypes';

export type ApplicationStep = {
  id: ApplicationStepID;
  title: string;
  dependsOn?: ApplicationStepID[];
  validationSchema?: Yup.ObjectSchema<any>;
  fields?: Record<string, any>;
  group: ApplicationGroups;
  index: number;
};

const reviewValidationSchema = businessIdentificationSchema
  .concat(solarSystemPropertySchema)
  .concat(ownershipHistorySchema)
  .concat(principalsSchema)
  .concat(documentsValidationSchema)
  .concat(outstandingLoansSchema)
  .concat(systemPowerSchema);

const reviewFields = {
  ...businessIdentification,
  ...solarSystemProperty,
  ...ownershipHistory,
  ...principals,
  ...documentsValidation,
  ...outstandingLoans,
  ...systemPower,
};

export const fullValidationSchema = reviewValidationSchema.concat(
  consentValidationSchema
);

export const applicationSteps: ApplicationStep[] = [
  {
    id: ApplicationStepID.CONTACT_INFO,
    title: 'Borrower Information',
    validationSchema: businessIdentificationSchema,
    fields: businessIdentification,
    group: ApplicationGroups.APP_INFO,
  },
  {
    id: ApplicationStepID.SYSTEM_LOCATION,
    title: 'Solar System Location',
    validationSchema: solarSystemPropertySchema,
    fields: solarSystemProperty,
    group: ApplicationGroups.APP_INFO,
  },
  {
    id: ApplicationStepID.SYSTEM_POWER,
    title: 'Solar System Power',
    fields: systemPower,
    group: ApplicationGroups.APP_INFO,
    validationSchema: systemPowerSchema,
  },
  {
    id: ApplicationStepID.OWNERSHIP_HISTORY,
    title: 'Business Structure and History',
    validationSchema: ownershipHistorySchema,
    fields: ownershipHistory,
    group: ApplicationGroups.APP_INFO,
  },
  {
    id: ApplicationStepID.PRINCIPALS,
    title: 'Principals Information',
    validationSchema: principalsSchema,
    fields: principals,
    group: ApplicationGroups.APP_INFO,
  },
  {
    id: ApplicationStepID.REQUIRED_DOCUMENTS,
    title: 'Required Documents',
    validationSchema: documentsValidationSchema,
    fields: documentsValidation,
    group: ApplicationGroups.DOC_UPLOADS,
  },
  {
    id: ApplicationStepID.BANK_STATEMENTS,
    title: 'Bank Statements',
    fields: bankStatements,
    group: ApplicationGroups.DOC_UPLOADS,
    validationSchema: bankStatementsValidationSchema,
  },
  {
    id: ApplicationStepID.OUTSTANDING_LOANS,
    title: 'Material Loan Obligations',
    validationSchema: outstandingLoansSchema,
    fields: outstandingLoans,
    group: ApplicationGroups.DOC_UPLOADS,
  },
  {
    id: ApplicationStepID.REVIEW,
    title: 'Review',
    validationSchema: reviewValidationSchema,
    fields: reviewFields,
    dependsOn: [
      ApplicationStepID.CONTACT_INFO,
      ApplicationStepID.SYSTEM_LOCATION,
      ApplicationStepID.SYSTEM_POWER,
      ApplicationStepID.OWNERSHIP_HISTORY,
      ApplicationStepID.PRINCIPALS,
      ApplicationStepID.REQUIRED_DOCUMENTS,
      ApplicationStepID.BANK_STATEMENTS,
      ApplicationStepID.OUTSTANDING_LOANS,
    ] as ApplicationStepID[],
    group: ApplicationGroups.DOC_UPLOADS,
  },
  {
    id: ApplicationStepID.CONSENT,
    title: 'Sign and Consent',
    validationSchema: businessIdentificationSchema
      .concat(solarSystemPropertySchema)
      .concat(ownershipHistorySchema)
      .concat(principalsSchema)
      .concat(documentsValidationSchema)
      .concat(outstandingLoansSchema)
      .concat(systemPowerSchema)
      .concat(consentValidationSchema),
    fields: {
      ...businessIdentification,
      ...solarSystemProperty,
      ...ownershipHistory,
      ...principals,
      ...documentsValidation,
      ...outstandingLoans,
      ...systemPower,
      ...consentValidation,
    },
    dependsOn: [ApplicationStepID.REVIEW] as ApplicationStepID[],
    group: ApplicationGroups.DOC_UPLOADS,
  },
].map((step, index) => ({ ...step, index }));

export const applicationStepsByIDs = applicationSteps.reduce(
  (acc, step) => ({ ...acc, [step.id]: step }),
  {} as {
    [key in ApplicationStepID]: ApplicationStep;
  }
);

export const applicationInfoSteps = applicationSteps.filter(
  (step) => step.group === ApplicationGroups.APP_INFO
);
export const documentUploadSteps = applicationSteps.filter(
  (step) => step.group === ApplicationGroups.DOC_UPLOADS
);
