import { useCallback, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
  CircularProgress,
} from '@mui/material';
import Logo from '../../components/logos/Logo';
import { authApi } from '../../services/apis/AuthAPIs';

const VerificationRequired = () => {
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const resendVerificationEmail = useCallback(async () => {
    try {
      setIsSending(true);
      const data = await authApi.resendVerificationEmail();
      setIsSending(false);
      setIsSent(true);
    } catch (err) {
      setIsSending(false);
      setIsSent(false);
      setErrorMessage(err.message);
      console.error(err);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Verification Required | Sunstone Credit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="sm" sx={{ py: 10 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <a href={__landingUrl__}>
              <Logo height={40} width={40} />
            </a>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8,
            }}
          />
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3,
                }}
              >
                <Typography color="textPrimary" gutterBottom variant="h5">
                  Email verification is required
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3,
                  alignItems: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Button
                  variant="contained"
                  onClick={resendVerificationEmail}
                  disabled={isSending || isSent}
                >
                  Send Verification Email
                </Button>
                {isSending && <CircularProgress />}
                {isSent && <Typography>Please check your email.</Typography>}
                {errorMessage && (
                  <Typography color="error">{errorMessage}</Typography>
                )}
              </Box>
              <Divider sx={{ my: 3 }} />
              <Link
                color="textSecondary"
                component={RouterLink}
                to=""
                variant="body2"
              >
                Did you not receive an email?
              </Link>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default VerificationRequired;
