import { Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { InputField } from '../../form_fields';
import * as Yup from 'yup';
import useAuth from '../../../hooks/useAuth';
import { useMemo, useState } from 'react';
import noticeError from '../../../utils/errors/noticeError';

const mask = '\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022\u2022';

const BankingValidationSchema = Yup.object().shape({
  installer_bank_account_name: Yup.string()
    .label('Account Name')
    .required('Account Name is required')
    .max(22, 'Must be at most 22 characters'),
  installer_bank_name: Yup.string()
    .label('Bank Name')
    .required('Bank Name is required')
    .max(255, 'Must be at most 255 characters'),
  installer_bank_account_number: Yup.string()
    .label('Account Number')
    .required('Account Number is required')
    .test('Only Numbers', `Only Enter Numbers`, (value) =>
      /^[0-9]*$/.test(value ?? '')
    )
    .max(255, 'Must be at most 255 characters'),
  installer_bank_routing_number: Yup.string()
    .label('ACH Routing Number')
    .required('ACH Routing Number is required')
    .test('Only Numbers', `Only Enter Numbers`, (value) =>
      /^[0-9]*$/.test(value ?? '')
    )
    .min(9, 'Must be at least 9 characters')
    .max(9, 'Must be at most 9 characters'),
  location_of_bank: Yup.string()
    .label('Bank Address')
    .required('Bank Address is required')
    .max(255, 'Must be at most 255 characters'),
});

export const BankingForm = () => {
  const { account, updateAccountData } = useAuth();
  let [isSubmitted, setIsSubmitted] = useState(false);

  const {
    installer_bank_account_name,
    installer_bank_account_number,
    installer_bank_name,
    installer_bank_routing_number,
    location_of_bank,
  } = account;

  const isBankAcountInfoFilled = Boolean(
    installer_bank_account_name &&
      installer_bank_account_number &&
      installer_bank_name &&
      installer_bank_routing_number &&
      location_of_bank
  );

  const initialValues = useMemo(() => {
    return isBankAcountInfoFilled
      ? {
          installer_bank_account_name: installer_bank_account_name,
          installer_bank_name: installer_bank_name,
          installer_bank_account_number: mask.concat(
            installer_bank_account_number
          ),
          installer_bank_routing_number: mask.concat(
            installer_bank_routing_number
          ),
          location_of_bank: location_of_bank,
        }
      : {
          installer_bank_account_name: '',
          installer_bank_account_number: '',
          installer_bank_name: '',
          installer_bank_routing_number: '',
          location_of_bank: '',
        };
  }, [
    installer_bank_account_name,
    installer_bank_account_number,
    installer_bank_name,
    installer_bank_routing_number,
    isBankAcountInfoFilled,
    location_of_bank,
  ]);

  async function _handleSubmit(values, formikBag) {
    try {
      await updateAccountData(values);
      toast.success('Banking Information Updated!');
      setIsSubmitted(true);
    } catch (err) {
      noticeError(err);
      if (err.data) formikBag.setErrors(err.data);
      toast.error('Something went wrong!');
    }
  }

  return (
    <Formik
      validationSchema={BankingValidationSchema}
      initialValues={initialValues}
      enableReinitialize
      onSubmit={_handleSubmit}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          <Grid container rowSpacing={2.25} columnSpacing={3}>
            <Grid item xs={12}>
              <InputField
                label="Account Name"
                fullWidth
                disabled={isSubmitted || isBankAcountInfoFilled}
                name={'installer_bank_account_name'}
                max={22}
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label="Bank Name"
                fullWidth
                disabled={isSubmitted || isBankAcountInfoFilled}
                name={'installer_bank_name'}
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label="Account Number"
                fullWidth
                disabled={isSubmitted || isBankAcountInfoFilled}
                name={'installer_bank_account_number'}
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label="ACH Routing Number"
                fullWidth
                disabled={isSubmitted || isBankAcountInfoFilled}
                name={'installer_bank_routing_number'}
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <InputField
                label="Bank Address"
                fullWidth
                disabled={isSubmitted || isBankAcountInfoFilled}
                name={'location_of_bank'}
                type="text"
              />
            </Grid>
            <Grid item container xs={12}>
              <LoadingButton
                type="submit"
                variant="contained"
                disabled={!dirty || !isValid || isSubmitted}
                loading={isSubmitting}
                sx={{ ml: 'auto' }}
              >
                Save Changes
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
