import { EXTERNAL_ACTIONS } from '../sunstone_credit/frontend/src/constants';
import { WithNonNullableKeys } from './utility';

// enums

export enum AccountAccountSource {
  ADVERTISEMENT = 'ADVERTISEMENT',
  CUSTOMER_EVENT = 'CUSTOMER_EVENT',
  EMPLOYEE_REFERRAL = 'EMPLOYEE_REFERRAL',
  EXTERNAL_REFERRAL = 'EXTERNAL_REFERRAL',
  GOOGLE_ADWORDS = 'GOOGLE_ADWORDS',
  OTHER = 'OTHER',
  PARTNER = 'PARTNER',
  PURCHASED_LIST = 'PURCHASED_LIST',
  TRADE_SHOW = 'TRADE_SHOW',
  WEBINAR = 'WEBINAR',
  WEBSITE = 'WEBSITE',
}

export enum AccountBusinessStructure {
  LLC = 'LLC',
  S_CORP = 'S-Corp',
  C_CORP = 'C-Corp',
  GENERAL_PARTNERSHIP = 'General Partnership',
  SOLE_PROPRIETORSHIP = 'Sole Proprietorship',
  NON_PROFIT = 'Non-Profit',
  HOA = 'HOA',
}

enum AccountLaunchStatus {
  APPROVED = 'Approved',
  SIGNED = 'Signed',
  LAUNCHED = 'Launched',
}

enum AccountPricingProfile {
  NORMAL = 'Normal',
  CED = 'CED',
  CUSTOM = 'Custom',
}

export enum AccountType {
  BORROWER = 'Borrower',
  CONSULTANT = 'Consultant',
  INSTALLER = 'Installer',
}

export enum AdvancedFundingApprovalStatus {
  APPROVED = 'Approved',
  APPLICATION_IN_PROGRESS = 'Application In-Progress',
  DECLINED = 'Declined',
  PRE_QUALIFIED = 'Pre-Qualified',
  ELIGIBLE = 'Eligible',
  NOT_ELIGIBLE = 'Not Eligible',
}

export enum DocumentApprovalSource {
  DOCUSIGN = 'DOCUSIGN',
  UPLOAD = 'UPLOAD',
}

export enum DocumentApprovalStatus {
  VERIFIED = 'VERIFIED',
  NEEDS_REVIEW = 'NEEDS_REVIEW',
  ISSUES_IDENTIFIED = 'ISSUES_IDENTIFIED',
  MISSING = 'MISSING',
}
export enum FileUploadSource {
  ROSEFIELD = 'ROSEFIELD',
  RAILWAY = 'RAILWAY',
}

export enum ProductFamily {
  STANDARD = 'No Special Payment (no ITC)',
  SPECIAL_PAYMENT = 'Special Payment (ITC)',
  PREMIUM = 'Premium',
  ELECTIVE_PAY = 'Elective Pay',
}

export enum LoanCategory {
  EXPRESS = 'Express',
  EXPRESS_PLUS = 'Express+',
  CORE = 'Core',
}

// types

export type ApplicationExternalAction =
  | 'Begin Application'
  | 'Finish Application'
  | 'Upload Documents'
  | 'Provide Additional Information'
  | 'Under Review'
  | 'Sign Loan Package'
  | 'Loan Processing'
  | 'Loan Approved'
  | 'Loan Declined'
  | 'Canceled';

export type ChangeOrderDocumentTypes = keyof Pick<
  RequiredDocuments,
  'Change Order Contract' | 'New Solar Proposal'
>;

export type Document = {
  name: string;
  url: string;
  last_modified_date: string;
  id: SFId;
};

export type ExternalStatusAction =
  | 'Application Created'
  | 'Application In Progress'
  | 'Application Submitted'
  | 'Loan Package'
  | 'Complete';

export type FeeByProductFamily = {
  [key in RateSheetFee['product_family']]?: RateSheetFee[];
};

export type FundedProjectMessage = {
  type: 'info' | 'warning' | 'error';
  message: string;
};

export type InstallerDashboardSummary = {
  total_applications: number;
  total_applicants: number;
  completed_applications: number;
  active_applications: number;
};

export type InstallerProjectSummary = {
  total_paid_to_date: number;
  net_fundings: number;
  projects_in_progress: number;
  completed_projects: number;
};

export type NonUniqueRequiredDocuments =
  | 'Proof of Offtaker Creditworthiness'
  | 'Offtaker Utility Bill'
  | 'Offtaker PPA or Lease Agreement'
  | 'Community Solar Financial Projections'
  | 'Additional Document';

export enum PlaidAssetReportStatus {
  'SUCCESS' = 'SUCCESS',
  'FAILED' = 'FAILED',
  'PENDING' = 'PENDING',
  'DOES_NOT_EXIST' = 'DOES_NOT_EXIST',
}

export type PlaidAssetReport = {
  asset_report_status: PlaidAssetReportStatus;
  institution: null | string;
  masks: null | string[];
};

export type RateSheetFee = WithNonNullableKeys<
  Fee,
  | 'dealer_fee'
  | 'interest_rate'
  | 'pricing_grouping'
  | 'product_family'
  | 'loan_term_years'
  | 'amortization_years'
>;

export type RequiredDocuments = {
  plaid_asset_report?: PlaidAssetReport;
  self_prepared_financials?: SunstoneDocument;
  TR: SunstoneDocument;
  TR1: SunstoneDocument;
  TR2: SunstoneDocument;
  FIN?: SunstoneDocument;
  BIZ?: SunstoneDocument;
  BS: SunstoneDocument;
  BS1: SunstoneDocument;
  BS2: SunstoneDocument;
  OWN: SunstoneDocument;
  PGTR1: SunstoneDocument;
  PGEX1: SunstoneDocument;
  PGTR2: SunstoneDocument;
  PGEX2: SunstoneDocument;
  PGTR3: SunstoneDocument;
  PGEX3: SunstoneDocument;
  PRINCIPAL_ID?: SunstoneDocument;
  'Articles of Organization/Incorporation'?: SunstoneDocument;
  'Non-Profit Board Resolution'?: SunstoneDocument;
  'Non-Profit Bylaws'?: SunstoneDocument;
  'Property Company Rent Roll'?: SunstoneDocument;
  'Lease of the property where the system will be installed'?: SunstoneDocument;
  'Proof of Ownership of the property where the system will be installed'?: SunstoneDocument;
  'Offtaker PPA or Lease Agreement'?: SunstoneDocument[];
  'Proof of Offtaker Creditworthiness'?: SunstoneDocument[];
  'Offtaker Lease or Proof of Ownership'?: SunstoneDocument;
  'Offtaker Utility Bill'?: SunstoneDocument[];
  'PPA/Lease Financial Projections'?: SunstoneDocument;
  'Subscriber Management Company Contract'?: SunstoneDocument;
  'Community Solar Financial Projections'?: SunstoneDocument[];
  'Approval for Community Solar Delivery'?: SunstoneDocument;
  'Financial Statements Current Year'?: SunstoneDocument;
  'Financial Statements Last Year'?: SunstoneDocument;
  'Financial Statements One Year Ago'?: SunstoneDocument;
  'Financial Statements Two Years Ago'?: SunstoneDocument;
  'IRS Form SS-4'?: SunstoneDocument;
  'Tax Returns Extension (Form 7004)'?: SunstoneDocument;
  'Debt Schedule'?: SunstoneDocument;
  // Change order documents
  'Change Order Contract'?: SunstoneDocument;
  'New Solar Proposal'?: SunstoneDocument;
  // Religious Organization documents
  'Religious Organization Attendance'?: SunstoneDocument;
  'Religious Organization Donations'?: SunstoneDocument;
  // HOA Documents
  'Member Assessment Policy'?: SunstoneDocument;
  'Operating and Reserve Budgets'?: SunstoneDocument;
  'Current Occupant Ledger'?: SunstoneDocument;
  'Meeting Minutes'?: SunstoneDocument;
  'HOA Bylaws'?: SunstoneDocument;
  'Property Company Mortgage Statement'?: SunstoneDocument;
  'Mortgage Statement'?: SunstoneDocument;
  'Additional Document'?: SunstoneDocument[];
  FinancialStatementQ1: SunstoneDocument;
  FinancialStatementQ2: SunstoneDocument;
  FinancialStatementQ3: SunstoneDocument;
  FinancialStatementLastYearQ3: SunstoneDocument;
  FinancialStatementFullYear: SunstoneDocument;
};

export type SFAutoFields = keyof SFObject;

export type SFDate = string;

export type SFId = string;

type SunstoneDocumentTypes =
  | 'Additional Document'
  | 'Tax Returns Last Year'
  | 'Tax Returns 1 Year Ago'
  | 'Tax Returns 2 Years Ago'
  | 'Interim Financial Statement'
  | 'Bank Statement Last Month'
  | 'Bank Statement 1 Month Ago'
  | 'Bank Statement 2 Months Ago'
  | 'Ownership Document'
  | 'Asset Report';

export type USState =
  | 'Alabama'
  | 'Alaska'
  | 'Arizona'
  | 'Arkansas'
  | 'California'
  | 'Colorado'
  | 'Connecticut'
  | 'Delaware'
  | 'Florida'
  | 'Georgia'
  | 'Hawaii'
  | 'Idaho'
  | 'Illinois'
  | 'Indiana'
  | 'Iowa'
  | 'Kansas'
  | 'Kentucky'
  | 'Louisiana'
  | 'Maine'
  | 'Maryland'
  | 'Massachusetts'
  | 'Michigan'
  | 'Minnesota'
  | 'Mississippi'
  | 'Missouri'
  | 'Montana'
  | 'Nebraska'
  | 'Nevada'
  | 'New Hampshire'
  | 'New Jersey'
  | 'New Mexico'
  | 'New York'
  | 'North Carolina'
  | 'North Dakota'
  | 'Ohio'
  | 'Oklahoma'
  | 'Oregon'
  | 'Pennsylvania'
  | 'Rhode Island'
  | 'South Carolina'
  | 'South Dakota'
  | 'Tennessee'
  | 'Texas'
  | 'Utah'
  | 'Vermont'
  | 'Virginia'
  | 'Washington'
  | 'Washington, D.C.'
  | 'West Virginia'
  | 'Wisconsin'
  | 'Wyoming';

export type UtilityAPIConnectionStatus =
  | 'PENDING_AUTHORIZATION'
  | 'UPDATING'
  | 'BILLS_COLLECTED'
  | 'ERROR';

export type UtilityAPIConnection = {
  application_id: string;
  auth_form_url: string;
  status: UtilityAPIConnectionStatus;
  bills: Record<string, any> | null;
  error_message?: string | null;
  referral?: string;
  authorization_uid?: string;
  meter_uid?: string;
};

export type YesNo = 'Yes' | 'No';

export type YesNoUppercase = 'YES' | 'NO';

// interfaces

export type APIError = {
  /**Error description coming from the API */
  detail: string;
  /**If incoming error is a validation error, this will be a list or a dictionary of normalized errors. */
  errors?: Record<string, any>;
};

export interface Account {
  id: string;
  account_domain_url: string;
  account_number: number;
  account_source: AccountAccountSource;
  anticipated_annual_loan_volume?: number;
  borrower_contact?: Contact;
  borrower_contact_email: string;
  borrower_contact_name: string;
  business_name: string;
  business_phone: string;
  business_structure: AccountBusinessStructure;
  created_date: Date;
  custom_pricing_current_rates_pdf?: string;
  custom_pricing_future_rates_pdf?: string;
  dba_if_applicable?: string;
  description: string;
  ein: number;
  encrypted_data: string;
  installer: string;
  installer_account: number;
  installer_advance_funding?: AdvancedFundingApprovalStatus;
  installer_advance_funding_limit?: number;
  installer_bank_account_name?: string;
  installer_bank_account_number?: string;
  installer_bank_name?: string;
  installer_bank_routing_number?: string;
  installer_business_name: string;
  installer_contact?: Contact;
  installer_contact_email: string;
  installer_contact_first_name: string;
  installer_contact_last_name?: string;
  installer_contact_name: string;
  installer_contact_phone?: string;
  installer_contact_position_title?: string;
  installer_current_rates_calculator?: string;
  installer_current_rates_calculator_pdf?: string;
  installer_dba_if_applicable: string;
  installer_ein: number;
  installer_future_rates_calculator?: string;
  installer_future_rates_calculator_pdf?: string;
  installer_liaison_email: string;
  installer_liaison_first_name: string;
  installer_liaison_last_name: string;
  installer_liaison_phone: string;
  installer_liaison_position_title: string;
  installer_license_number: number;
  installer_phone: string;
  installer_programs: InstallerProgram[];
  installer_rates_change_date?: string;
  installer_tin: number;
  installer_website: string;
  jigsaw: string;
  jigsaw_company_id: string;
  last_activity_date: Date;
  last_modified_date: Date;
  last_referenced_date: Date;
  last_viewed_date: Date;
  launch_status?: AccountLaunchStatus;
  liaison_position_title: string;
  license_number: number;
  license_type: string;
  llc_s_corp_c_corp_general_partnership: number;
  location_of_bank?: string;
  master_record: number;
  milestone_program?: string;
  name_of_bank: string;
  net_income_annual: number;
  number_of_employees: number;
  owner: User;
  phone: string;
  pricing_calculator_current?: string;
  pricing_calculator_future?: string;
  greentech_direct_pay?: string;
  pricing_profile?: AccountPricingProfile;
  products: InstallerProduct[];
  record_type: number;
  routing_number: number;
  sic_desc: string;
  system_modstamp: Date;
  type: AccountType;
  tin: number;
  website: string;
}

export interface Application {
  id?: any;
  loan_product: InstallerProduct;
  installer_program: InstallerProgram;
  loan_status_tasks?: any;
  principals?: RelatedParty[];
  is_personal_guarantor: 'Yes' | 'No' | null;
  outstanding_loans?: MaterialLoanObligation[];
  name?: string;
  created_date: string;
  app_submission_deadline?: string;
  last_modified_date?: string;
  loan_version?: LoanVersion;
  system_modstamp?: string;
  last_activity_date?: string | null;
  last_viewed_date?: string | null;
  last_referenced_date?: string | null;
  sys_prop_lease_remaining_months?: number | null;
  applicant_ip_address?: string | null;
  business_address_city?: string | null;
  crb_application_id?: string | null;
  borrower_dba?: string | null;
  borrower_tin?: number | null;
  borrower_structure?:
    | 'LLC'
    | 'LLP'
    | 'S-CORP'
    | 'C-CORP'
    | 'GENERAL PARTNERSHIP'
    | 'SOLE PROPRIETORSHIP'
    | 'NON-PROFIT'
    | 'HOA'
    | null;
  borrower_gross_revenue_latest?: number | null;
  borrower_net_income_annual_after_tax?: number | null;
  sys_prop_address?: string | null;
  sys_prop_address_line_2?: string | null;
  project_timeline_estimation?:
    | 'The project is already complete'
    | 'The project is currently under construction'
    | 'Construction has not started, expect completion within 3 months'
    | 'Construction has not started, expect completion within 6 months'
    | 'Construction has not started, expect completion in more than 6 months'
    | 'Construction has not started, construction timeline currently unknown'
    | null;
  system_mounting_hardware?: string | null;
  sys_prop_ownership_status_picklist?: 'LEASED' | 'OWNED' | null;
  sys_prop_lease_monthly_payment?: number | null;
  sys_prop_spe_name?: string | null;
  sys_prop_mortgage_has_spe?: 'YES' | 'NO' | null;
  sys_prop_monthly_mortgage_payment?: number | null;
  borrower_advance?: 'Yes' | 'No' | null;
  borrower_signer_role?: string | null;
  business_address_state?: USState | null;
  borrower_industry_lines_of_business?: string | null;
  borrower_date_business_established_date?: string | null;
  borrower_does_owe_taxes_from_prev_years?: 'YES' | 'NO' | null;
  borrower_def_in_pending_claim_lawsuit?: 'YES' | 'NO' | null;
  borrower_judgment_in_past_5_years?: 'YES' | 'NO' | null;
  borrower_declared_bankruptcy_past_10_yrs?: 'YES' | 'NO' | null;
  borrower_has_potential_ownership_change?: 'Yes' | 'No' | null;
  borrower_has_credit_under_other_name?: 'Yes' | 'No' | null;
  borrower_is_high_risk?: 'Yes' | 'No' | null;
  business_address_zip_code?: string | null;
  mailing_address_city?: string | null;
  mailing_address_state?: USState | null;
  mailing_address_zip_code?: string | null;
  sys_prop_address_city?: string | null;
  sys_prop_address_state?: USState | null;
  rec_info?: string | null;
  sys_prop_address_zip_code?: string | null;
  business_address?: string | null;
  business_address_line_2?: string | null;
  mailing_address?: string | null;
  mailing_address_line_2?: string | null;
  sys_prop_lease_remaining_years?: number | null;
  sys_prop_landlord_consent_confirmation?: 'Yes' | 'No' | null;
  submission_date_stage1?: string | null;
  submission_date_stage2?: string | null;
  borrower_contact_full_name?: string;
  borrower_contact_email?: string;
  installer_business_name?: string | null;
  borrower_business_name?: string;
  loan_amount?: number | null;
  borrower_state_of_organization?: string | null;
  borrower_esign_name?: string | null;
  borrower_business_email?: string | null;
  borrower_business_phone_number?: string | null;
  business_ownership_explanation?: string | null;
  sys_prop_is_subleasing?: 'YES' | 'NO' | null;
  sys_prop_months_remaining_on_sublease?: number | null;
  booking_received_date?: string | null;
  booking_sent_date?: string | null;
  crb_compliance_passed?: string | null;
  crb_send_date?: string | null;
  date_funded?: string | null;
  date_ready_to_fund?: string | null;
  external_status_action: EXTERNAL_ACTIONS;
  external_status_borrower_description?: string | null;
  external_status_installer_description?: string | null;
  external_status_stage?:
    | 'Application Created'
    | 'Application In Progress'
    | 'Application Submitted'
    | 'Loan Package'
    | 'Complete'
    | 'Expired'
    | 'Closed'
    | null;
  installer_contact_email?: string | null;
  internal_status?:
    | 'Application Created'
    | 'Application Pending'
    | 'Documents Pending'
    | 'Pending Borrower'
    | 'Pending Installer'
    | 'Application Complete'
    | 'UW In-Progress'
    | 'UW Complete'
    | 'Compliance Pending'
    | 'Compliance Fail'
    | 'Compliance Pass'
    | 'Agreements Pending'
    | 'Agreements Executed'
    | 'Agreement Complete'
    | 'Ready to Fund'
    | 'Loan Funded'
    | 'Loan Approved'
    | 'Declined'
    | 'Canceled: Installer'
    | 'Canceled: Borrower'
    | 'Canceled: Sunstone'
    | 'Canceled: Do Not Contact'
    | 'Delayed'
    | 'Canceled: Expired'
    | 'Pending Change Request'
    | 'Closed'
    | null;
  last_uw_communication_date?: string | null;
  loan_agreement_sent_date?: string | null;
  loan_agreement_signed_date?: string | null;
  decision_date?: string | null;
  ucc_received_date?: string | null;
  ucc_sent_date?: string | null;
  has_non_solar_component?: string | null;
  non_solar_components?: string | null;
  non_solar_amount?: number | null;
  other_non_solar_amount?: number | null;
  roof_repair_amount?: number | null;
  ev_charging_amount?: number | null;
  energy_storage_amount?: number | null;
  other_non_solar?: string | null;
  sys_prop_mortgage_lender_name?: string | null;
  installer_contact?: SFId | null;
  consultant_contact?: SFId | null;
  product: Product;
  borrower_contact?: any;
  installer?: Account;
  consultant_account?: Account | null;
  borrower_account?: SFId;
  deal_description_and_context?: string | null;
  primary_contact_name?: string | null;
  primary_contact_email_address?: string | null;
  primary_contact_phone_number?: string | null;
  mailing_address_same_as_bussiness?: 'Yes' | 'No' | null;
  solar_system_power_usage?:
    | "Offset Borrower's utility bill"
    | 'Sold to another entity'
    | null;
  solar_system_power_company_type?:
    | 'Operating Company'
    | 'Property Company'
    | 'Agriculture'
    | 'Non-Profit'
    | 'HOA'
    | null;
  utility_bill_payer?: 'Tenant' | 'Landlord' | null;
  non_profit_received_board_approval?: 'Yes' | 'No' | null;
  non_profit_bylaws_reviewed_updated?: 'Yes' | 'No' | null;
  solar_system_power_describe_company?: string | null;
  system_power_single_or_multiple_users?:
    | 'Single User'
    | 'Multiple Users'
    | null;
  legal_name_of_offtaker?: string | null;
  is_partnering_with_tax_equity_investor?: 'Yes' | 'No' | null;
  tax_equity_investor_deal_funding_percent?: string | null;
  system_power_as_percent_of_offtaker_need?: string | null;
  offtaker_utility_rate_discount?: string | null;
  community_solar_or_ppa_lease?:
    | 'PPA/Lease Agreement'
    | 'Community Solar Arrangement'
    | null;
  legal_names_of_offtakers?: string | null;
  system_power_number_of_offtakers?: number | null;
  sys_prop_address_is_same_as_business?: 'Yes' | 'No' | null;
  borrower_is_currently_paying_mortgage?: 'Yes' | 'No' | null;
  system_property_legal_name_of_owner?: string | null;
  sys_prop_owner_affiliated_with_borrower?: 'Yes' | 'No' | null;
  system_property_lease_expiration_year?: number | null;
  system_property_appraised_value?: number | null;
  system_property_mortgage_opened_year?: number | null;
  system_property_initial_mortgage_amount?: number | null;
  system_property_mortgage_due_amount?: number | null;
  system_property_mortgage_maturity_year?: number | null;
  fin_info_name_credit_was_obtained_under?: string | null;
  fin_info_borrower_owes_taxes_explanation?: string | null;
  fin_info_borrower_pending_lawsuit_claim?: string | null;
  fin_info_awarded_judgement_explanation?: string | null;
  fin_info_borrower_bankruptcy_explanation?: string | null;
  system_power_rent_roll_or_tenant_info?:
    | 'Upload rent roll'
    | 'Enter information for a single tenant'
    | null;
  sys_power_property_company_tenant_name?: string | null;
  property_company_tenant_move_in_year?: number | null;
  property_company_tenant_lease_term?: number | null;
  property_company_tenant_renewal_year?: number | null;
  property_company_tenant_renewal_length?: number | null;
  prop_com_tenant_monthly_rent_payment?: number | null;
  prop_com_tenant_payments_satisfactory?: 'Yes' | 'No' | null;
  business_established_year?: number | null;
  management_years_of_industry_experience?: number | null;
  years_management_in_place?: number | null;
  borrower_is_high_risk_explanation?: string | null;
  applicant_filed_tax_returns_in_the_past: 'Yes' | 'No' | null;
  applicant_filed_last_year_tax_returns: 'Yes' | 'No' | null;
  closed_reason:
    | 'Invalid Application'
    | 'Solar Loan Concerns'
    | 'Sunstone Terms/Requirements'
    | 'Solar Project Concerns'
    | 'Expired'
    | 'Not Supported'
    | 'Issue With Installer'
    | 'Loan Package Unsigned'
    | 'Solar Project Incomplete'
    | null;
  close_requested_by: 'Borrower' | 'Installer' | 'Sunstone' | null;
  closed_sub_reason:
    | 'Application Incomplete'
    | 'Application Not Started'
    | 'Borrower dissatisfied'
    | 'Sunstone Loan is too expensive'
    | 'Borrower does not want to provide a PG'
    | 'Borrower does not want to proceed with solar project'
    | 'Borrower does not want to take on a loan'
    | 'Borrower does not like Sunstone loan conditions'
    | 'Borrower is uncertain if they qualify for ITC or another incentive'
    | 'External factors'
    | 'Incorrect Amount'
    | 'Incorrect Borrower Contact'
    | 'Incorrect Business Name'
    | 'Incorrect Product'
    | 'Loan package not signed within 30 days of receipt'
    | 'Loan package not signed within Sunstone approved timeline'
    | 'Loan Product'
    | 'Multiple Systems'
    | 'Other Error'
    | 'Poor Sunstone application experience'
    | 'Project Type'
    | 'Rate Increase'
    | 'Solar project did not fund within 180 days of approval'
    | 'Solar project not funded within Sunstone approved timeline'
    | 'Test Application'
    | null;
  closed_reason_notes: string | null;
  web_user_can_close: 'Yes' | 'No' | null;
  shown_to_borrower: 'Yes' | 'No' | null;
  is_religious_organization: 'Yes' | 'No' | null;
  parent_religious_organization_support: 'Yes' | 'No' | null;
  years_religious_leader_position: number | null;
  change_orders: ChangeOrder[];
  utility_api_connection: UtilityAPIConnection | null;
  borrower_attests_no_debt: boolean;
  uw_requests: ChecklistItem[];
  system_size_in_k_ws: number | null;
  year_1_system_production_kwh: number | null;
  year_1_total_solar_savings: number | null;
  total_system_cost: number | null;
  extensions_count: number;
  special_payment_percentage?: number;
  loan_category?: LoanCategory | null;
}

// minimal version of Application for dashboard
export interface DashboardApplication
  extends Pick<
    Application,
    | 'id'
    | 'app_submission_deadline'
    | 'borrower_contact_full_name'
    | 'borrower_business_name'
    | 'borrower_contact_email'
    | 'closed_reason'
    | 'closed_sub_reason'
    | 'consultant_account'
    | 'external_status_action'
    | 'external_status_installer_description'
    | 'external_status_stage'
    | 'installer'
    | 'installer_program'
    | 'name'
    | 'loan_amount'
    | 'rec_info'
    | 'web_user_can_close'
  > {
  amortization_years: number;
  apr_percentage: number;
  change_orders: DashboardChangeOrder[];
  error_code?: string;
  loan_term_years: number;
  loan_status_tasks: Task[];
  system_address: string;
  uw_requests: DashboardChecklistItem[];
  can_create_change_request: boolean;
}

export interface ChangeOrder extends SFObject {
  loan_application: string | null;
  date_submitted: string | null;
  external_status:
    | 'Draft'
    | 'Under Review'
    | 'Approved'
    | 'Declined'
    | 'Canceled'
    | null;
  internal_status:
    | 'Draft'
    | 'Submitted'
    | 'Under Review'
    | 'CRB First Pass'
    | 'Loan Agreement Sent'
    | 'Loan Agreement Executed'
    | 'Declined'
    | 'Canceled'
    | null;
  decline_reason: string | null;
  new_loan_amount: number | null;
  project_has_a_solar_component: 'Yes' | 'No' | null;
  cost_of_the_solar_component_changing: 'Yes' | 'No' | null;
  description_of_solar_changes: string | null;
  additional_solar_equipment_installation: 'Yes' | 'No' | null;
  equipment_new_or_used: 'New' | 'Used' | null;
  name_of_subcontractor: string | null;
  subcontractor_warranty: string | null;
  equipment_make_and_model: string | null;
  who_is_completing_work: 'Us' | 'Subcontractor' | null;
  subcontractor_address: string | null;
  subcontractor_address_line_2: string | null;
  subcontractor_city: string | null;
  subcontractor_zipcode: string | null;
  subcontractor_state: string | null;
  new_system_size_k_w: number | null;
  new_first_year_production_k_wh: number | null;
  new_solar_benefits: string | null;
  other_changes: string | null;
  loan_product: string | null;
  loan_product_name: string | null;
  borrower_business_name: string | null;
  application_number: string | null;
  loan_term_name: string | null;
  loan_amount: number | null;
  system_property_address: string | null;
  interest_rate: number | null;
  is_system_size_changing: 'Yes' | 'No' | null;
  is_first_year_production_changing: 'Yes' | 'No' | null;
  are_solar_benefits_available: 'Yes' | 'No' | null;
  total_cost_of_solar_plus_components: number | null;
  reason_for_change: string | null;
  addition_reason_notes: string | null;
}

// minimal version of ChangeOrder for dashboard
export type DashboardChangeOrder = Pick<
  ChangeOrder,
  | 'id'
  | 'loan_application'
  | 'internal_status'
  | 'new_loan_amount'
  | 'other_changes'
  | 'date_submitted'
  | 'created_date'
  | 'external_status'
  | 'application_number'
>;

export interface ChangeOrderWithDocuments extends ChangeOrder {
  documents: Pick<RequiredDocuments, ChangeOrderDocumentTypes>;
}

export interface ChecklistItem {
  id: string;
  is_deleted: boolean;
  name: string | null;
  created_date: string;
  last_modified_date: string;
  system_modstamp: string;
  last_viewed_date: string | null;
  last_referenced_date: string | null;
  action:
    | 'No Action'
    | 'Automated Daily Email alert to Borrower'
    | 'Follow up with the Installer'
    | null;
  borrower_last_notified_date: string | null;
  internal_notes: string | null;
  loan_application: number | null;
  notes_to_borrower: string | null;
  status: 'Verified' | 'Issues Identified' | 'Missing' | null;
  checklist_status: 'New' | 'In Progress' | 'In Review' | 'Done' | null;
  verification_date: string | null;
  template: 'Standard' | 'Optional' | 'UW Checklist' | null;
  document_name: string | null;
  date_completed: string | null;
  sort: number | null;
  start_date: string | null;
  underwriting: string | null;
  days_in_progress: number | null;
  status_color: string | null;
}

// minimal version of ChecklistItem for dashboard
export type DashboardChecklistItem = Pick<
  ChecklistItem,
  'id' | 'name' | 'start_date' | 'notes_to_borrower'
>;

export interface Contact {
  id?: any;
  account: Account;
  borrower_business_name?: string;
  created_date?: string;
  email?: string;
  first_name?: string | null;
  installer_role: 'Sales' | 'Accounting' | 'Admin' | null;
  is_active?: boolean;
  last_login?: string;
  last_name: string;
  position_title?: string;
  record_type?: 'borrower' | 'installer' | 'consultant';
  status?: 'Invited' | 'Unverified' | 'Active' | 'Inactive';
  is_installer?: boolean;
  is_consultant?: boolean;
  is_borrower?: boolean;
}

export type DocumentType = {
  id: string;
  name: string;
  crb_abbrev: string;
};

export interface EmailMessage {
  id?: any;
  created_by_id: string;
  created_date: string;
  last_modified_date: string;
  system_modstamp: string;
  text_body?: string | null;
  html_body?: string | null;
  headers?: string | null;
  subject?: string | null;
  from_name?: string | null;
  from_address?: string | null;
  validated_from_address?:
    | 'eng@sunstonecredit.com'
    | 'team@sunstonecredit.com'
    | 'support@sunstonecredit.com'
    | null;
  to_address?: string | null;
  cc_address?: string | null;
  bcc_address?: string | null;
  incoming?: boolean;
  has_attachment?: boolean;
  status: '0' | '1' | '2' | '3' | '4' | '5';
  message_date?: string | null;
  is_externally_visible?: boolean;
  message_identifier?: string | null;
  thread_identifier?: string | null;
  is_client_managed?: boolean;
  is_tracked?: boolean;
  is_opened?: boolean;
  first_opened_date?: string | null;
  last_opened_date?: string | null;
  is_bounced?: boolean;
  activity?: SFId | null;
  reply_to_email_message?: SFId | null;
  related_to?: SFId | null;
}

export interface Fee
  extends Omit<
    SFObject,
    | 'is_deleted'
    | 'created_date'
    | 'last_modified_date'
    | 'last_activity_date'
    | 'last_viewed_date'
    | 'last_referenced_date'
  > {
  active: boolean;
  amortization_years: string | null;
  deactivation_reason: string | null;
  dealer_fee: number | null;
  end_date: string | null;
  installer: SFId | null;
  interest_rate: number | null;
  loan_term_years: string | null;
  pricing_grouping: '1' | '2' | '3' | '4' | '5' | null;
  product: SFId | null;
  product_family: ProductFamily | null;
  profile: 'Normal' | 'CED' | 'Custom' | 'Legacy' | null;
  start_date: string | null;
}

export type File = {
  id: string;
  required_doc_id: string;
  s3_url: string;
  version_id: string;
  file_name: string;
  is_deleted: boolean;
  is_public: boolean;
  created_at: Date;
  created_by: string;
  upload_source: FileUploadSource;
};

export interface FundedProject {
  id?: any;
  active_milestone:
    | 'NTP'
    | 'EO'
    | 'SC'
    | 'FC'
    | 'MC'
    | 'RC'
    | 'FullyFunded'
    | null;
  advance_funding_request?: number | null;
  borrower_business_name?: string | null;
  borrower_contact_name?: string | null;
  borrower_email?: string | null;
  borrower_phone?: string | null;
  closed_message: string | null;
  consultant_account: Account;
  created_date: string;
  custom_information?: string | null;
  days_until_expiration_date?: number;
  days_until_pricing_expiration_date?: number;
  decision_date?: string | null;
  detailed_requirements_url: string | null;
  errors?: FundedProjectMessage[] | null;
  external_status?:
    | 'In Progress'
    | 'Pending Change Order'
    | 'Fully Funded'
    | 'Canceled'
    | 'Closed'
    | null;
  final_completion_expiration_date?: string;
  financing_expiration_date?: string;
  installer_account: Account;
  installer_advance_funding_opt_in: 'Yes' | 'No' | null;
  installer_product: SFId;
  installer_program: InstallerProgram | null;
  last_modified_date: string;
  loan_agreement_signed_date?: string;
  loan_app_interest_rate?: number | null;
  loan_app_loan_amount?: number | null;
  loan_app_number?: string | null;
  loan_app_term_length?: number | null;
  loan_application: Application;
  name?: string | null;
  net_funding?: number | null;
  paid_to_date?: number | null;
  pricing_expiration_date?: string | null;
  type_of_advance_funding?: string | null;
  request_approved: string | null;
  selected_advanced_funding: string | null;
  system_location_address?: string | null;
  system_modstamp: string;
}

// minimal version of FundedProject for dashboard
export interface DashboardFundedProject
  extends Pick<
    FundedProject,
    | 'id'
    | 'active_milestone'
    | 'borrower_business_name'
    | 'borrower_contact_name'
    | 'closed_message'
    | 'consultant_account'
    | 'custom_information'
    | 'days_until_expiration_date'
    | 'days_until_pricing_expiration_date'
    | 'decision_date'
    | 'errors'
    | 'external_status'
    | 'final_completion_expiration_date'
    | 'financing_expiration_date'
    | 'installer_account'
    | 'installer_program'
    | 'loan_app_loan_amount'
    | 'loan_app_number'
    | 'loan_application'
    | 'name'
    | 'net_funding'
    | 'paid_to_date'
    | 'pricing_expiration_date'
    | 'system_location_address'
  > {
  days_since_note_date?: number;
  system_location_address_2?: string;
  system_location_address_3?: string;
}

export interface InstallerDashboardProjectsSummary {
  total_paid_to_date: number;
  net_funding: number;
  projects_in_progress: number;
  completed_projects: number;
}

export interface InstallerDashboardApplicationsSummary {
  total_applications: number;
  total_applicants: number;
  completed_applications: number;
  active_applications: number;
}

export interface InstallerProduct {
  id?: any;
  created_date: string;
  dealer_fee: number;
  fee_dealer_fee: Fee;
  installer_account: SFId;
  last_activity_date?: string | null;
  last_modified_date: string;
  last_referenced_date?: string | null;
  last_viewed_date?: string | null;
  name: string;
  product: Product;
  product_rate_scenario: '1' | '2' | '3' | '4' | '5';
  system_modstamp: string;
}

export interface InstallerProgram {
  amount_of_available_funds: number;
  decision_date: Date;
  funding_limit: number;
  installer_account: string;
  installer_application: string;
  name: string;
  open_advances: number;
  status:
    | 'Application Submitted'
    | 'Application In-Progress'
    | 'Approved'
    | 'Declined'
    | 'Not Eligible';
  type: 'Milestone Program' | 'Greentech Direct Pay Program';
}

export interface LoanVersion {
  id?: any;
  active_loan_version: boolean;
  loan_version_name: string;
  maturity_payment_amount?: number;
  special_payment_amount?: number;
  special_payment_month?: number;
}

export interface LogOut {
  refresh: string;
  access: string;
}

export interface MaterialLoanObligation extends SFObject {
  loan_type?: 'Commercial' | 'Personal' | null;
  loan_balance?: number | null;
  loan_maturity_date?: string | null;
  loan_monthly_payment?: number | null;
  loan_original_amount?: number | null;
  loan_collateral_description?: string | null;
  any_payments_30_days_past_due?: 'Yes' | 'No' | null;
  reference_name?: string | null;
  reference_phone_number?: string | null;
  reference_email?: string | null;
  application?: SFId;
  lender_name?: string | null;
  year_of_loan_maturity?: number | null;
  loan_purpose?: string | null;
  outstanding_loan_type?: string | null;
  on_system_property?: string | null;
}

export interface Milestone {
  id?: any;
  approval_1: 'Awaiting Entry' | 'Complete' | null;
  approval_2: 'Awaiting Entry' | 'Complete' | null;
  approval_3: 'Awaiting Entry' | 'Complete' | null;
  completion_date: string | null;
  created_date: string;
  description: string;
  documents: SunstoneDocument[];
  entered_by_1: SFId | null;
  entered_by_2: SFId | null;
  entered_by_3: SFId | null;
  funded_project: SFId;
  key_1: string | null;
  key_2: string | null;
  key_3: string | null;
  last_modified_date: string;
  milestone_completion: number;
  max_allowed_amount: number;
  name?: string | null;
  payment_amount: number;
  payment_percentage: number;
  requested_amount: number;
  status: 'Not Started' | 'In Progress' | 'Completed' | 'Paid' | 'Waived';
  system_modstamp: string;
  title: string;
  tooltip_1: string | null;
  tooltip_2: string | null;
  tooltip_3: string | null;
  total_of_documents: number;
  total_of_approved_documents: number;
  type: 'NTP' | 'EO' | 'SC' | 'RC' | 'MC' | 'FC';
  type_1: 'Text' | 'Date' | null;
  type_2: 'Text' | 'Date' | null;
  type_3: 'Text' | 'Date' | null;
  value_1: string | null;
  value_2: string | null;
  value_3: string | null;
  warning: string | null;
}

export interface OutstandingLoan extends SFObject {
  loan_type?: 'Commercial' | 'Personal' | null;
  loan_balance?: number | null;
  loan_maturity_date?: SFDate | null;
  loan_monthly_payment?: number | null;
  loan_original_amount?: number | null;
  loan_collateral_description?: string | null;
  any_payments_30_days_past_due?: YesNo | null;
  reference_name?: string | null;
  reference_phone_number?: number | null;
  reference_email?: string | null;
  application: SFId;
  lender_name?: string | null;
}

export type PaginatedResult<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
};

export interface Payment {
  id?: any;
  account_mask?: string | null;
  amount: number;
  created_date: string;
  funded_project: SFId;
  last_modified_date: string;
  made_on: string;
  milestone: Milestone;
  name?: string | null;
  purpose: string | null;
  receipt_download_url: string | null;
  system_modstamp: string;
}

export interface Product extends SFObject {
  amortization_years: number;
  apr_percentage: number;
  description: string;
  family: ProductFamily;
  id: string;
  is_active: boolean;
  is_archived?: boolean;
  loan_term_years: number;
  name: string;
}

export interface Rates {
  current: FeeByProductFamily;
  future: {
    date: string;
    rates: FeeByProductFamily;
  }[];
}

export interface RelatedParty {
  id?: any;
  address?: string | null;
  address_line_2?: string | null;
  annual_gross_income?: number | null;
  applicant_same_as_principal?: 'Yes' | 'No' | null;
  application: SFId;
  average_cash_balance?: number | null;
  city?: string | null;
  country?: string | null;
  created_date?: any;
  dob?: string | null;
  email?: string | null;
  entity_type?: 'Business' | 'Trust' | null;
  first_name?: string | null;
  filed_taxes_1_year_ago?: 'Yes' | 'No' | null;
  filed_taxes_2_years_ago?: 'Yes' | 'No' | null;
  filed_taxes_3_years_ago?: 'Yes' | 'No' | null;
  guarantor_agreement?: boolean | null;
  is_a_non_us_person?: 'Yes' | 'No' | null;
  is_controlling_principal?: boolean;
  is_deleted?: any;
  is_us_citizen: 'Yes' | 'No' | null;
  last_activity_date?: string | null;
  last_modified_date?: any;
  last_name?: string | null;
  last_referenced_date?: string | null;
  last_viewed_date?: string | null;
  liquid_assets?: number | null;
  middle_initial?: string | null;
  monthly_rent?: number | null;
  mortgage_payment?: number | null;
  name?: any;
  name_of_trust?: string | null;
  non_usperson_id_country_of_issuance?: string | null;
  non_us_person_id_number?: string | null;
  other_occupation?: string | null;
  percent_ownership?: number | null;
  phone_number?: string | null;
  position_title?: string | null;
  principal_is_personal_guarantor?: 'Yes' | 'No' | null;
  principal_type?: 'Individual' | 'Entity' | null;
  relationship?: 'Principal' | 'Guarantor' | 'Offtaker' | 'Landlord' | null;
  rent_or_own?: 'Rent' | 'Own' | null;
  ssn_text?: string | null;
  state_picklist?: USState | null;
  trust_tin?: string | null;
  year_trust_was_established?: number | null;
  zip_code?: string | null;
  documents?: {
    PRINCIPAL_ID?: SunstoneDocument;
    'Certificate of Trust or Executed Trust Agreement'?: SunstoneDocument;
  };
  // these 2 fields are only used by Rosefield for principal validation
  __asap_count: number;
  __is_pg_value: boolean;
  __companyTypeValue: string;
}

export type RequiredDocument = {
  id: string;
  requirement_name: string;
  parent_id: string;
  document_type_id: string;
  requirement_description: string;
  requires_approval: boolean;
  approval_status: DocumentApprovalStatus;
  document_approval_source: DocumentApprovalSource;
  is_gallery: boolean;
  approval_date: Date;
  approved_by_user_username: string | null;
  is_single_file: boolean;
};

interface SFObject {
  id: SFId;
  name: string;
  is_deleted: boolean;
  created_date: SFDate;
  last_modified_date: SFDate;
  last_activity_date: SFDate | null;
  last_viewed_date?: SFDate | null;
  last_referenced_date?: SFDate | null;
}

export interface SunstoneDocument {
  id?: any;
  approval_status?:
    | 'Sent via Docusign'
    | 'Awaiting Upload'
    | 'Signature Needed'
    | 'Waiting Review'
    | 'Approved'
    | 'Issues Identified'
    | 'Pending Call'
    | null;
  created_date: string;
  description?: string | null;
  display_name?: string | null;
  document_source?: 'DocuSign' | 'Direct-Upload' | null;
  document_type:
    | 'Additional Document'
    | 'Tax Returns Last Year'
    | 'Tax Returns 1 Year Ago'
    | 'Tax Returns 2 Years Ago'
    | 'Interim Financial Statement'
    | 'Bank Statement Last Month'
    | 'Bank Statement 1 Month Ago'
    | 'Bank Statement 2 Months Ago'
    | 'Ownership Document'
    | 'Asset Report'
    | 'Compressed File'
    | 'Installer Milestone-Funding Document';
  e_signer_email?: string | null;
  e_signer_name?: string | null;
  file_created_date?: string | null;
  file_name?: string;
  file_size?: number;
  frontend_upload_type: 'Single File' | 'Gallery' | null;
  has_no_major_subtractors_or_suppliers: boolean;
  last_modified_date: string;
  last_submission_date: string | null;
  loan_application: SFId;
  milestone?: SFId | null;
  name?: string | null;
  original_file_name?: string;
  owner_account?: SFId | null;
  possibly_unrequired: 'Yes' | 'No' | null;
  related_entity_name?: string | null;
  requires_approval: 'Yes' | 'No' | null;
  sort_id?: number | null;
  system_modstamp: string;
  tooltip_text?: string | null;
  unrequired_text: string | null;
  uploaded_by?: SFId;
  url: string | null;
}

export interface Task {
  id?: any;
  who_count?: number | null;
  what_count?: number | null;
  subject?: 'CALL' | 'SEND LETTER' | 'SEND QUOTE' | 'OTHER' | null;
  activity_date?: string | null;
  status?: 'OPEN' | 'COMPLETED';
  priority?: 'HIGH' | 'NORMAL';
  is_high_priority?: boolean;
  description?: string | null;
  is_closed?: boolean;
  created_date: string;
  last_modified_date: string;
  system_modstamp: string;
  is_archived?: boolean;
  call_duration_in_seconds?: number | null;
  call_type?: 'Internal' | 'Inbound' | 'Outbound' | null;
  call_disposition?: string | null;
  call_object?: string | null;
  reminder_date_time?: string | null;
  is_reminder_set?: boolean;
  is_recurrence?: boolean;
  recurrence_start_date_only?: string | null;
  recurrence_end_date_only?: string | null;
  recurrence_time_zone_sid_key?: string | null;
  recurrence_type?:
    | 'RecursDaily'
    | 'RecursEveryWeekday'
    | 'RecursMonthly'
    | 'RecursMonthlyNth'
    | 'RecursWeekly'
    | 'RecursYearly'
    | 'RecursYearlyNth'
    | null;
  recurrence_interval?: number | null;
  recurrence_day_of_week_mask?: number | null;
  recurrence_day_of_month?: number | null;
  recurrence_instance?: 'First' | 'Second' | 'Third' | 'Fourth' | 'Last' | null;
  recurrence_month_of_year?:
    | 'January'
    | 'February'
    | 'March'
    | 'April'
    | 'May'
    | 'June'
    | 'July'
    | 'August'
    | 'September'
    | 'October'
    | 'November'
    | 'December'
    | null;
  recurrence_regenerated_type?:
    | 'RecurrenceRegenerateAfterDueDate'
    | 'RecurrenceRegenerateAfterToday'
    | 'RecurrenceRegenerated'
    | null;
  task_subtype?: 'Task' | 'Email' | 'ListEmail' | 'Cadence' | 'Call' | null;
  completed_date_time?: string | null;
  borrower_communication?:
    | 'Application Created'
    | 'Application in Progress'
    | 'Waiting for Additional Information from Borrower'
    | 'Application Completed and Submitted for Review'
    | 'Application Review in Progress'
    | 'Application Review Complete'
    | 'Application Secondary Review Pending'
    | 'Loan Agreement Package Sent to Borrower'
    | 'Loan Agreement Signed by Borrower'
    | 'Loan Agreement Package Finalized'
    | 'Loan Funded'
    | 'Loan Declined'
    | 'Loan Cancelled by Installer'
    | 'Loan Cancelled Due to Inactivity'
    | string
    | null;
  who?: SFId | null;
  what?: SFId | null;
  account?: SFId | null;
  recurrence_activity?: SFId | null;
}

export interface TokenRefresh {
  refresh: string;
}

export interface UpdatePassword {
  current_password: string;
  new_password: string;
  confirm_password: string;
  refresh_token: string;
}

export interface User extends SFObject {
  first_name: string;
  last_name: string;
  name: string;
  email: string;
  phone?: string;
}

export interface WebPortalResource extends SFObject {
  resource_name: string;
  accessible_by: 'Installers' | 'Borrowers' | 'All' | 'Custom' | null;
  custom_access_account: string | null;
  description: string;
  tooltip: string | null;
  resource_type: 'Rate sheet' | 'Installer Document' | 'Borrower Document';
  start_date: string | null;
  end_date: string | null;
  download_link: string | null;
  preview_link: string | null;
  content_delivery_id: string | null;
  content_document_id: string | null;
}

export type PricingSummary = {
  monthly_payment: number;
  yearly_payments: number[];
  special_payment: number;
  year_1_payment: number;
  maturity_payment: number;
};
