import React, { memo, ReactNode } from 'react';
import { InputAdornment, Typography, Box } from '@mui/material';
import StyledInput from '../inputs/StyledInput';
import type { StyledInputProps } from '../inputs/StyledInput';
import { Control, Controller, FieldValues } from 'react-hook-form';

type InputFieldProps<T extends FieldValues = any> = StyledInputProps & {
  control?: Control<T>;
  name: string;
  startAdornmentText?: string;
  endAdornmentText?: string;
  readonly?: boolean;
  maxLength?: number;
  counter?: boolean;
  touched?: boolean;
  isDisabled?: boolean;
  infoText?: string;
};

const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>(
  function InputField(props, ref) {
    const {
      control,
      name,
      isDisabled,
      infoText,
      startAdornmentText,
      endAdornmentText,
      readOnly,
      maxLength,
      counter,
      touched: defaultTouched,
      ...rest
    } = props;

    let startAdornment: ReactNode | undefined = undefined;
    if (startAdornmentText) {
      startAdornment = (
        <InputAdornment position="start">{startAdornmentText}</InputAdornment>
      );
    }
    let endAdornment: ReactNode | undefined = undefined;
    if (endAdornmentText) {
      endAdornment = (
        <InputAdornment position="end">{endAdornmentText}</InputAdornment>
      );
    }

    const id = props.id ?? props.name;
    return (
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => {
          const { error, isTouched: fieldTouched } = fieldState;
          const touched = defaultTouched || fieldTouched;
          function _renderHelperText() {
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  justifyContent: 'space-between',
                  whiteSpace: 'nowrap',
                }}
                component="span"
              >
                <Typography
                  variant="body2"
                  flexGrow={0}
                  pt={0.375}
                  component="span"
                >
                  {counter &&
                    field.value &&
                    `${field.value?.length ?? 0}/${maxLength}`}
                </Typography>
                {touched && error?.message}
              </Box>
            );
          }
          return (
            <StyledInput
              id={id}
              type="text"
              error={Boolean(touched && error)}
              helperText={_renderHelperText()}
              disabled={isDisabled}
              InputProps={{
                readOnly: readOnly,
                endAdornment: endAdornment,
                startAdornment: startAdornment,
              }}
              {...field}
              value={field.value ?? ''}
              ref={ref}
              onChange={(e) => {
                let value = e.target.value;
                if (maxLength && value.length > maxLength)
                  value = value.slice(0, maxLength);
                field.onChange(value);
              }}
              {...rest}
            />
          );
        }}
      />
    );
  }
);

export default memo(InputField);
