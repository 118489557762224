import { Button, Dialog, DialogContent, Typography } from '@mui/material';
import useAuth from '../hooks/useAuth';

export const SessionExpiredModal = () => {
  const { closeSessionExpiredModal, showSessionExpiredModal } = useAuth();
  return (
    <Dialog open={showSessionExpiredModal} onClose={closeSessionExpiredModal}>
      <DialogContent>
        <Typography variant="h6" textAlign="center" sx={{ mt: 1 }}>
          Your session has expired. Please log in again.
        </Typography>
        <Button
          variant="contained"
          sx={{
            minWidth: '150px',
            borderRadius: '10px',
            mt: 3,
            mx: 'auto',
            display: 'block',
          }}
          onClick={closeSessionExpiredModal}
        >
          OK
        </Button>
      </DialogContent>
    </Dialog>
  );
};
