import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // 20 seconds base stale time to prevent duplicate requests.
      staleTime: 20 * 1000,
    },
  },
});
