export class CustomError {
  constructor({ code, message }) {
    this.code = code;
    this.message = message;
  }
}

export class UserNotFound {
  constructor({ code = 300, message = 'Not Found', data } = {}) {
    this.code = code;
    this.message = message;
    this.data = data;
  }
}

export class UserPasswordIncorrect {
  constructor({
    code = 300,
    message = 'Incorrect email or password entered, please try again',
    data,
  } = {}) {
    this.code = code;
    this.message = message;
    this.data = data;
  }
}

export class UserNotInvited {
  constructor({
    code = 300,
    message = 'Sorry, you have not been invited to join with this email address.',
    data,
  } = {}) {
    this.code = code;
    this.message = message;
    this.data = data;
  }
}

export class UserVerificationCodeInvalid {
  constructor({
    code = 300,
    message = 'Verification code is invalid.',
    data,
  } = {}) {
    this.code = code;
    this.message = message;
    this.data = data;
  }
}

export class BadRequest {
  constructor({ code = 300, message = 'request failed !', data } = {}) {
    this.code = code;
    this.message = message;
    this.data = data;
  }
}

export class TimeoutError {
  constructor({
    code = 408,
    message = "Sunstone Credit web portal is experiencing some performance issues due to an ongoing incident with our servers. Don't worry, your data is safe. Intermittent slow performance, timeouts, and connectivity issues are possible. We will provide an update in 30 minutes or sooner if we have more information.",
    data,
  } = {}) {
    this.code = code;
    this.message = message;
    this.data = data;
  }
}

export class ServerError {
  constructor({ code = 300, message = 'Server Error!', data } = {}) {
    this.code = code;
    this.message = message;
    this.data = data;
  }
}
