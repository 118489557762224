import React from 'react';
import { useField } from 'formik';
import { MenuItem } from '@mui/material';
import StyledInput from '../inputs/StyledInput';

function SelectField(props) {
  const {
    label,
    choices,
    isDisabled,
    readOnly,
    touched: defaultTouched,
    ...rest
  } = props;
  const [field, meta] = useField(props);
  const { touched: fieldTouched, error } = meta;
  const touched = defaultTouched || fieldTouched;

  const isError = touched && error;
  function _renderHelperText() {
    if (isError) {
      return error;
    }
  }
  const id = props.id ?? props.name;
  return (
    <StyledInput
      select
      label={label}
      disabled={isDisabled}
      helperText={_renderHelperText()}
      SelectProps={{
        displayEmpty: true,
        inputProps: { readOnly },
      }}
      id={id}
      error={isError}
      {...rest}
      {...field}
      value={field.value ?? ''}
    >
      <MenuItem value="" disabled>
        <em>Please choose a value</em>
      </MenuItem>
      {choices.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </StyledInput>
  );
}

SelectField.defaultProps = {
  data: [],
};

export default SelectField;
