import { z } from 'zod';
import { UNITED_STATES } from '../../../../constants';
import { ProductFamily } from '../../../../../../../types/api';

const checkAtLeastOneCheckbox = (obj) => {
  return Object.values(obj).some((val) => val === true);
};

const getValidationSchema = (email: string) => {
  const validationSchema = z
    .object({
      borrower: z.object({
        name: z
          .string()
          .refine((value) => value !== '', 'Business name is required'),
      }),
      loanType: z
        .nativeEnum(ProductFamily, {
          invalid_type_error: 'Please select a loan type',
        })
        .nullable()
        .optional()
        .refine((value) => Boolean(value), 'Please select a loan type'),
      loanTerm: z
        .string()
        .min(1, 'Please select a loan term')
        .optional()
        .refine((value) => Boolean(value), 'Please select a loan term'),
      contact: z.object({
        first_name: z
          .string()
          .refine((value) => value !== '', "Contact's First name is required"),
        last_name: z
          .string()
          .refine((value) => value !== '', "Contact's Last name is required"),
        position_title: z
          .string()
          .refine(
            (value) => value !== '',
            "Contact's Position/Title is required"
          ),
        email: z
          .string()
          .email('Must be a valid email address')
          .refine(
            (value) => value !== '',
            "Contact's Email address is required"
          )
          .refine(
            (value) => value !== email,
            (value) => ({
              message: `${value} can not be used to initialize this loan application. Please enter another email.`,
            })
          ),
        phone: z
          .string()
          .refine((value) => /^[0-9]+$/.test(value), 'Must be only digits')
          .refine((value) => value.length === 10, 'Must be 10 digits')
          .refine((value) => value !== '', 'A phone number is required'),
      }),
      application: z
        .object({
          sys_prop_address_state: z
            .string()
            .refine(
              (value) =>
                UNITED_STATES.map((state) => state.value).includes(value),
              "The property's State where the system will be installed must be one of the valid options"
            )
            .refine(
              (value) => value !== '',
              "The property's State where the system will be installed is required"
            ),
          rec_info: z.string().nullable().optional(),
          loan_product: z
            .string()
            .refine((value) => value !== '', 'Please select the interest rate'),
          has_non_solar_component: z.union([
            z.literal('Yes'),
            z.literal('No'),
            z.literal(''),
          ]),
          non_solar_amount: z
            .union([z.string().length(0), z.coerce.number()])
            .nullable()
            .optional(),
          energy_storage_amount: z
            .union([z.string().length(0), z.coerce.number()])
            .nullable()
            .optional(),
          ev_charging_amount: z
            .union([z.string().length(0), z.coerce.number()])
            .nullable()
            .optional(),
          roof_repair_amount: z
            .union([z.string().length(0), z.coerce.number()])
            .nullable()
            .optional(),
          other_non_solar_amount: z
            .union([z.string().length(0), z.coerce.number()])
            .nullable()
            .optional(),
          system_mounting_hardware: z.string().optional(),
          non_solar_components: z.string().optional(),
          loan_amount: z
            .union([
              z.coerce
                .number()
                .refine((value) => value >= 50000, 'Must be atleast $50,000')
                .refine(
                  (value) => value <= 100000000,
                  'Cannot exceed $100,000,000'
                ),
              z.string().length(0),
            ])
            .refine(
              (value) => typeof value === 'number',
              'Loan amount is required'
            ),
          project_timeline_estimation: z
            .string()
            .refine(
              (value) => value !== '',
              'Project Timeline Estimation is required'
            ),
          deal_description_and_context: z.string().max(5000),
          system_size_in_k_ws: z.coerce
            .number()
            .nullable()
            .refine((value) => value !== null, {
              message: 'System Size in kW is required',
            })
            .refine((value) => (value === null ? 1 : value > 0), {
              message: 'Must be greater than 0',
            }),
          year_1_system_production_kwh: z.coerce
            .number()
            .nullable()
            .refine((value) => value !== null, {
              message: 'Year 1 Solar System Production (kWh) is required',
            })
            .refine((value) => (value === null ? 1 : value > 0), {
              message: 'Must be greater than 0',
            }),
          year_1_total_solar_savings: z.coerce
            .number()
            .nullable()
            .refine((value) => value !== null, {
              message: 'Year 1 Total Solar Savings is required',
            })
            .refine((value) => (value === null ? 1 : value > 0), {
              message: 'Must be greater than 0',
            }),
          total_system_cost: z.coerce
            .number()
            .nullable()
            .refine((value) => value !== null, {
              message: 'Total System Cost is required',
            })
            .refine((value) => (value === null ? 1 : value > 0), {
              message: 'Must be greater than 0',
            }),
          is_special: z.string().nullable().optional(),
          special_payment_percentage: z.coerce.number().nullable().optional(),
          borrower_advance: z.string().nullable().optional(),
        })
        .superRefine((values, context) => {
          if (
            ['Colorado', 'Illinois', 'Massachusetts'].includes(
              values.sys_prop_address_state
            ) &&
            !values?.rec_info
          ) {
            context.addIssue({
              code: z.ZodIssueCode.custom,
              message:
                'You must choose a Utility ' +
                (values.sys_prop_address_state == 'Massachusetts'
                  ? 'Territory'
                  : 'Company'),
              path: ['rec_info'],
            });
          }
        }),
      loan_proposal: z.object({
        file: z.instanceof(File, {
          message: 'Solar Proposal Document is required',
        }),
      }),
      utility_bills: z
        .array(
          z.object({
            file: z.instanceof(File, {
              message: 'Solar Proposal Document is required',
            }),
          })
        )
        .min(1, 'At least one utility bill is required'),
      incentive_documents: z.array(
        z.object({
          file: z.instanceof(File, {
            message: 'Solar Proposal Document is required',
          }),
        })
      ),
      mounting: z
        .object({
          roof: z.boolean(),
          ground: z.boolean(),
          carport: z.boolean(),
          other: z.boolean(),
          otherValue: z.string().optional(),
        })
        .refine((value) => (value.other ? Boolean(value.otherValue) : true), {
          message: 'Enter Other Mounting Hardware',
          path: ['otherValue'],
        })
        .optional(),
      mountingChecked: z.coerce.boolean().optional(),
      nonSolar: z
        .object({
          hasNonSolarComponent: z
            .boolean()
            .optional()
            .refine(
              (value) => typeof value === 'boolean',
              'Non-Solar Project Component status is required'
            ),
          nonSolarAmount: z.coerce.number().optional(),
          energyStorage: z.boolean(),
          evCharging: z.boolean(),
          roofRepair: z.boolean(),
          other: z.boolean(),
          otherValue: z.string().optional(),
        })
        .refine(
          (value) =>
            value.hasNonSolarComponent ? Boolean(value.nonSolarAmount) : true,
          {
            message:
              'Enter Estimated total cost of non-solar project components',
            path: ['nonSolarAmount'],
          }
        )
        .refine((value) => (value.other ? Boolean(value.otherValue) : true), {
          message: 'Enter Other Component',
          path: ['otherValue'],
        })
        .optional(),

      nonSolarChecked: z.coerce.boolean().optional(),
    })
    .refine(
      (values) => {
        const {
          hasNonSolarComponent,
          nonSolarAmount,
          otherValue,
          ...nonSolarCheckboxes
        } = values.nonSolar ?? {};
        return hasNonSolarComponent
          ? checkAtLeastOneCheckbox(nonSolarCheckboxes)
          : true;
      },
      { message: 'Check at least one checkbox', path: ['nonSolarChecked'] }
    )
    .refine((values) => checkAtLeastOneCheckbox(values.mounting), {
      message: 'Check at least one checkbox',
      path: ['mountingChecked'],
    });
  return validationSchema;
};

export const timelineEstimations = [
  {
    label: 'The project is already complete',
    value: 'The project is already complete',
  },
  {
    label: 'The project is currently under construction',
    value: 'The project is currently under construction',
  },
  {
    label: 'Construction has not started, expect completion within 3 months',
    value: 'Construction has not started, expect completion within 3 months',
  },
  {
    label: 'Construction has not started, expect completion within 6 months',
    value: 'Construction has not started, expect completion within 6 months',
  },
  {
    label:
      'Construction has not started, expect completion in more than 6 months',
    value:
      'Construction has not started, expect completion in more than 6 months',
  },
  {
    label:
      'Construction has not started, construction timeline currently unknown',
    value:
      'Construction has not started, construction timeline currently unknown',
  },
];

export type InitialValues = z.infer<ReturnType<typeof getValidationSchema>>;

export const urlParamsSchema = z.object({
  loanAmount: z.coerce.number().optional(),
  interestRate: z.coerce.number().optional(),
  productId: z.string().optional(),
  installerProductId: z.string().optional(),
  y1production: z.coerce.number().optional(),
  y1saving: z.coerce.number().optional(),
  systemLocation: z.string().optional(),
  systemSize: z.coerce.number().optional(),
  recInfo: z.string().optional(),
  isSpecial: z.string().optional(),
  specialPaymentPercentage: z.coerce.number().optional(),
});

export default getValidationSchema;
