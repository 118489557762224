/* eslint-disable indent */
import { USER_URL, USER_ACCOUNT_URL } from '../../constants';
import {
  BadRequest,
  GeneralError,
  ServerError,
  UserNotFound,
} from '../../utils/errors';
import { api } from './API';

export const getUserAPI = async () => {
  try {
    const response = await api().get(USER_URL);
    handleAPIResponse(response);
    return response.data.data;
  } catch (error) {
    console.error(error);
    handleAPIResponse(error.response);
  }
};

export const updateUserAPI = async (userData) => {
  try {
    const response = await api().put(USER_URL, userData);
    handleAPIResponse(response);
    return response.data;
  } catch (error) {
    console.error(error);
    handleAPIResponse(error.response);
  }
};

export const updateAccountAPI = async (accountData) => {
  try {
    const response = await api().put(USER_ACCOUNT_URL, accountData);
    handleAPIResponse(response);
    return response.data;
  } catch (error) {
    console.error(error);
    handleAPIResponse(error.response);
  }
};

const handleAPIResponse = (response) => {
  const { status, data } = response;
  // Most of default django errors set 'detail' field in response
  const detail = data?.detail;
  // If detail field is set, put it's contents as the message for the error
  const errorData = detail ? { message: detail } : { data };
  switch (status) {
    case 200:
    case 201:
      break;

    case 400:
      throw new BadRequest(errorData);

    case 401:
      throw new UserNotFound(errorData);

    case 503:
      throw new ServerError(errorData);

    default:
      throw new GeneralError(errorData);
  }
};
