import { FC, useEffect, useMemo, useRef } from 'react';
import { InitialValues } from '../schema';
import { Box, Grid, Typography } from '@mui/material';
import NumberField from '../../../../hook_form_fields/NumberField';
import { CustomTooltip } from '../../../../CustomTooltip';
import NetFundingTooltipText from '../../../../pricing-calculator/NetFundingTooltipText';
import { InfoIcon } from '../../../../loan_application/icons';
import { useAuth } from '../../../../../hooks';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { currencyFormatter } from '../../../../../utils/formatters';
import SelectField from '../../../../hook_form_fields/SelectField';
import NewBorrowerFormFieldset from '../NewBorrowerFormFieldset';
import { ProductFamily } from '../../../../../../../../types/api';
import { Choice } from '../../../../../../../../types/utility';
import { SolarEconomics } from '../NewBorrowerForm';
import { getLoanYearsOptions } from '../../../../../utils/helpers';
import { SPECIAL_PAYMENT_REQUEST_OPTIONS } from '../../../../../constants';

type LoanInformationProps = {
  control: Control<InitialValues & SolarEconomics>;
  setValue: UseFormSetValue<InitialValues & SolarEconomics>;
};

export type InstallerProductModel = {
  amortizationYears: number;
  dealerFee: number;
  id: string;
  loanTermYears: number;
  productFamily: ProductFamily;
  rate: number;
  rateScenario: string;
  startDate?: Date;
};

export const LoanInformation: FC<LoanInformationProps> = ({
  control,
  setValue,
}) => {
  const { account } = useAuth();

  const [
    loan_amount,
    loanType,
    loanTerm,
    loan_product,
    special_payment_percentage,
  ] = useWatch({
    control,
    name: [
      'application.loan_amount',
      'loanType',
      'loanTerm',
      'application.loan_product',
      'application.special_payment_percentage',
    ],
  });
  const numberLoanAmount = Number(loan_amount);
  const installerProducts = useMemo(() => account?.products ?? [], [account]);

  const installerProductList = useMemo(() => {
    // When creating a new loan app, only Current Interest Rates will be available
    const currentInstallerProducts: InstallerProductModel[] =
      installerProducts.map((installerProduct) => ({
        amortizationYears: installerProduct.product.amortization_years,
        dealerFee: installerProduct.dealer_fee,
        id: installerProduct.id,
        loanTermYears: installerProduct.product.loan_term_years,
        productFamily: installerProduct.product.family,
        rate: installerProduct.product.apr_percentage,
        rateScenario: installerProduct.product_rate_scenario,
      }));

    return currentInstallerProducts;
  }, [installerProducts]);

  const productFamilyOptions = useMemo(
    () =>
      Array.from(
        new Set(
          installerProductList
            .map(({ productFamily }) => productFamily)
            .filter((family) =>
              [
                ProductFamily.STANDARD,
                ProductFamily.SPECIAL_PAYMENT,
                ProductFamily.ELECTIVE_PAY,
              ].includes(family)
            )
        ).values()
      ).map((productFamily) => ({
        label:
          productFamily === ProductFamily.STANDARD
            ? 'Standard'
            : productFamily === ProductFamily.SPECIAL_PAYMENT
            ? 'Special Payment'
            : productFamily === ProductFamily.PREMIUM
            ? 'Premium'
            : productFamily === ProductFamily.ELECTIVE_PAY
            ? 'Elective Pay'
            : '',
        value: productFamily,
      })),
    [installerProductList]
  );

  // Get a list of options for each select field
  const loanYearsOptions = useMemo(
    () => getLoanYearsOptions(installerProductList, loanType, true),
    [loanType, installerProductList]
  );

  // When creating a new loan app, only Current Interest Rates will be available
  const interestRateOptions = useMemo(() => {
    const currentTerm = loanTerm ? parseInt(loanTerm.slice(0, 2)) : -1;
    const currentAmort = loanTerm
      ? loanTerm.slice(-2) == '00'
        ? null
        : parseInt(loanTerm.slice(-2))
      : -1;
    const interestRateOptions: Choice[] = installerProductList
      .filter(({ productFamily }) =>
        loanType === ProductFamily.STANDARD
          ? productFamily === ProductFamily.STANDARD ||
            productFamily === ProductFamily.PREMIUM
          : productFamily === loanType
      )
      .filter(
        ({ loanTermYears, amortizationYears }) =>
          loanTermYears === currentTerm && amortizationYears === currentAmort
      )
      .sort((a, b) => Number(a.rate) - Number(b.rate))
      .map(({ id, rate }) => ({
        value: `${id}`,
        label: `${rate}%`,
      }));

    return interestRateOptions;
  }, [loanTerm, loanType, installerProductList]);

  const selectedProductIsPremium = useMemo(
    () =>
      account?.products?.find(
        (installerProduct) => installerProduct.id === loan_product
      )?.product.family === ProductFamily.PREMIUM,
    [account?.products, loan_product]
  );
  const showPremiumProductWarning =
    selectedProductIsPremium && numberLoanAmount > 1000000;

  const previousLoanTypeAndTerm = useRef({ loanType, loanTerm });
  // Reset the loan term and pricing grouping when the loan type changes
  useEffect(() => {
    // If the previous loan term is not in the new list of options, reset the loan term
    const loanTermOptionsIncludePreviousLoanTerm =
      previousLoanTypeAndTerm.current.loanTerm &&
      loanYearsOptions.some(
        (obj) => obj.value === previousLoanTypeAndTerm.current.loanTerm
      );
    const installerProduct = account?.products?.find(
      (installerProduct) => installerProduct.id === loan_product
    );
    setValue('economics.loanAmount', Number(loan_amount));
    setValue(
      'economics.interestRate',
      installerProduct?.product.apr_percentage
    );
    if (previousLoanTypeAndTerm.current.loanType !== loanType) {
      setValue('application.loan_product', '');
      if (!loanTermOptionsIncludePreviousLoanTerm) setValue('loanTerm', '');
    }
    if (previousLoanTypeAndTerm.current.loanTerm !== loanTerm) {
      setValue('application.loan_product', '', {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
    previousLoanTypeAndTerm.current = { loanType, loanTerm };
  }, [
    loanTerm,
    loanType,
    loanYearsOptions,
    account,
    loan_amount,
    loan_product,
    setValue,
  ]);

  useEffect(() => {
    // If Special Payment is selected and its value is not coming from the Calculator, pre-select to 30%
    if (
      loanType === ProductFamily.SPECIAL_PAYMENT &&
      !special_payment_percentage
    ) {
      setValue('application.special_payment_percentage', 30, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      }); // if not Special Payment or changed loan type to Standard, reset the value
    } else if (loanType !== ProductFamily.SPECIAL_PAYMENT) {
      setValue('application.special_payment_percentage', undefined, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  }, [loanType, setValue]);

  return (
    <NewBorrowerFormFieldset title={'Loan Information'}>
      <Grid container maxWidth="md" spacing={2}>
        <Grid item xs={12} md={4}>
          <SelectField
            control={control}
            name={'loanType' as const}
            fullWidth
            label={'Loan Type'}
            tooltip={
              <>
                <b>Standard Loans</b> have fixed terms and interest rates, while{' '}
                <b>Special Payment Loans</b> have lower monthly payments and a
                special payment on the 18th month allowing the borrower to
                leverage tax credits.
              </>
            }
            choices={productFamilyOptions}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectField
            control={control}
            name={'loanTerm' as const}
            fullWidth
            label={'Loan Term / Amortization'}
            choices={loanYearsOptions.map((obj) => ({
              label: obj.label,
              value: obj.value,
            }))}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectField
            control={control}
            name={'application.loan_product'}
            fullWidth
            label={'Interest Rate'}
            //User should select loan type and term before selecting interest rate
            disabled={
              interestRateOptions.length === 0 || !loanTerm || !loanType
            }
            choices={interestRateOptions}
          />
        </Grid>
        {loanType === ProductFamily.SPECIAL_PAYMENT && (
          <Grid item xs={12} md={12}>
            <SelectField
              control={control}
              name={'application.special_payment_percentage' as const}
              sx={{
                width: '100%',
              }}
              containerSx={{ width: 0.32 }}
              label={'Special Payment Request'}
              choices={SPECIAL_PAYMENT_REQUEST_OPTIONS}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <NumberField
            thousandSeparator
            startAdornmentText="$"
            decimalScale={2}
            autoFocus={true}
            control={control}
            name="application.loan_amount"
            data-testid="loanAmount"
            label="Loan Amount"
            required
            warningText={
              showPremiumProductWarning ? (
                <Typography sx={{ fontSize: 12 }} color={'error'}>
                  Loan amount must be less than $1,000,000 for Scenario 4 loans
                </Typography>
              ) : undefined
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            display={'flex'}
            flexDirection="column"
            justifyContent={'space-between'}
            height={'100%'}
          >
            <Typography variant="body2" color="textSecondary" ml={4}>
              The amount you will receive from Sunstone (Net Funding){' '}
              <CustomTooltip title={<NetFundingTooltipText />}>
                <InfoIcon sx={{ fontSize: 12 }} />
              </CustomTooltip>
            </Typography>
            <Typography
              variant="body1"
              fontWeight={'bold'}
              color="textPrimary"
              ml={4}
            >
              {numberLoanAmount && loan_product
                ? account &&
                  currencyFormatter.format(
                    (numberLoanAmount *
                      (100 -
                        (account.products?.find(
                          (product) => product.id === loan_product
                        )?.dealer_fee ?? 0))) /
                      100
                  )
                : '–'}
            </Typography>
          </Box>
        </Grid>
        {account?.milestone_program === 'Approved' && (
          <Grid item xs={12} sm={6}>
            <SelectField
              control={control}
              name={'application.borrower_advance'}
              fullWidth
              label={'Does this loan need milestone payments?'}
              tooltip={
                <Typography textAlign={'left'}>
                  Selecting &quot;Yes&quot; will enable Milestone payments for
                  this loan. If approved, the Borrower will receive a loan
                  agreement that includes the milestones as well as the
                  Milestone Program interest rate and must sign this agreement
                  to enable the program.
                </Typography>
              }
              choices={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
              ]}
            />
          </Grid>
        )}
      </Grid>
    </NewBorrowerFormFieldset>
  );
};

export default LoanInformation;
