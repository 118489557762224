import { Box } from '@mui/material';
import Logo from './logos/Logo';
import { Z_INDEXES } from '../constants';

const SlashScreen = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: Z_INDEXES.SPLASH_SCREEN,
    }}
  >
    <Logo />
  </Box>
);

export default SlashScreen;
