import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { Box, Button, Container, Link, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useQueryClient } from 'react-query';
import { FC, useEffect } from 'react';
import { useAuth } from '../../hooks';

type Props = {
  error: unknown;
  errorInfo: unknown;
  resetErrorBoundary: () => void;
};

const InternalClientError: FC<Props> = ({
  error,
  errorInfo,
  resetErrorBoundary,
}) => {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const error_code =
    typeof location.state === 'object' &&
    location.state &&
    'error_code' in location.state
      ? location.state?.error_code
      : null;
  const queryClient = useQueryClient();
  const { user, account } = useAuth();

  useEffect(() => {
    queryClient.resetQueries();
  }, [queryClient]);
  // Enrich and log the error to New Relic
  useEffect(() => {
    const originalErrorMessage = error?.toString() ?? JSON.stringify(error);
    let enrichedErrorMessage = originalErrorMessage;
    // set version in the enriched error message
    enrichedErrorMessage = `${enrichedErrorMessage}\n\nVersion:\n${__versionString__}`;
    if (user) {
      enrichedErrorMessage = `${enrichedErrorMessage}\n\nUser:\n${JSON.stringify(
        user,
        null,
        2
      )}`;
    }
    if (account) {
      enrichedErrorMessage = `${enrichedErrorMessage}\n\nAccount:\n${JSON.stringify(
        account,
        null,
        2
      )}`;
    }
    if (errorInfo) {
      enrichedErrorMessage = `${enrichedErrorMessage}\n\nReact Error Info:\n${JSON.stringify(
        errorInfo,
        null,
        2
      )}`;
    }

    if (error instanceof Error) {
      error.message = enrichedErrorMessage;
    }
    const parsedError =
      error instanceof Error ? error : new Error(enrichedErrorMessage);
    const parsedErrorInfo =
      typeof errorInfo === 'object' && errorInfo ? errorInfo : {};
    if (window.newrelic)
      newrelic.noticeError(parsedError, {
        ...parsedErrorInfo,
        version: __versionString__,
      });
    else console.error(error, errorInfo);
  }, [account, error, errorInfo, user]);

  return (
    <>
      <Helmet>
        <title>Error: Internal Error | Sunstone Credit</title>
      </Helmet>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: 'background.default',
          display: 'flex',
          minHeight: '100%',
          px: 3,
          py: '80px',
        }}
      >
        <Container maxWidth="xs">
          <Box
            sx={{
              margin: '1.5rem auto',
              display: 'box',
              height: 'auto',
              width: '9.185rem',
            }}
          >
            <Box
              alt="Something went wrong!"
              component="img"
              src={'/static/error/internal_error.svg'}
            />
          </Box>
          <Typography
            align="center"
            color="textPrimary"
            variant={mobileDevice ? 'h4' : 'h5'}
          >
            Something went wrong!
          </Typography>
          <Typography
            align="center"
            color="textSecondary"
            sx={{ mt: 2 }}
            variant="body1"
          >
            {error_code && (
              <>
                Error code: {error_code}
                <br />
              </>
            )}
            Try refreshing the page. If the problem persists, please contact -{' '}
            <Link component="a" href="mailto:support@sunstonecredit.com">
              support@sunstonecredit.com
            </Link>{' '}
            for more information.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 4,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ px: 4, py: 2 }}
              onClick={resetErrorBoundary}
            >
              Back to Page
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default InternalClientError;
