import React, { ReactNode } from 'react';
import { useField } from 'formik';
import {
  Radio,
  FormControl,
  FormControlProps,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
  FormLabel,
} from '@mui/material';
import { Choice } from '../../../../../types/utility';

type RadioGroupFieldProps = {
  name: string;
  choices: Choice[];
  label: ReactNode;
  readOnly?: boolean;
  touched?: boolean;
  getValue?: <T = any>(e: React.ChangeEvent<HTMLInputElement>) => T;
} & FormControlProps;

export default function RadioGroupFields(props: RadioGroupFieldProps) {
  const {
    name,
    label,
    choices,
    readOnly,
    getValue,
    touched: defaultTouched,
    ...rest
  } = props;
  const [field, meta, helper] = useField(name);
  const { setValue } = helper;
  const { touched: fieldTouched, error } = meta;
  const touched = defaultTouched || fieldTouched;

  function _renderHelperText() {
    if (touched && error) {
      return (
        <FormHelperText error sx={{ mx: 0 }}>
          {error}
        </FormHelperText>
      );
    }
  }

  function _onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = getValue ? getValue(e) : e.target.value;
    setValue(value);
  }

  return (
    <FormControl {...rest}>
      <FormLabel id={field.name}>{label}</FormLabel>
      <RadioGroup
        aria-labelledby={field.name}
        onChange={readOnly ? undefined : _onChange}
        name={field.name}
        value={field.value}
      >
        {choices.map((choice, i) => (
          <FormControlLabel
            key={i}
            value={choice.value}
            control={<Radio />}
            label={choice.label}
          />
        ))}
      </RadioGroup>
      {_renderHelperText()}
    </FormControl>
  );
}
