import { SvgIcon } from '@mui/material';
import { forwardRef } from 'react';
import FinancialInfoSvg from './FinancialInfo.svg';

export const FinancialInfo = forwardRef(function FinancialInfo(props, ref) {
  return (
    <SvgIcon
      {...props}
      ref={ref}
      component={FinancialInfoSvg}
      viewBox="0 0 40 41"
    />
  );
});
