import { Helmet } from 'react-helmet-async';
import { Box, Paper } from '@mui/material';
import LoginJWT from '../../components/authentication/LoginJWT';
import LogoWithName from '../../components/logos/LogoWithName';
import DashboardFooter from '../../components/dashboard/DashboardFooter';

const Login = () => {
  return (
    <>
      <Helmet>
        <title>Login | Sunstone Credit</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          py: 5,
        }}
      >
        <Paper
          sx={{
            pt: { xs: 2, sm: 3, md: 7.75 },
            pb: { xs: 2, sm: 4, md: 8.5 },
            px: { xs: 3, sm: 7, md: 14 },
            width: { xs: 300, sm: 450, md: 720 },
            borderRadius: 1.5,
            textAlign: { xs: 'center', md: 'unset' },
          }}
        >
          <Box display="flex" justifyContent="center">
            <a href={__landingUrl__}>
              <LogoWithName height={41.5} />
            </a>
          </Box>
          <LoginJWT />
        </Paper>
        <Box sx={{ display: { md: 'block', xs: 'none' } }}>
          <DashboardFooter />
        </Box>
      </Box>
    </>
  );
};

export default Login;
