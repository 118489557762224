import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import SplashScreen from './components/SplashScreen';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import { getUserRoutes } from './routes';
import theme from './theme';
import { SessionExpiredModal } from './components/SessionExpiredModal';

const App = () => {
  const { isInitialized, user } = useAuth();
  const routes = getUserRoutes(user);
  const content = useRoutes(routes);
  useScrollReset();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster position="top-center" />
        {isInitialized ? content : <SplashScreen />}
        <SessionExpiredModal />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
