import { Box, Typography, Link, useTheme } from '@mui/material';
import { darken } from '@mui/system';
import { lighten } from '@mui/material/styles';
import { formatPhoneNumber } from '../loan_application/utils/helpers';
import { Contact } from '../../../../../types/api';
import {
  BORROWER_SUCCESS_CONTACT_INFO,
  PARTNER_SUCCESS_CONTACT_INFO,
} from '../../constants';

type ContactInformationProps = {
  isMobile: boolean;
  user: Contact;
};

const ContactInformation = ({ isMobile, user }: ContactInformationProps) => {
  const theme = useTheme();
  const isInstaller = user.is_installer;
  const isConsultant = user.is_consultant;

  const isInstallerOrConsultant = isInstaller || isConsultant;

  // For now we are using 'anticipated_annual_loan_volume' to determine which installers are shown a generic contact and which
  // the account owner contact info
  const displayOwner =
    isInstallerOrConsultant &&
    user.account.anticipated_annual_loan_volume !== 1;

  const installerOrConsultantManagerData = {
    name: displayOwner
      ? user?.account.owner.name
      : PARTNER_SUCCESS_CONTACT_INFO.NAME,
    email: displayOwner
      ? user?.account.owner.email
      : PARTNER_SUCCESS_CONTACT_INFO.EMAIL,
    phone:
      displayOwner && user?.account.owner.phone
        ? formatPhoneNumber(user?.account?.owner.phone)
        : '',
  };

  const accountManagerData = {
    name: isInstallerOrConsultant
      ? installerOrConsultantManagerData.name
      : BORROWER_SUCCESS_CONTACT_INFO.NAME,
    email: isInstallerOrConsultant
      ? installerOrConsultantManagerData.email
      : BORROWER_SUCCESS_CONTACT_INFO.EMAIL,
    phone: isInstallerOrConsultant
      ? installerOrConsultantManagerData.phone
      : BORROWER_SUCCESS_CONTACT_INFO.PHONE,
  };

  const dynamicFontSize = 'clamp(12px, 1vw, 14px)';

  return (
    <>
      {isMobile ? (
        <Box
          sx={{
            paddingTop: '0px',
            display: 'flex',
            flexDirection: 'column',
            borderTop: `1px solid ${lighten(theme.palette.divider, 0.5)}`,
            borderBottom: `1px solid ${lighten(theme.palette.divider, 0.5)}`,
            padding: '.5rem 1rem',
            flexBasis: '1%',
            justifyContent: 'center',
          }}
          flexWrap="wrap"
          gap={0.7}
        >
          <Typography color={darken(theme.palette.divider, 0.3)}>
            <Typography component="span" fontWeight={500}>
              {isInstallerOrConsultant
                ? displayOwner
                  ? 'Your dedicated Account Manager: '
                  : 'Need Help? Contact the '
                : 'Having Issues? Email our '}
            </Typography>

            <Typography component="span" color="primary" fontWeight={800}>
              {isInstallerOrConsultant ? (
                displayOwner ? (
                  ''
                ) : (
                  <Link
                    href={`mailto:${accountManagerData.email}`}
                    color="primary"
                    sx={{ textDecoration: 'none' }}
                  >
                    {accountManagerData.name}
                  </Link>
                )
              ) : (
                <Link
                  href={`mailto:${accountManagerData.email}`}
                  color="primary"
                  sx={{ textDecoration: 'none' }}
                >
                  {`${accountManagerData.name} Team`}
                </Link>
              )}
            </Typography>
          </Typography>
          <Typography
            color="primary"
            fontWeight={isInstallerOrConsultant ? 800 : 500}
          >
            {isInstallerOrConsultant ? (
              displayOwner ? (
                <Box>
                  <Typography component="span" color="black" fontWeight={800}>
                    {`${accountManagerData.name} `}
                  </Typography>
                  <Link
                    href={`mailto:${accountManagerData.email}`}
                    color="primary"
                    sx={{ textDecoration: 'none' }}
                  >
                    {accountManagerData.email}
                  </Link>
                </Box>
              ) : (
                <Typography component="span" color="primary" fontWeight={500}>
                  <Typography
                    component="span"
                    color={darken(theme.palette.divider, 0.3)}
                    fontWeight={500}
                  >
                    at{' '}
                  </Typography>

                  <Link
                    href={`mailto:${accountManagerData.email}`}
                    color="primary"
                    fontWeight={800}
                    sx={{ textDecoration: 'none', flexWrap: 'wrap' }}
                    flexWrap="wrap"
                  >
                    {accountManagerData.email}
                  </Link>
                </Typography>
              )
            ) : (
              <Typography component="span" color="primary" fontWeight={500}>
                <Typography
                  component="span"
                  color={darken(theme.palette.divider, 0.3)}
                  fontWeight={500}
                >
                  or call{' '}
                </Typography>

                <Typography component="span" fontWeight={800}>
                  {accountManagerData.phone}
                </Typography>
              </Typography>
            )}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            paddingTop: '0px',
            display: 'flex',
            flexBasis: '100%',
            justifyContent: 'center',
            flexDirection: 'column',
            border: `1px solid ${theme.palette.divider}`,
            maxWidth: 'fit-content',
            padding: '.5rem 1rem',
            borderRadius: '8px',
            flexGrow: 0,
            flexShrink: 1,
          }}
          flexWrap="wrap"
        >
          <Typography
            color={darken(theme.palette.divider, 0.3)}
            fontSize={dynamicFontSize}
          >
            <Typography
              component="span"
              fontWeight={800}
              fontSize={dynamicFontSize}
            >
              {isInstallerOrConsultant
                ? displayOwner
                  ? 'Your Account Manager: '
                  : 'Need Help? Contact the '
                : 'Having Issues? Contact '}
            </Typography>

            <Typography
              component="span"
              color={
                isInstallerOrConsultant && displayOwner
                  ? darken(theme.palette.divider, 0.3)
                  : 'primary'
              }
              fontWeight={isInstallerOrConsultant && displayOwner ? 500 : 800}
              fontSize={dynamicFontSize}
            >
              {accountManagerData.name}
            </Typography>
            {isInstaller && displayOwner ? '' : ' at'}
          </Typography>

          <Typography
            color="primary"
            fontWeight={isInstallerOrConsultant ? 800 : 500}
            fontSize={dynamicFontSize}
          >
            {isInstallerOrConsultant ? (
              displayOwner && accountManagerData.phone ? (
                `${accountManagerData.email} | ${accountManagerData.phone}`
              ) : (
                accountManagerData.email
              )
            ) : (
              <Typography
                component="span"
                color="primary"
                fontWeight={500}
                fontSize={dynamicFontSize}
              >
                {`${accountManagerData.email} `}
                <Typography
                  component="span"
                  color={darken(theme.palette.divider, 0.3)}
                  fontWeight={500}
                  fontSize={dynamicFontSize}
                >
                  or call{' '}
                </Typography>
                {accountManagerData.phone}
              </Typography>
            )}
          </Typography>
        </Box>
      )}
    </>
  );
};
export default ContactInformation;
