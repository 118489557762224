import { SvgIcon, SvgIconProps } from '@mui/material';
import { forwardRef } from 'react';
import Icon from './Standard.svg';

const StandardIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  function StandardIcon(props, ref) {
    return (
      <SvgIcon ref={ref} viewBox="0 0 55 55" {...props}>
        <Icon />
      </SvgIcon>
    );
  }
);

export default StandardIcon;
