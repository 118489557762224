import FormFieldset, {
  FormFieldsetProps,
} from '../../../loan_application/components/FormFieldset';
import { FC } from 'react';

export const NewBorrowerFormFieldset: FC<FormFieldsetProps> = ({
  children,
  ...rest
}) => {
  return (
    <FormFieldset
      sx={{
        px: 3,
        py: 4,
      }}
      titleProps={{
        variant: 'h6',
      }}
      {...rest}
    >
      {children}
    </FormFieldset>
  );
};

export default NewBorrowerFormFieldset;
