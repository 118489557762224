import * as Yup from 'yup';
import {
  genericRequiredFieldError,
  SYSTEM_POWER_USAGE_VALUES,
  INTERVAL_DATES,
} from '../../../constants';
import {
  getDocumentValidationSchema,
  getStringFieldValidationSchema,
} from '../../../utils/validation';
import loanFormModel, { taxDocuments } from './loanFormModel';
import {
  companyDocumentsValidation,
  hoaDocumentsValidation,
  solarSystemDocumentsValidation,
  additionalDocumentationValidation,
} from './requiredDocumentsValidation';
import { LoanCategory } from '../../../../../../types/api';

const {
  formField: {
    // System Power
    systemPowerUsage,
    borrowerBusinessEstablishedYear,
    // Outstanding loans
    loanLender,
    loanBalance,
    loanPurpose,
    loanMonthlyPayment,
    loanYearOfMaturity,
    systemPowerCompanyType,
    borrowerAttestsNoDebt,
    borrowerEsignName,
    borrowerSignerRole,
    isPG,
    principalAverageCashBalance,
    documents: {
      bankStatementLastMonth,
      bankStatement1Month,
      bankStatement2Month,
      plaidAssetReport,
    },
    pgFiledTaxes1YearAgo,
    pgFiledTaxes2YearsAgo,
    pgFiledTaxes3YearsAgo,
    principalSsn,
    principalIsPG,
    applicantSameAsPrincipal,
    outstandingLoanType,
    loanCategory,
  },
} = loanFormModel;

const {
  applicantFiledTaxReturnsInThePast,
  applicantFiledLastYearTaxReturns,
  irsFormSS4,
  taxReturnsExtension,
  pgTaxes1YearAgo,
  pgExtension1YearAgo,
  pgTaxes2YearsAgo,
  pgExtension2YearsAgo,
  pgTaxes3YearsAgo,
  pgExtension3YearsAgo,
} = taxDocuments;

const defaultApplicantName = 'Applicant';
const taxDocumentsValidation = {
  [applicantFiledTaxReturnsInThePast.name]: Yup.string()
    .nullable()
    .required(genericRequiredFieldError),

  [applicantFiledLastYearTaxReturns.name]: Yup.string()
    .nullable()
    .when(applicantFiledTaxReturnsInThePast.name, {
      is: (applicantFiledTaxReturnsInThePast) =>
        applicantFiledTaxReturnsInThePast === 'Yes' && INTERVAL_DATES().MayOct,
      then: (schema) => schema.required(genericRequiredFieldError),
      else: (schema) => schema.notRequired(),
    }),
  [irsFormSS4.name]: getDocumentValidationSchema(
    irsFormSS4.getLabel(defaultApplicantName),
    false
  ).when(applicantFiledTaxReturnsInThePast.name, {
    is: (applicantFiledTaxReturnsInThePast) =>
      applicantFiledTaxReturnsInThePast === 'No',
    then: getDocumentValidationSchema(
      irsFormSS4.getLabel(defaultApplicantName),
      true
    ),
    else: getDocumentValidationSchema(
      irsFormSS4.getLabel(defaultApplicantName),
      false
    ),
  }),

  [taxReturnsExtension.name]: getDocumentValidationSchema(
    taxReturnsExtension.getLabel(defaultApplicantName),
    false
  ).when(
    [
      applicantFiledTaxReturnsInThePast.name,
      applicantFiledLastYearTaxReturns.name,
      systemPowerUsage.name,
    ],
    {
      is: (
        applicantFiledTaxReturnsInThePast,
        applicantFiledLastYearTaxReturns,
        systemPowerUsage
      ) =>
        taxReturnsExtension.displayCondition(
          applicantFiledTaxReturnsInThePast,
          applicantFiledLastYearTaxReturns
        ) && taxReturnsExtension.getRequired(systemPowerUsage),
      then: getDocumentValidationSchema(
        taxReturnsExtension.getLabel(defaultApplicantName),
        true
      ),
      else: getDocumentValidationSchema(
        taxReturnsExtension.getLabel(defaultApplicantName),
        false
      ),
    }
  ),
  [taxDocuments.taxReturns1Year.name]: getDocumentValidationSchema(
    taxDocuments.taxReturns1Year.getLabel(defaultApplicantName),
    false
  ).when(
    [
      applicantFiledTaxReturnsInThePast.name,
      borrowerBusinessEstablishedYear.name,
      systemPowerUsage.name,
    ],
    {
      is: (filedTaxReturns, businessEstablishedYear, systemPowerUsage) =>
        taxDocuments.taxReturns1Year.displayCondition(
          filedTaxReturns,
          businessEstablishedYear
        ) && taxDocuments.taxReturns1Year.getRequired(systemPowerUsage),
      then: getDocumentValidationSchema(
        taxDocuments.taxReturns1Year.getLabel(defaultApplicantName),
        true
      ),
      else: getDocumentValidationSchema(
        taxDocuments.taxReturns1Year.getLabel(defaultApplicantName),
        false
      ),
    }
  ),
  [taxDocuments.taxReturns2Year.name]: getDocumentValidationSchema(
    taxDocuments.taxReturns2Year.getLabel(defaultApplicantName),
    false
  ).when(
    [
      applicantFiledTaxReturnsInThePast.name,
      borrowerBusinessEstablishedYear.name,
      isPG.name,
      systemPowerUsage.name,
    ],
    {
      is: (
        filedTaxReturns,
        businessEstablishedYear,
        isPersonalGuarantor,
        systemPowerUsage
      ) => {
        taxDocuments.taxReturns2Year.displayCondition(
          filedTaxReturns,
          businessEstablishedYear
        ) &&
          taxDocuments.taxReturns2Year.getRequired(systemPowerUsage) &&
          !isPersonalGuarantor;
      },
      then: getDocumentValidationSchema(
        taxDocuments.taxReturns2Year.getLabel(defaultApplicantName),
        true
      ),
      else: getDocumentValidationSchema(
        taxDocuments.taxReturns2Year.getLabel(defaultApplicantName),
        false
      ),
    }
  ),
  [taxDocuments.taxReturnsLastYear.name]: getDocumentValidationSchema(
    taxDocuments.taxReturnsLastYear.getLabel(defaultApplicantName),
    false
  ).when(
    [
      applicantFiledTaxReturnsInThePast.name,
      applicantFiledLastYearTaxReturns.name,
      systemPowerUsage.name,
    ],
    {
      is: (
        applicantFiledTaxReturnsInThePast,
        applicantFiledLastYearTaxReturns,
        systemPowerUsage
      ) =>
        taxDocuments.taxReturnsLastYear.displayCondition(
          applicantFiledTaxReturnsInThePast,
          applicantFiledLastYearTaxReturns
        ) && taxDocuments.taxReturnsLastYear.getRequired(systemPowerUsage),
      then: getDocumentValidationSchema(
        taxDocuments.taxReturnsLastYear.getLabel(defaultApplicantName),
        true
      ),
      else: getDocumentValidationSchema(
        taxDocuments.taxReturnsLastYear.getLabel(defaultApplicantName),
        false
      ),
    }
  ),
  [taxDocuments.financialStatementQ1.name]: getDocumentValidationSchema(
    taxDocuments.financialStatementQ1.getLabel(defaultApplicantName),
    false
  ).when([isPG.name, systemPowerUsage.name], {
    is: (isPersonalGuarantor, systemPowerUsage) => {
      taxDocuments.financialStatementQ1.displayCondition(isPersonalGuarantor) &&
        taxDocuments.financialStatementQ1.getRequired(systemPowerUsage);
    },
    then: getDocumentValidationSchema(
      taxDocuments.financialStatementQ1.getLabel(defaultApplicantName),
      true
    ),
    else: getDocumentValidationSchema(
      taxDocuments.financialStatementQ1.getLabel(defaultApplicantName),
      false
    ),
  }),
  [taxDocuments.financialStatementQ2.name]: getDocumentValidationSchema(
    taxDocuments.financialStatementQ2.getLabel(defaultApplicantName),
    false
  ).when([isPG.name, systemPowerUsage.name], {
    is: (isPersonalGuarantor, systemPowerUsage) => {
      taxDocuments.financialStatementQ2.displayCondition(isPersonalGuarantor) &&
        taxDocuments.financialStatementQ2.getRequired(systemPowerUsage);
    },
    then: getDocumentValidationSchema(
      taxDocuments.financialStatementQ2.getLabel(defaultApplicantName),
      true
    ),
    else: getDocumentValidationSchema(
      taxDocuments.financialStatementQ2.getLabel(defaultApplicantName),
      false
    ),
  }),
  [taxDocuments.financialStatementQ3.name]: getDocumentValidationSchema(
    taxDocuments.financialStatementQ3.getLabel(defaultApplicantName),
    false
  ).when([isPG.name, systemPowerUsage.name], {
    is: (isPersonalGuarantor, systemPowerUsage) => {
      taxDocuments.financialStatementQ3.displayCondition(isPersonalGuarantor) &&
        taxDocuments.financialStatementQ3.getRequired(systemPowerUsage);
    },
    then: getDocumentValidationSchema(
      taxDocuments.financialStatementQ3.getLabel(defaultApplicantName),
      true
    ),
    else: getDocumentValidationSchema(
      taxDocuments.financialStatementQ3.getLabel(defaultApplicantName),
      false
    ),
  }),
  [taxDocuments.financialStatementLastYearQ3.name]: getDocumentValidationSchema(
    taxDocuments.financialStatementLastYearQ3.getLabel(defaultApplicantName),
    false
  ).when([systemPowerUsage.name, applicantFiledTaxReturnsInThePast.name], {
    is: (systemPowerUsage, applicantFiledTaxReturnsInThePast) => {
      taxDocuments.financialStatementLastYearQ3.displayCondition(
        applicantFiledTaxReturnsInThePast
      ) &&
        taxDocuments.financialStatementLastYearQ3.getRequired(systemPowerUsage);
    },
    then: getDocumentValidationSchema(
      taxDocuments.financialStatementLastYearQ3.getLabel(defaultApplicantName),
      true
    ),
    else: getDocumentValidationSchema(
      taxDocuments.financialStatementLastYearQ3.getLabel(defaultApplicantName),
      false
    ),
  }),
  [taxDocuments.financialStatementFullYear.name]: getDocumentValidationSchema(
    taxDocuments.financialStatementFullYear.getLabel(defaultApplicantName),
    false
  ).when(
    [
      systemPowerUsage.name,
      applicantFiledTaxReturnsInThePast.name,
      applicantFiledLastYearTaxReturns.name,
    ],
    {
      is: (
        systemPowerUsage,
        applicantFiledTaxReturnsInThePast,
        applicantFiledLastYearTaxReturns
      ) => {
        taxDocuments.financialStatementFullYear.displayCondition(
          applicantFiledTaxReturnsInThePast,
          applicantFiledLastYearTaxReturns
        ) &&
          taxDocuments.financialStatementFullYear.getRequired(systemPowerUsage);
      },
      then: getDocumentValidationSchema(
        taxDocuments.financialStatementFullYear.getLabel(defaultApplicantName),
        true
      ),
      else: getDocumentValidationSchema(
        taxDocuments.financialStatementFullYear.getLabel(defaultApplicantName),
        false
      ),
    }
  ),
};

const principalsDocumentsValidation = {
  principals: Yup.array().of(
    Yup.object().shape({
      documents: Yup.object().shape({
        [pgTaxes1YearAgo.name]: Yup.object().shape({
          url: Yup.string().test(
            'check-required-if-undefined',
            'This document is required',
            function (value) {
              // this.from[2] traverses back up the object tree to the principal so that we can read principal_type
              if (this.from[2].value[applicantSameAsPrincipal.name] === 'Yes') {
                if (
                  this.from[2].value[pgFiledTaxes1YearAgo.name] === 'Yes' ||
                  !this.from[2].value[pgFiledTaxes1YearAgo.name]
                ) {
                  return value != null && value !== '';
                }
                return true;
              }
              return true;
            }
          ),
        }),
        [pgExtension1YearAgo.name]: Yup.object().shape({
          url: Yup.string().test(
            'check-required-if-undefined',
            'This document is required',
            function (value) {
              // this.from[2] traverses back up the object tree to the principal so that we can read principal_type
              if (this.from[2].value[pgFiledTaxes1YearAgo.name] === 'No') {
                return value != null && value !== '';
              }
              return true;
            }
          ),
        }),
        [pgTaxes2YearsAgo.name]: Yup.object().shape({
          url: Yup.string().test(
            'check-required-if-undefined',
            'This document is required',
            function (value) {
              // this.from[2] traverses back up the object tree to the principal so that we can read principal_type
              if (this.from[2].value[applicantSameAsPrincipal.name] === 'Yes') {
                if (
                  (this.from[2].value[pgFiledTaxes1YearAgo.name] === 'No' &&
                    this.from[2].value[pgFiledTaxes2YearsAgo.name] === 'Yes') ||
                  (this.from[2].value[pgFiledTaxes1YearAgo.name] === 'No' &&
                    !this.from[2].value[pgFiledTaxes2YearsAgo.name])
                ) {
                  return value != null && value !== '';
                }
                return true;
              }
              return true;
            }
          ),
        }),
        [pgExtension2YearsAgo.name]: Yup.object().shape({
          url: Yup.string().test(
            'check-required-if-undefined',
            'This document is required',
            function (value) {
              // this.from[2] traverses back up the object tree to the principal so that we can read principal_type
              if (
                this.from[2].value[pgFiledTaxes2YearsAgo.name] === 'No' &&
                this.from[2].value[pgFiledTaxes1YearAgo.name] === 'No'
              ) {
                return value != null && value !== '';
              }
              return true;
            }
          ),
        }),
        [pgTaxes3YearsAgo.name]: Yup.object().shape({
          url: Yup.string().test(
            'check-required-if-undefined',
            'This document is required',
            function (value) {
              // this.from[2] traverses back up the object tree to the principal so that we can read principal_type
              if (this.from[2].value[applicantSameAsPrincipal.name] === 'Yes') {
                if (
                  (this.from[2].value[pgFiledTaxes1YearAgo.name] === 'No' &&
                    this.from[2].value[pgFiledTaxes2YearsAgo.name] === 'No' &&
                    this.from[2].value[pgFiledTaxes3YearsAgo.name] === 'Yes') ||
                  (this.from[2].value[pgFiledTaxes1YearAgo.name] === 'No' &&
                    this.from[2].value[pgFiledTaxes2YearsAgo.name] === 'No' &&
                    !this.from[2].value[pgFiledTaxes3YearsAgo.name])
                ) {
                  return value != null && value !== '';
                }
                return true;
              }
              return true;
            }
          ),
        }),
        [pgExtension3YearsAgo.name]: Yup.object().shape({
          url: Yup.string().test(
            'check-required-if-undefined',
            'This document is required',
            function (value) {
              // this.from[2] traverses back up the object tree to the principal so that we can read principal_type
              if (
                this.from[2].value[pgFiledTaxes3YearsAgo.name] === 'No' &&
                this.from[2].value[pgFiledTaxes2YearsAgo.name] === 'No' &&
                this.from[2].value[pgFiledTaxes1YearAgo.name] === 'No'
              ) {
                return value != null && value !== '';
              }
              return true;
            }
          ),
        }),
      }),
    })
  ),
};

export const documentsValidation = {
  ...taxDocumentsValidation,
  ...companyDocumentsValidation,
  ...hoaDocumentsValidation,
  ...solarSystemDocumentsValidation,
  ...additionalDocumentationValidation,
  ...principalsDocumentsValidation,
};

export const bankStatements = {
  // TODO: Can't figure out how to get this to acknowledge when the value is filled in.  Persistent error state.
  // [`principals[0].${principalAverageCashBalance.name}`]: Yup.string()
  //   // .label(`principals[0].${principalAverageCashBalance.label}`)
  //   .nullable()
  //   .when(
  //     [isPG.name],
  //     (isPG, schema) => {
  //       if (isPG === 'Yes') return schema.required(principalAverageCashBalance.requiredErrorMsg);
  //       return schema;
  //     }
  //   ),
  [bankStatementLastMonth.name]: getDocumentValidationSchema(
    bankStatementLastMonth.label,
    bankStatementLastMonth.required
  ).when(
    [
      plaidAssetReport.name,
      systemPowerUsage.name,
      isPG.name,
      `principals[0].${principalAverageCashBalance.name}`,
    ],
    (assetReport, systemPowerUsage, isPG, averageCashBalance, schema) => {
      // When asset report is present,
      //  or systemPowerUsage is "Sold to another entity"
      //  or isPersonalGuarantor is "Yes",
      // don't require bank statements
      if (
        assetReport?.asset_report_status === 'SUCCESS' ||
        systemPowerUsage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY ||
        (isPG === 'Yes' &&
          (averageCashBalance ||
            assetReport?.asset_report_status === 'SUCCESS'))
      ) {
        return getDocumentValidationSchema(bankStatement2Month.label, false);
      }
      return schema;
    }
  ),
  [bankStatement1Month.name]: getDocumentValidationSchema(
    bankStatement1Month.label,
    bankStatement1Month.required
  ).when(
    [
      plaidAssetReport.name,
      systemPowerUsage.name,
      isPG.name,
      `principals[0].${principalAverageCashBalance.name}`,
    ],
    (assetReport, systemPowerUsage, isPG, averageCashBalance, schema) => {
      // When asset report is present,
      //  or systemPowerUsage is "Sold to another entity"
      //  or isPersonalGuarantor is "Yes",
      // don't require bank statements
      if (
        assetReport?.asset_report_status === 'SUCCESS' ||
        systemPowerUsage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY ||
        (isPG === 'Yes' &&
          (averageCashBalance ||
            assetReport?.asset_report_status === 'SUCCESS'))
      ) {
        return getDocumentValidationSchema(bankStatement2Month.label, false);
      }
      return schema;
    }
  ),
  [bankStatement2Month.name]: getDocumentValidationSchema(
    bankStatement2Month.label,
    bankStatement2Month.required
  ).when(
    [
      plaidAssetReport.name,
      systemPowerUsage.name,
      isPG.name,
      `principals[0].${principalAverageCashBalance.name}`,
      loanCategory.name,
    ],
    (
      assetReport,
      systemPowerUsage,
      isPG,
      averageCashBalance,
      loanCategory,
      schema
    ) => {
      // When asset report is present,
      //  or systemPowerUsage is "Sold to another entity"
      //  or isPersonalGuarantor is "Yes",
      // don't require bank statements
      const isExpressPlusLoan = loanCategory === LoanCategory.EXPRESS_PLUS;
      if (
        isExpressPlusLoan ||
        assetReport?.asset_report_status === 'SUCCESS' ||
        systemPowerUsage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY ||
        (isPG === 'Yes' &&
          (averageCashBalance ||
            assetReport?.asset_report_status === 'SUCCESS'))
      ) {
        return getDocumentValidationSchema(bankStatement2Month.label, false);
      }
      return schema;
    }
  ),
};

export const outstandingLoans = {
  [borrowerAttestsNoDebt.name]: Yup.boolean().nullable(),
  outstanding_loans: Yup.array().when(borrowerAttestsNoDebt.name, {
    is: true,
    then: Yup.array().max(0),
    otherwise: Yup.array()
      .of(
        Yup.object().shape({
          [loanLender.name]: getStringFieldValidationSchema(loanLender),
          [outstandingLoanType.name]:
            getStringFieldValidationSchema(outstandingLoanType),
          [loanPurpose.name]: getStringFieldValidationSchema(loanPurpose).when(
            outstandingLoanType.name,
            (typeOfLoan, schema) => {
              if (loanPurpose.getRequired(typeOfLoan))
                schema = schema.required(loanPurpose.requiredErrorMsg);
              return schema;
            }
          ),
          [loanBalance.name]: Yup.number()
            .typeError('Please enter a number.')
            .min(10000, 'Remaining principal must be greater than $10,000')
            .required(`${loanBalance.requiredErrorMsg}`)
            .nullable(),
          [loanYearOfMaturity.name]: Yup.number()
            .typeError(genericRequiredFieldError)
            .nullable()
            .min(
              new Date().getFullYear() + 1,
              'Loan should have a remaining term of at least 1 year'
            )
            .required('This field is required'),
          [loanMonthlyPayment.name]: Yup.number()
            .typeError('Please enter a number.')
            .min(0.01, 'Please enter a number greater than 0')
            .max(
              Yup.ref(loanBalance.name),
              'Please enter an amount less than the total loan balance'
            )
            .required(`${loanMonthlyPayment.requiredErrorMsg}`)
            .nullable(),
        })
      )
      .min(1, 'Please add at least one loan.'),
  }),
};

export const consentValidation = {
  [borrowerEsignName.name]: Yup.string()
    .label(borrowerEsignName.label)
    .required(`${borrowerEsignName.requiredErrorMsg}`)
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s.'-]*)$/gi,
      'Name can only contain English letters.'
    )
    // A full name. First and Last word must be atleast two characters, all words in between the first and last must be 1 character. Each word is counted as separated by a space.
    .matches(
      /^\s*[\S]{2,}(\s[\S]{1,})*(\s[\S]{2,})\s*$/gms,
      'Please enter your full name.'
    )
    .nullable(),
  [borrowerSignerRole.name]: Yup.string()
    .label(borrowerSignerRole.label)
    .required(`${borrowerSignerRole.requiredErrorMsg}`)
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s.'-]*)$/gi,
      'Position/Title can only contain English letters.'
    )
    .min(3)
    .nullable(),
};

export const documentsValidationSchema =
  Yup.object().shape(documentsValidation);

export const bankStatementsValidationSchema =
  Yup.object().shape(bankStatements);

export const outstandingLoansSchema = Yup.object().shape(outstandingLoans);

export const stage2ReviewSchema = Yup.object().shape({
  ...outstandingLoans,
  ...documentsValidation,
});

export const consentValidationSchema = Yup.object().shape(consentValidation);

const validationSchema = [
  // Documents
  documentsValidationSchema,

  // Outstanding Loans
  outstandingLoansSchema,
  // Review
  stage2ReviewSchema,
  // Consent
  consentValidationSchema,
];

export const stage2ValidationFields = [
  documentsValidation,
  outstandingLoans,
  { ...outstandingLoans, ...documentsValidation },
  consentValidation,
];

export default validationSchema;
