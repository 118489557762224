import { FC } from 'react';
import { Control } from 'react-hook-form';
import { InitialValues } from '../schema';
import NewBorrowerFormFieldset from '../NewBorrowerFormFieldset';
import { DialogContentText, Grid } from '@mui/material';
import InputField from '../../../../hook_form_fields/InputField';
import NumberField from '../../../../hook_form_fields/NumberField';
import { NUMBER_FORMATS, PLACEHOLDERS } from '../../../../../constants';

const BusinessContactInformationFieldset: FC<{
  control: Control<InitialValues>;
}> = ({ control }) => {
  return (
    <NewBorrowerFormFieldset title="Business Contact Information">
      <Grid container maxWidth="md" spacing={2}>
        <Grid item xs={12} sm={12}>
          <InputField
            data-testid="businessName"
            control={control}
            name="borrower.name"
            label="Business Name"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <DialogContentText>
            The contact person below is the party responsible for filling out
            the solar loan application. Sunstone may reach out to the
            responsible party with additional questions.
          </DialogContentText>
        </Grid>
        <Grid item xs={6} sm={6}>
          <InputField
            control={control}
            name="contact.first_name"
            label="Contact First Name"
            data-testid="firstName"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <InputField
            control={control}
            name="contact.last_name"
            data-testid="lastName"
            label="Contact Last Name"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputField
            control={control}
            name="contact.position_title"
            data-testid="positionTitle"
            label="Contact Position/Title"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputField
            control={control}
            name="contact.email"
            label="Contact Email"
            data-testid="email"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <NumberField
            format={NUMBER_FORMATS.PHONE}
            placeholder={PLACEHOLDERS.PHONE}
            control={control}
            name="contact.phone"
            data-testid="phone"
            label="Contact Phone Number"
            allowLeadingZeros
            required
            fullWidth
          />
        </Grid>
      </Grid>
    </NewBorrowerFormFieldset>
  );
};

export default BusinessContactInformationFieldset;
