export const registerFields = {
  email: {
    label: 'Email Address',
    name: 'email',
    id: 'email',
    type: 'email',
    'data-testid': 'email',
  },
  password: {
    label: 'Password',
    name: 'password',
    id: 'password',
    type: 'password',
    'data-testid': 'password',
  },
  confirmPassword: {
    label: 'Confirm Password',
    name: 'passwordConfirmation',
    id: 'passwordConfirmation',
    type: 'password',
    'data-testid': 'confirmPassword',
  },
  policyCheckbox: {
    name: 'policy',
    'data-testid': 'policy',
  },
};

export const loginFields = {
  email: {
    id: 'email',
    label: 'Email Address',
    name: 'email',
    type: 'email',
    testId: 'email',
  },
  password: {
    id: 'password',
    label: 'Password',
    name: 'password',
    type: 'password',
    testId: 'password',
  },
};
