const TOKEN_KEY = 'TOKEN_KEY';

/**
 * Access/Refresh token pair
 * @typedef {Object} TokenPair
 * @property {string} access - access token
 * @property {string} refresh - refresh token
 */

/**
 * @param {TokenPair} token
 */
export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
};

/**
 * @returns {TokenPair | null}
 */
export const getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  return token ? JSON.parse(token) : null;
};

/**
 * @returns {string | null}
 */
export const getLocalRefreshToken = () => {
  const token = getToken();
  return token ? token.refresh : null;
};

/**
 * @returns {string | null}
 */
export const getLocalAccessToken = () => {
  const token = getToken();
  return token ? token.access : null;
};

export const deleteToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};
