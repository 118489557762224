import * as Yup from 'yup';
import { AnyObject } from 'yup/lib/types';
import { ApplicationStepStatus } from '../components/loan_application/types';
import { genericRequiredFieldError } from '../constants';
type FieldDescription = {
  name: string;
  label?: string;
  getLabel?: (...args: any[]) => string;
  required?: boolean;
  max?: number;
  requiredErrMessage?: string;
};

export const getEmailValidationSchema = (field: FieldDescription) => {
  let schema: Yup.StringSchema<
    string | null | undefined,
    AnyObject,
    string | null | undefined
  > = Yup.string()
    .email('Invalid email address')
    // Test thet top level domain is 2 to 6 characters long
    .test('has-valid-tdn', 'Invalid email address', (value) =>
      value ? /\.[A-Za-z]{2,6}$/.test(value) : false
    );
  if (field.label) {
    schema = schema.label(field.label);
  }
  if (field.required) {
    schema = schema.required(field.requiredErrMessage || 'Required').nullable();
  } else {
    schema = schema.nullable();
  }
  return schema;
};

export const getStringFieldValidationSchema = (field: FieldDescription) => {
  let schema: Yup.StringSchema<
    string | null | undefined,
    AnyObject,
    string | null | undefined
  > = Yup.string();
  if (field.label) {
    schema = schema.label(field.label);
  }
  if (field.required) {
    schema = schema
      .required(field.requiredErrMessage ?? genericRequiredFieldError)
      .nullable();
  } else {
    schema = schema.nullable();
  }
  if (field.max) {
    schema = schema.max(
      field.max,
      `${field.label} must be less than ${field.max} characters`
    );
  }
  return schema;
};
/** Accepted phone numbers are always 10 characters long */
export const getPhoneNumValidationSchema = (field: FieldDescription) => {
  let schema: Yup.StringSchema<
    string | null | undefined,
    AnyObject,
    string | null | undefined
  > = Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(10, 'Must be 10 digits')
    .max(10, 'Must be 10 digits');
  if (field.label) {
    schema = schema.label(field.label);
  }
  if (field.required) {
    schema = schema
      .required(field.requiredErrMessage ?? genericRequiredFieldError)
      .nullable();
  } else {
    schema = schema.nullable();
  }
  return schema;
};

const defaultRequiredDocErrMessage = 'Required document missing';

export const getDocumentValidationSchema = (
  label: string,
  required: boolean
) => {
  const urlSchema = required
    ? Yup.string()
        .label(label)
        .nullable()
        .required(defaultRequiredDocErrMessage)
    : Yup.string().label(label).nullable();
  const schema = Yup.object().shape({
    url: urlSchema,
  });

  return schema;
};

export const getFormIncompleteStatus = (
  fields: Record<string, any>,
  data: Record<string, any>
): ApplicationStepStatus => {
  if (!fields || !Object.keys(fields).length || !data) {
    return ApplicationStepStatus.INCOMPLETE;
  }
  for (const key in fields) {
    const field = fields[key];
    if (key && data[key]) {
      if (
        field.type === 'array' &&
        Array.isArray(data[key]) &&
        data[key].length === 0
      ) {
        return ApplicationStepStatus.INCOMPLETE;
      }
      // If the field is an object, and data[key] is an empty object, then the field is incomplete
      if (field.type === 'object' && Object.keys(data[key]).length === 0) {
        return ApplicationStepStatus.INCOMPLETE;
      }
      if (field.type !== 'array') {
        return ApplicationStepStatus.ACTIVE;
      }

      for (let i = 0; i < data[key].length; i += 1) {
        const formItem = data[key][i];
        const formFields = field.innerType?.fields;
        if (!formFields) return ApplicationStepStatus.ACTIVE;
        const itemStatus = getFormIncompleteStatus(formFields, formItem);
        if (itemStatus === ApplicationStepStatus.ACTIVE) {
          return ApplicationStepStatus.ACTIVE;
        }
      }
    }
  }
  return ApplicationStepStatus.INCOMPLETE;
};

export const getApplicationFormStatus = (
  schema,
  fields,
  data
): ApplicationStepStatus => {
  if (!schema) {
    return ApplicationStepStatus.INCOMPLETE;
  }

  try {
    schema.validateSync(data);
    // for debugging, see AppliactionStepsContext.getStepsStateFromData()
    // schema.validateSync(data, { abortEarly: false });

    return ApplicationStepStatus.COMPLETE;
  } catch (e) {
    // for debugging
    // if (e.inner) {
    //   e.inner.forEach((error) => {
    //     console.log(`  Path: ${error.path}`);
    //     console.log(`    Message: ${error.message}`);
    //     console.log(`    Value: ${error.value}`);
    //   });
    // }
    return getFormIncompleteStatus(fields, data);
  }
};
