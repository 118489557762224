import { Field, FieldInputProps, FormikBag, FieldMetaProps } from 'formik';
import { FC, useState } from 'react';
import { Document, SunstoneDocument } from '../../../../../types/api';
import { maxFileSizeInBytes } from '../../constants';
import bytesToSize from '../../utils/bytesToSize';
import FileDropzone, { FileDropzoneProps } from '../FileDropzone';

interface FileInputFieldProps
  extends Omit<
    FileDropzoneProps,
    | 'file'
    | 'error'
    | 'maxSize'
    | 'onDropRejected'
    | 'onDrop'
    | 'onDropAccepted'
  > {
  name: string;
  onUpload: (files: File[]) => Promise<SunstoneDocument | Document>;
}

const FileInputField: FC<FileInputFieldProps> = ({
  name,
  onUpload,
  canReplace,
  ...rest
}) => {
  const [fileError, setFileError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  return (
    <Field name={name}>
      {({
        field,
        form,
        meta,
      }: {
        field: FieldInputProps<SunstoneDocument>;
        form: FormikBag<any, any>;
        meta: FieldMetaProps<SunstoneDocument>;
      }) => {
        const { value } = field;
        const { error, touched } = meta;
        return (
          <FileDropzone
            file={value}
            error={fileError || (touched && (error as any)?.url)}
            loading={loading}
            disabled={loading || (!!value && !canReplace)}
            onDrop={() => setFileError(null)}
            maxSize={maxFileSizeInBytes}
            onDropRejected={(rejectedFiles) => {
              const file = rejectedFiles[0].file;
              const error = rejectedFiles[0].errors[0];
              let errorCopy: string;
              if (error.code === 'file-too-large') {
                errorCopy = `${file.name} is too big. Max size is ${bytesToSize(
                  maxFileSizeInBytes
                )}.`;
              } else if (error.code === 'file-invalid-type') {
                errorCopy = `${file.name} is not a valid file type. Should be an image, PDF, or a text document.`;
              } else {
                errorCopy = error.message;
              }
              setFileError(errorCopy);
            }}
            onDropAccepted={async (files) => {
              try {
                setLoading(true);
                const document = await onUpload(files);
                form.setFieldValue(name, document);
              } catch (e) {
                console.error(e);
              } finally {
                setLoading(false);
              }
            }}
            canReplace={canReplace}
            {...rest}
          />
        );
      }}
    </Field>
  );
};

export default FileInputField;
