import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import { ACCOUNT_TYPES } from '../../constants';
import { AccountType } from '../../../../../types/api';

interface GuestGuardProps {
  children: ReactNode;
}

const isInstallerOrConsultant = (type: AccountType): type is AccountType =>
  type === ACCOUNT_TYPES.INSTALLER || type === ACCOUNT_TYPES.CONSULTANT;

const GuestGuard: React.FC<GuestGuardProps> = ({ children }) => {
  const { user, isAuthenticated } = useAuth();

  if (isAuthenticated) {
    if (user?.account?.type && isInstallerOrConsultant(user.account.type)) {
      return <Navigate to="/dashboard/borrowers/" replace />;
    } else {
      return <Navigate to="/dashboard/applications/" replace />;
    }
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GuestGuard;
