import { ApplicationStepStatus } from '../../../components/loan_application/types';
import {
  createContext,
  Dispatch,
  FC,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import type { ApplicationData } from './LoanDetailTypes';
import { ApplicationStepID } from './LoanDetailTypes';
import { getApplicationFormStatus } from '../../../utils/validation';
import {
  applicationSteps,
  applicationStepsByIDs,
} from './ApplicationStepsConfig';
import {
  calculatePGPercentOwnership,
  requiresPGPercentOwnership,
  missingApplicantSameAsPrincipal,
} from '../../../components/loan_application/utils/helpers';
import { PG_PERCENT_OWNERSHIP_FLOOR } from '../../../constants';

type StepState = { status: ApplicationStepStatus; disabled: boolean };

type StepsState = {
  currentStep: number;
  steps: StepState[];
  data: ApplicationData | null;
  isSaving: boolean;
};

const initialStepsState: StepsState = {
  currentStep: 0,
  steps: [],
  data: null,
  isSaving: false,
};

type Action<T extends string, P = any> = {
  type: T;
  payload: P;
};

type StepsActions =
  | Action<'SET_CURRENT_STEP', number>
  | Action<'UPDATE_STEPS_STATE', ApplicationData>
  | Action<'SET_IS_SAVING', boolean>;

function stepsReducer(state: StepsState, action: StepsActions): StepsState {
  switch (action.type) {
    case 'SET_CURRENT_STEP':
      return {
        ...state,
        currentStep:
          // If step in the action payload is outside of range of steps, leave the current step as is
          action.payload > state.steps.length - 1 || action.payload < 0
            ? state.currentStep
            : action.payload,
      };
    case 'UPDATE_STEPS_STATE':
      return {
        ...state,
        data: action.payload,
        steps: getStepsStateFromData(action.payload),
      };
    case 'SET_IS_SAVING':
      return action.payload !== state.isSaving
        ? { ...state, isSaving: action.payload }
        : state;
    default:
      return state;
  }
}

const ApplicationStepsContext = createContext<StepsState>(initialStepsState);
const ApplicationStepsDispatchContext = createContext<Dispatch<StepsActions>>(
  () => {}
);

export const useApplicationSteps = () => useContext(ApplicationStepsContext);
export const useApplicationStepsDispatch = () =>
  useContext(ApplicationStepsDispatchContext);

/**
 * @param data Application data including application object and required documents
 * @returns an array of objects containing the status of each step and whether it is disabled
 */
const getStepsStateFromData = (data: ApplicationData): StepState[] => {
  const validationData = { ...data.application, ...data.documents };

  const stepsStatuses = applicationSteps.map((step) => {
    // for debugging
    // console.log(`** ${step.title} **`)
    let status = getApplicationFormStatus(
      step.validationSchema,
      step.fields,
      validationData
    );

    // special handling for Principals Information PG percent ownership
    if (
      step.id === ApplicationStepID.PRINCIPALS &&
      status === ApplicationStepStatus.COMPLETE &&
      ((requiresPGPercentOwnership(data.application) &&
        calculatePGPercentOwnership(data.application.principals) <
          PG_PERCENT_OWNERSHIP_FLOOR) ||
        missingApplicantSameAsPrincipal(data.application))
      // We also need to check that the field applicantSameAsPrincipal is not filled as the logic overrides the step completion,
      // marking the step as completed even if the field is empty
    ) {
      status = ApplicationStepStatus.ACTIVE;
    }

    return { status };
  });
  const steps = stepsStatuses.map((step, index) => {
    const dependsOn = applicationSteps[index].dependsOn;

    // If the step doesn't depend on any other step, it's not disabled
    if (!dependsOn)
      return {
        ...step,
        disabled: false,
      };
    // Step is disabled if at least one of the steps it depends on is not completed
    const disabled = dependsOn.some(
      (stepId) =>
        stepsStatuses[applicationStepsByIDs[stepId].index].status !==
        ApplicationStepStatus.COMPLETE
    );
    return {
      ...step,
      disabled,
    };
  });
  return steps;
};

// Get the initial state of the stepper context
// This includes:
// - The current step, which is the first step that has not been completed
// - The status of each step, which is based on the validation schema of the step
const initializeSteps = (data: ApplicationData): StepsState => {
  const steps = getStepsStateFromData(data);
  const firstIncompleteStep = steps.findIndex(
    (step) => step.status !== ApplicationStepStatus.COMPLETE
  );
  // Set current step to review step if all steps up to consent are complete
  const consentStepIndex = steps.length - 1;
  const reviewStepIndex = steps.length - 2;
  const currentStep =
    firstIncompleteStep >= consentStepIndex || firstIncompleteStep === -1
      ? reviewStepIndex
      : firstIncompleteStep;
  return { steps, currentStep, data, isSaving: false };
};
/**
 * Provides the state of the application form steps.
 * This is used to determine which step to display, and what is the validation status of all the steps
 * that are displayed in the sidebar.
 */
const ApplicationStepsProvider: FC<{
  applicationData: ApplicationData;
}> = ({ children, applicationData }) => {
  const [state, dispatch] = useReducer(
    stepsReducer,
    applicationData,
    initializeSteps
  );

  const firstRender = useRef(true);

  // When we mutate the application data, the parent component will pass
  // updated applicationData to this component. While the initial state of the stepper
  // is set by initializeSteps, we need to update the state of the stepper when the applicationData
  // changes.
  useEffect(() => {
    // Skip doing anything on the first render
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    // Else, on change to applicationData, update the state of the stepper
    dispatch({ type: 'UPDATE_STEPS_STATE', payload: applicationData });
  }, [applicationData]);

  return (
    <ApplicationStepsContext.Provider value={state}>
      <ApplicationStepsDispatchContext.Provider value={dispatch}>
        {children}
      </ApplicationStepsDispatchContext.Provider>
    </ApplicationStepsContext.Provider>
  );
};

// Set current step to a given step that is not disabled.
const setCurrentStep = (dispatch: Dispatch<StepsActions>, step: number) => {
  dispatch({ type: 'SET_CURRENT_STEP', payload: step });
};

// Set isSaving to given value
const setIsSaving = (dispatch: Dispatch<StepsActions>, isSaving: boolean) => {
  dispatch({ type: 'SET_IS_SAVING', payload: isSaving });
};

// The reason for using the helper functions above is to avoid having to access the context state directly
// in cases where we don't need it to update the state. This helps us avoid unnecessary re-renders.
export const helpers = {
  setCurrentStep,
  setIsSaving,
};

export default ApplicationStepsProvider;
