import * as Yup from 'yup';
import { Application, RelatedParty } from '../../../../../../types/api';
import {
  RELATED_PARTY_TYPES,
  SYSTEM_POWER_USAGE_VALUES,
} from '../../../constants';
import {
  getDocumentValidationSchema,
  getStringFieldValidationSchema,
} from '../../../utils/validation';
import loanFormModel from './loanFormModel';

const {
  systemPowerCompanyType,
  borrowerIsReligiousOrganization,
  nonProfitRecievedBoardApproval,
  propertyCompanyUploadRentRollOrProvideTenantInfo,
  borrowerBusinessEstablishedYear,
  systemPropertyOwnershipStatus,
  systemPropertyCurrentlyPayingMortgage,
  // Tenant Info
  propertyCompanyTenantName,
  propertyCompanyTenantMoveInYear,
  propertyCompanyTenantLeaseTerm,
  propertyCompanyTenantRenewalYear,
  propertyCompanyTenantRenewalLength,
  propertyCompanyTenantMonthlyRentPayment,
  propertyCompanyTenantPaymentsSatisfactory,
  // Single User
  systemPowerUsage,
  systemPowerSingleOrMultipleUsers,
  // community solar
  systemPowerCommunityOrPPALease,
  // Principal docs
  principalCertificateOfTrust,
  principalType,
  isPG,
} = loanFormModel.formField;

const {
  articlesOfIncorporation,
  nonProfitBoardResolution,
  nonProfitBylawsCopy,
  pgMortgageStatement,
  propertyCompanyRentRoll,
  propertyCompanyMortgageStatement,
  religiousOrganizationAttendance,
} = loanFormModel.formField.companyDocuments;

const {
  leaseOfTheSystemProperty,
  offtakerPPAorLeaseAgreement,
  proofOfOfftakerCreditworthiness,
  offTakerLeaseOrProofOfOwnership,
  offTakerUtilityBill,
  ppaLeaseFinancialProjections,
  // Community Solar Fields
  approvalForCommunitySolarDelivery,
  communitySolarFinancialProjections,
  subscriberManagementCompanyContract,
} = loanFormModel.formField.solarSystemDocuments;

const {
  memberAssessmentPolicy,
  priorYearBudgets,
  currentOccupantLedger,
  meetingMinutes,
  hoaBylaws,
} = loanFormModel.formField.hoaDocuments;

const { additionalDocuments } = loanFormModel.formField.otherDocuments;

const enterTenantInfoCondition = (
  systemPowerCompanyType: Application['solar_system_power_company_type'],
  propertyCompanyUploadRentRollOrProvideTenantInfo: Application['system_power_rent_roll_or_tenant_info']
) => {
  return (
    systemPowerCompanyType === 'Property Company' &&
    propertyCompanyUploadRentRollOrProvideTenantInfo ===
      'Enter information for a single tenant'
  );
};

const enterTenantLeaseTermCondition = (
  systemPowerCompanyType: Application['solar_system_power_company_type'],
  propertyCompanyUploadRentRollOrProvideTenantInfo: Application['system_power_rent_roll_or_tenant_info']
) => {
  return (
    systemPowerCompanyType !== 'Property Company' &&
    propertyCompanyUploadRentRollOrProvideTenantInfo ===
      'Enter information for a single tenant'
  );
};

const powerSoldToSingleUserCondition = (
  powerUsage: Application['solar_system_power_usage'],
  singleOrMultipleUsers: Application['system_power_single_or_multiple_users']
) => {
  return (
    powerUsage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
    singleOrMultipleUsers === 'Single User'
  );
};

const powerIsCommunitySolarCondition = (
  powerUsage: Application['solar_system_power_usage'],
  singleOrMultipleUsers: Application['system_power_single_or_multiple_users'],
  communityOrPPALease: Application['community_solar_or_ppa_lease']
) => {
  return (
    powerUsage === SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
    singleOrMultipleUsers === 'Multiple Users' &&
    communityOrPPALease === 'Community Solar Arrangement'
  );
};

export const companyDocumentsValidation = {
  [articlesOfIncorporation.name]: getDocumentValidationSchema(
    articlesOfIncorporation.name,
    false
  ).when([borrowerBusinessEstablishedYear.name, isPG.name], {
    is: (
      borrowerBusinessEstablishedYear: Application['business_established_year'],
      isPersonalGuarantor: Application['is_personal_guarantor']
    ) => {
      const businessEstablishedYear = borrowerBusinessEstablishedYear;
      const currentYear = new Date().getFullYear();
      return (
        businessEstablishedYear &&
        currentYear - Number(businessEstablishedYear) < 3 &&
        isPersonalGuarantor !== 'Yes'
      );
    },
    then: getDocumentValidationSchema(articlesOfIncorporation.name, true),
    otherwise: getDocumentValidationSchema(articlesOfIncorporation.name, false),
  }),
  [nonProfitBoardResolution.name]: getDocumentValidationSchema(
    nonProfitBoardResolution.name,
    false
  ).when(
    [
      systemPowerCompanyType.name,
      nonProfitRecievedBoardApproval.name,
      isPG.name,
    ],
    {
      is: (
        systemPowerCompanyType: Application['solar_system_power_company_type'],
        nonProfitRecievedBoardApproval: Application['non_profit_received_board_approval'],
        isPersonalGuarantor: Application['is_personal_guarantor']
      ) => {
        return (
          systemPowerCompanyType === 'Non-Profit' &&
          nonProfitRecievedBoardApproval === 'Yes' &&
          isPersonalGuarantor !== 'Yes'
        );
      },
      then: getDocumentValidationSchema(
        nonProfitBoardResolution.name,
        true
      ).required('This document is required'),
      otherwise: getDocumentValidationSchema(
        nonProfitBoardResolution.name,
        false
      ),
    }
  ),
  [religiousOrganizationAttendance.name]: getDocumentValidationSchema(
    religiousOrganizationAttendance.name,
    false
  ).when(
    [
      systemPowerCompanyType.name,
      borrowerIsReligiousOrganization.name,
      isPG.name,
    ],
    {
      is: (
        systemPowerCompanyType: Application['solar_system_power_company_type'],
        borrowerIsReligiousOrganization: Application['is_religious_organization'],
        isPersonalGuarantor: Application['is_personal_guarantor']
      ) => {
        return (
          systemPowerCompanyType === 'Non-Profit' &&
          borrowerIsReligiousOrganization === 'Yes' &&
          isPersonalGuarantor !== 'Yes'
        );
      },
      then: getDocumentValidationSchema(
        religiousOrganizationAttendance.name,
        true
      ).required('This document is required'),
      otherwise: getDocumentValidationSchema(
        religiousOrganizationAttendance.name,
        false
      ),
    }
  ),
  [nonProfitBylawsCopy.name]: getDocumentValidationSchema(
    nonProfitBylawsCopy.name,
    false
  ).when([systemPowerCompanyType.name, isPG.name], {
    is: (
      systemPowerCompanyType: Application['solar_system_power_company_type'],
      isPersonalGuarantor: Application['is_personal_guarantor']
    ) => {
      return (
        systemPowerCompanyType === 'Non-Profit' && isPersonalGuarantor !== 'Yes'
      );
    },
    then: getDocumentValidationSchema(nonProfitBylawsCopy.name, true).required(
      'This document is required'
    ),
    otherwise: getDocumentValidationSchema(nonProfitBylawsCopy.name, false),
  }),
  [propertyCompanyMortgageStatement.name]: getDocumentValidationSchema(
    propertyCompanyMortgageStatement.name,
    false
  ).when(
    [
      systemPowerCompanyType.name,
      systemPropertyOwnershipStatus.name,
      systemPropertyCurrentlyPayingMortgage.name,
      isPG.name,
    ],
    {
      is: (
        systemPowerCompanyType: Application['solar_system_power_company_type'],
        systemPropertyOwnershipStatus: Application['sys_prop_ownership_status_picklist'],
        systemPropertyCurrentlyPayingMortgage: Application['borrower_is_currently_paying_mortgage'],
        isPersonalGuarantor: Application['is_personal_guarantor']
      ) => {
        return (
          systemPowerCompanyType === 'Property Company' &&
          systemPropertyOwnershipStatus === 'OWNED' &&
          systemPropertyCurrentlyPayingMortgage === 'Yes' &&
          isPersonalGuarantor !== 'Yes'
        );
      },
      then: getDocumentValidationSchema(
        propertyCompanyMortgageStatement.name,
        true
      ).required('This document is required'),
      otherwise: getDocumentValidationSchema(
        propertyCompanyMortgageStatement.name,
        false
      ),
    }
  ),
  [propertyCompanyUploadRentRollOrProvideTenantInfo.name]: Yup.string()
    .nullable()
    .when([systemPowerCompanyType.name, isPG.name], {
      is: (
        systemPowerCompanyType: Application['solar_system_power_company_type'],
        isPersonalGuarantor: Application['is_personal_guarantor']
      ) => {
        return (
          systemPowerCompanyType === 'Property Company' &&
          isPersonalGuarantor !== 'Yes'
        );
      },
      then: getStringFieldValidationSchema(
        propertyCompanyUploadRentRollOrProvideTenantInfo
      ),
      otherwise: getStringFieldValidationSchema(
        propertyCompanyUploadRentRollOrProvideTenantInfo
      ).notRequired(),
    }),
  [propertyCompanyRentRoll.name]: getDocumentValidationSchema(
    propertyCompanyRentRoll.name,
    false
  ).when(
    [
      systemPowerCompanyType.name,
      propertyCompanyUploadRentRollOrProvideTenantInfo.name,
      isPG.name,
    ],
    {
      is: (
        systemPowerCompanyType: Application['solar_system_power_company_type'],
        propertyCompanyUploadRentRollOrProvideTenantInfo: Application['system_power_rent_roll_or_tenant_info'],
        isPersonalGuarantor: Application['is_personal_guarantor']
      ) => {
        return (
          systemPowerCompanyType === 'Property Company' &&
          propertyCompanyUploadRentRollOrProvideTenantInfo ===
            'Upload rent roll' &&
          isPersonalGuarantor !== 'Yes'
        );
      },
      then: getDocumentValidationSchema(
        propertyCompanyRentRoll.name,
        true
      ).required('This document is required'),
      otherwise: getDocumentValidationSchema(
        propertyCompanyRentRoll.name,
        false
      ),
    }
  ),
  [propertyCompanyTenantName.name]: getStringFieldValidationSchema(
    propertyCompanyTenantName
  ).when(
    [
      systemPowerCompanyType.name,
      propertyCompanyUploadRentRollOrProvideTenantInfo.name,
    ],
    {
      is: enterTenantInfoCondition,
      then: (schema) => schema.required('This field is required'),
      otherwise: (schema) => schema.notRequired(),
    }
  ),
  [propertyCompanyTenantMoveInYear.name]: Yup.number()
    .positive()
    .nullable()
    .min(1900)
    .max(new Date().getFullYear())
    .when(
      [
        systemPowerCompanyType.name,
        propertyCompanyUploadRentRollOrProvideTenantInfo.name,
      ],
      {
        is: enterTenantInfoCondition,
        then: (schema) => schema.required('This field is required'),
        otherwise: (schema) => schema.notRequired(),
      }
    ),
  [propertyCompanyTenantMonthlyRentPayment.name]: Yup.number()
    .positive()
    .nullable()
    .when(
      [
        systemPowerCompanyType.name,
        propertyCompanyUploadRentRollOrProvideTenantInfo.name,
      ],
      {
        is: enterTenantInfoCondition,
        then: (schema) => schema.required('This field is required'),
        otherwise: (schema) => schema.notRequired(),
      }
    ),
  [propertyCompanyTenantLeaseTerm.name]: Yup.number()
    .positive()
    .nullable()
    .when(
      [
        systemPowerCompanyType.name,
        propertyCompanyUploadRentRollOrProvideTenantInfo.name,
      ],
      {
        is: enterTenantLeaseTermCondition,
        then: (schema) => schema.required('This field is required'),
        otherwise: (schema) => schema.notRequired(),
      }
    ),
  [propertyCompanyTenantRenewalYear.name]: Yup.number()
    .positive()
    .nullable()
    .min(new Date().getFullYear())
    .when(
      [
        systemPowerCompanyType.name,
        propertyCompanyUploadRentRollOrProvideTenantInfo.name,
      ],
      {
        is: enterTenantInfoCondition,
        then: (schema) => schema.required('This field is required'),
        otherwise: (schema) => schema.notRequired(),
      }
    ),
  [propertyCompanyTenantRenewalLength.name]: Yup.number()
    .positive()
    .nullable()
    .when(
      [
        systemPowerCompanyType.name,
        propertyCompanyUploadRentRollOrProvideTenantInfo.name,
      ],
      {
        is: enterTenantInfoCondition,
        then: (schema) => schema.required('This field is required'),
        otherwise: (schema) => schema.notRequired(),
      }
    ),
  [propertyCompanyTenantPaymentsSatisfactory.name]:
    getStringFieldValidationSchema(
      propertyCompanyTenantPaymentsSatisfactory
    ).when(
      [
        systemPowerCompanyType.name,
        propertyCompanyUploadRentRollOrProvideTenantInfo.name,
      ],
      {
        is: enterTenantInfoCondition,
        then: (schema) => schema.required('This field is required'),
        otherwise: (schema) => schema.notRequired(),
      }
    ),
  [pgMortgageStatement.name]: Yup.mixed().when(
    [
      systemPropertyOwnershipStatus.name,
      systemPropertyCurrentlyPayingMortgage.name,
      isPG.name,
    ],
    {
      is: (
        systemPropertyOwnershipStatus: Application['sys_prop_ownership_status_picklist'],
        systemPropertyCurrentlyPayingMortgage: Application['borrower_is_currently_paying_mortgage'],
        isPersonalGuarantor: Application['is_personal_guarantor']
      ) => {
        return (
          isPersonalGuarantor === 'Yes' &&
          systemPropertyCurrentlyPayingMortgage === 'Yes' &&
          systemPropertyOwnershipStatus !== 'LEASED'
        );
      },
      then: getDocumentValidationSchema(
        pgMortgageStatement.name,
        true
      ).required('This document is required'),
      otherwise: getDocumentValidationSchema(
        pgMortgageStatement.name,
        false
      ).nullable(),
    }
  ),
};

export const hoaDocumentsValidation = {
  [memberAssessmentPolicy.name]: getDocumentValidationSchema(
    memberAssessmentPolicy.name,
    false
  ).notRequired(),
  [priorYearBudgets.name]: getDocumentValidationSchema(
    priorYearBudgets.name,
    false
  ).when(systemPowerCompanyType.name, {
    is: (
      systemPowerCompanyType: Application['solar_system_power_company_type']
    ) => {
      return systemPowerCompanyType === 'HOA';
    },
    then: getDocumentValidationSchema(priorYearBudgets.name, true).required(
      'This document is required'
    ),
    otherwise: getDocumentValidationSchema(
      priorYearBudgets.name,
      false
    ).notRequired(),
  }),
  [currentOccupantLedger.name]: getDocumentValidationSchema(
    currentOccupantLedger.name,
    false
  ).when(systemPowerCompanyType.name, {
    is: (
      systemPowerCompanyType: Application['solar_system_power_company_type']
    ) => {
      return systemPowerCompanyType === 'HOA';
    },
    then: getDocumentValidationSchema(
      currentOccupantLedger.name,
      true
    ).required('This document is required'),
    otherwise: getDocumentValidationSchema(
      currentOccupantLedger.name,
      false
    ).notRequired(),
  }),
  [meetingMinutes.name]: getDocumentValidationSchema(
    meetingMinutes.name,
    false
  ).notRequired(),
  [hoaBylaws.name]: getDocumentValidationSchema(
    hoaBylaws.name,
    false
  ).notRequired(),
};

export const solarSystemDocumentsValidation = {
  [leaseOfTheSystemProperty.name]: getDocumentValidationSchema(
    leaseOfTheSystemProperty.name,
    false
  ).when(systemPropertyOwnershipStatus.name, {
    is: (
      systemPropertyOwnershipStatus: Application['sys_prop_ownership_status_picklist']
    ) => {
      return systemPropertyOwnershipStatus === 'LEASED';
    },
    then: getDocumentValidationSchema(
      leaseOfTheSystemProperty.name,
      true
    ).required('This document is required'),
    otherwise: getDocumentValidationSchema(
      leaseOfTheSystemProperty.name,
      false
    ).notRequired(),
  }),
  // Single user
  [offtakerPPAorLeaseAgreement.name]: Yup.array()
    .nullable()
    .of(getDocumentValidationSchema(offtakerPPAorLeaseAgreement.name, false))
    .when(
      [
        systemPowerUsage.name,
        systemPowerSingleOrMultipleUsers.name,
        systemPowerCommunityOrPPALease.name,
      ],
      {
        is: (
          systemPowerUsage: Application['solar_system_power_usage'],
          systemPowerSingleOrMultipleUsers: Application['system_power_single_or_multiple_users'],
          systemPowerCommunityOrPPALease: Application['community_solar_or_ppa_lease']
        ) => {
          return (
            systemPowerUsage ===
              SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
            (systemPowerSingleOrMultipleUsers === 'Single User' ||
              (systemPowerSingleOrMultipleUsers === 'Multiple Users' &&
                systemPowerCommunityOrPPALease == 'PPA/Lease Agreement'))
          );
        },
        then: (schema) =>
          schema
            .of(
              getDocumentValidationSchema(
                offtakerPPAorLeaseAgreement.name,
                true
              )
            )
            .min(1)
            .required('This document is required'),
        otherwise: (schema) => schema.notRequired(),
      }
    ),
  [proofOfOfftakerCreditworthiness.name]: Yup.array()
    .nullable()
    .min(0)
    .of(
      getDocumentValidationSchema(proofOfOfftakerCreditworthiness.name, false)
    ),
  [offTakerLeaseOrProofOfOwnership.name]: getDocumentValidationSchema(
    offTakerLeaseOrProofOfOwnership.name,
    false
  ),
  [offTakerUtilityBill.name]: Yup.array()
    .nullable()
    .of(getDocumentValidationSchema(offTakerUtilityBill.name, true))
    .when(
      [
        systemPowerUsage.name,
        systemPowerSingleOrMultipleUsers.name,
        systemPowerCommunityOrPPALease.name,
      ],
      {
        is: (
          systemPowerUsage: Application['solar_system_power_usage'],
          systemPowerSingleOrMultipleUsers: Application['system_power_single_or_multiple_users'],
          systemPowerCommunityOrPPALease: Application['community_solar_or_ppa_lease']
        ) => {
          return (
            systemPowerUsage ===
              SYSTEM_POWER_USAGE_VALUES.SOLD_TO_ANOTHER_ENTITY &&
            (systemPowerSingleOrMultipleUsers === 'Single User' ||
              (systemPowerSingleOrMultipleUsers === 'Multiple Users' &&
                systemPowerCommunityOrPPALease == 'PPA/Lease Agreement'))
          );
        },
        then: (schema) =>
          schema
            .of(getDocumentValidationSchema(offTakerUtilityBill.name, true))
            .min(1)
            .required('This document is required'),
        otherwise: (schema) =>
          schema
            .of(getDocumentValidationSchema(offTakerUtilityBill.name, false))
            .notRequired(),
      }
    ),
  [ppaLeaseFinancialProjections.name]: getDocumentValidationSchema(
    ppaLeaseFinancialProjections.name,
    false
  ).when([systemPowerUsage.name, systemPowerSingleOrMultipleUsers.name], {
    is: powerSoldToSingleUserCondition,
    then: getDocumentValidationSchema(ppaLeaseFinancialProjections.name, true),
    otherwise: getDocumentValidationSchema(
      ppaLeaseFinancialProjections.name,
      false
    ),
  }),
  // Community solar
  [approvalForCommunitySolarDelivery.name]: getDocumentValidationSchema(
    approvalForCommunitySolarDelivery.name,
    false
  ),

  [communitySolarFinancialProjections.name]: Yup.array()
    .nullable()
    .of(
      getDocumentValidationSchema(
        communitySolarFinancialProjections.name,
        false
      )
    )
    .when(
      [
        systemPowerUsage.name,
        systemPowerSingleOrMultipleUsers.name,
        systemPowerCommunityOrPPALease.name,
      ],
      {
        is: powerIsCommunitySolarCondition,
        then: (schema) =>
          schema
            .of(
              getDocumentValidationSchema(
                communitySolarFinancialProjections.name,
                true
              )
            )
            .min(1),
        otherwise: (schema) =>
          schema.of(
            getDocumentValidationSchema(
              communitySolarFinancialProjections.name,
              false
            ).notRequired()
          ),
      }
    ),
  [subscriberManagementCompanyContract.name]: getDocumentValidationSchema(
    subscriberManagementCompanyContract.name,
    false
  ),
  principals: Yup.array()
    .of(
      Yup.object().shape({
        documents: Yup.object().when(principalType.name, {
          is: (principalType: RelatedParty['principal_type']) =>
            principalType === RELATED_PARTY_TYPES.ENTITY,
          then: (schema) =>
            schema.shape({
              [principalCertificateOfTrust.name]: Yup.object().shape({
                url: Yup.string().required('This document is required'),
              }),
            }),
        }),
      })
    )
    .required(),
};

export const additionalDocumentationValidation = {
  [additionalDocuments.name]: Yup.array()
    .nullable()
    .min(0)
    .max(10)
    .of(getDocumentValidationSchema(additionalDocuments.name, false)),
};
