import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const formatDatetime = (datetime) => {
  return new Date(datetime)
    .toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })
    .replace(',', ' at')
    .toLowerCase();
};
/**
 * Formats a date to a string in UTC time
 * @param date can be a Date object, a string, or a number
 * @param format defaults to 'M/D/YYYY'
 * @returns {string} formatted date string
 */
export const formatDateInUTC = (
  date: string | number | Date,
  format = 'M/D/YYYY'
) => {
  return dayjs.utc(date).format(format);
};

export const formatDate = (date) => {
  return new Date(date)
    .toLocaleString('en-US', {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      timeZone: 'UTC',
    })
    .toLowerCase();
};

/**
 * Formats a number as a currency string with 2 decimal places
 * e.g. 1234.56 => $1,234.56
 * @example
 * currencyFormatter.format(1234.56); // $1,234.56
 */
export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

/**
 * Formats a number as a percentage string with 2 decimal places
 */
export const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});
