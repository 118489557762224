import { useState, useCallback } from 'react';
import { Tracker } from '../utils/tracker';

type UseDisclosureOptions = {
  onClose?: () => void;
  onOpen?: () => void;
  defaultIsOpen?: boolean;
};

export function useDisclosure(props: UseDisclosureOptions = {}) {
  const { onClose: onCloseProp, onOpen: onOpenProp, defaultIsOpen } = props;
  const [isOpen, setIsOpen] = useState(defaultIsOpen || false);

  const tracker = Tracker.getInstance();

  const onClose = useCallback(() => {
    setIsOpen(false);
    onCloseProp?.();
    tracker.track(Tracker.EVENTS.APP_TABLE_ROW_COLLAPSE);
  }, [onCloseProp]);

  const onOpen = useCallback(() => {
    setIsOpen(true);
    onOpenProp?.();
    tracker.track(Tracker.EVENTS.APP_TABLE_ROW_EXPAND);
  }, [onOpenProp]);

  const onToggle = useCallback(() => {
    const action = isOpen ? onClose : onOpen;
    action();
  }, [isOpen, onOpen, onClose]);

  return {
    isOpen: !!isOpen,
    onOpen,
    onClose,
    onToggle,
  };
}
