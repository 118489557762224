import React from 'react';
import { useField } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';

export default function CheckboxField(props) {
  const { label, checkboxProps, touched: defaultTouched, ...rest } = props;
  const [field, meta, helper] = useField({
    name: props.name,
    type: 'checkbox',
  });
  const { error, touched: fieldTouched } = meta;
  const touched = defaultTouched || fieldTouched;
  const { setValue } = helper;

  function _renderHelperText() {
    if (touched && error) {
      return (
        <FormHelperText error sx={{ mx: 0 }}>
          {error}
        </FormHelperText>
      );
    }
  }

  function _onChange(e) {
    setValue(e.target.checked);
  }

  return (
    <FormControl {...rest}>
      <FormControlLabel
        value={field.checked}
        checked={field.checked}
        control={
          <Checkbox
            {...field}
            {...checkboxProps}
            disabled={props.readOnly || props.isDisabled}
            onChange={_onChange}
          />
        }
        label={label}
      />
      {_renderHelperText()}
    </FormControl>
  );
}
