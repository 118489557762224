/**
 * Takes a file, documentType, and optional description
 * and returns a multipart form data object with arguments attached
 * @param file {File}
 * @param documentType {string}
 * @param description {string}
 * @returns {FormData}
 */
function getFormData(file: File, documentType: string, description?: string) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('documentType', documentType);
  if (description) {
    formData.append('description', description);
  }
  return formData;
}

export default getFormData;
