import React from 'react';
import InternalClientError from '../pages/errors/InternalClientError';

type State =
  | {
      hasError: false;
      error: null;
      errorInfo: null;
    }
  | {
      hasError: true;
      error: unknown;
      errorInfo: unknown;
    };

class ErrorBoundary extends React.Component<Record<string, never>, State> {
  state: State = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }
  resetErrorBoundary = () => {
    this.setState({ hasError: false, error: null, errorInfo: null });
  };
  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo, hasError: true });
  }
  render() {
    if (!this.state.hasError) return this.props.children;
    if (this.state.hasError && this.state.errorInfo && this.state.error) {
      // Redirect to the "Internal Error" page and pass the error code to the error page component via location.state
      return (
        <InternalClientError
          error={this.state.error}
          errorInfo={this.state.errorInfo}
          resetErrorBoundary={this.resetErrorBoundary}
        />
      );
    }
    // If there's no error to display, render children.
    return null;
  }
}

export default ErrorBoundary;
