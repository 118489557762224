import React from 'react';
import { useField } from 'formik';
import {
  FormControl,
  FormHelperText,
  FormLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';

export default function FreeformField(props) {
  const {
    label,
    readOnly,
    isDisabled,
    required,
    counter,
    touched: defaultTouched,
    rows,
    ...rest
  } = props;
  const [field, meta] = useField(props);
  const { touched: fieldTouched, error } = meta;
  const touched = defaultTouched || fieldTouched;

  const id = props.id ?? props.name;
  const helperTextId = id ? `${id}-helper-text` : undefined;
  const labelId = id ? `${id}-label` : undefined;

  function _renderHelperText() {
    return (
      <FormHelperText variant="outlined" sx={{ mx: 0 }} id={helperTextId}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            justifyContent: 'space-between',
          }}
          component="span"
        >
          <Typography variant="body2" flexGrow={0} pt={0.375} component="span">
            {counter && `${field.value?.length ?? 0}/${props.max}`}
          </Typography>
          {touched && error}
        </Box>
      </FormHelperText>
    );
  }
  const handleChange = (e) => {
    e.preventDefault();
    if (props.max && e.target.value.length > props.max)
      e.target.value = e.target.value.slice(0, props.max);
    field.onChange(e);
  };
  return (
    <FormControl
      {...rest}
      sx={{ position: 'relative' }}
      error={touched && error}
      disabled={isDisabled}
    >
      <FormLabel
        sx={{ mb: 1.25, fontSize: '0.75rem' }}
        id={labelId}
        htmlFor={id}
      >
        {label}
      </FormLabel>
      <OutlinedInput
        type="text"
        multiline
        minRows={rows || 2}
        id={id}
        InputProps={{
          readOnly: readOnly,
          'aria-labelledby': labelId,
        }}
        {...field}
        onChange={handleChange}
        aria-describedby={helperTextId}
      />
      {_renderHelperText()}
    </FormControl>
  );
}
